import React from "react";
import { observer } from "mobx-react-lite";
import { Modal, Box, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const ConfirmStatusModal = observer(
    ({
      isConfirm,
      onClick,
      setIsConfirm,
      status,
      buttonName = "CONFIRM",
    }: {
      isConfirm: boolean;
      setIsConfirm: any;
      onClick?: any;
      status: any;
      buttonName?: string;
    }) => {
  
      return (
        <Modal
          open={isConfirm}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            css={{ backgroundColor: "#fff", width: 350, borderRadius: 5 }}
            p={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Box css={{ color: "#4B4C56" }} fontSize={14}>
  
              </Box>
              <Box
                onClick={() => {
                  setIsConfirm(false);
                }}
              >
                <CloseIcon css={{ cursor: "pointer" }} />
              </Box>
            </Box>
            <Box
              mt ={2}
            >
              <p style={{ color:'grey', textAlign: 'center', fontSize: '18px'}}>Status will be changed to </p>
              <p style={{ color:'#3f51b5', textAlign: 'center', fontSize: '18px'}}> {status} .</p>
            </Box>
  
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt ={2}
            >
              
            </Box>
  
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt ={2}
            >
              <Button
                onClick={() => {
                  setIsConfirm(false);
                }}
                size="medium"
                variant="outlined"
              >
                CANCEL
              </Button>
  
              <Button
                onClick={onClick}
                color="primary"
                size="medium"
                variant="outlined"
                style={{marginLeft: '3%'}}
              >
                {buttonName}
              </Button> 
            </Box>
            <br/>
          </Box>
        </Modal>
      );
    }
  );