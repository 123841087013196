import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  Badge,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import {
  createStyles,
  makeStyles,
  Theme
} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useListSOSUser } from "../../services/api/user/useListSOSUser";
import { useListSOSUserComplete } from "../../services/api/user/useListSOSUserComplete";
import { dateStr } from "../../utils";
import { User } from "../../types/user";
import WarningIcon from "@material-ui/icons/Warning";
import { OnlineTab } from "./OnlineTab"; 
import { userDevice } from "../../types/device";
import Pagination from "@material-ui/lab/Pagination";
import { Places } from "../../types/places";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customBadge: {
      backgroundColor: "green",
      border: "2px solid #fff",
      width: 15,
      height: 15,
      borderRadius: "100%",
    },
  })
);
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const SOSItem = observer(
  ({
    user,
    device,
    fullDetail,
    border,
    place,
    sos_id,
    sos_status,
    updated_at,
    created_at
  }: {
    user: User;
    device?: userDevice;
    fullDetail?: boolean;
    place?: Places;
    border?: boolean;
    sos_id?: any;
    sos_status?: any;
    updated_at?: any;
    created_at?: any;
  }) => {
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={2}
          borderBottom={border ? "1px solid #ddd" : ""}
          css={{
            cursor: "pointer",
            "@media (max-width: 992px)": { borderTop: 0 },
            "&:hover": {
              backgroundColor: "rgba(3,169,244,0.08) !important",
              borderTop: 0,
            },
          }}
          onClick={() => {
            history.push("/user/" + sos_id + "/previewUserSOS");
          }}
        >
          <Box display="flex" padding="2%">
            <Badge
              overlap="circle"
              variant="dot"
              classes={{ badge: classes.customBadge }}
            >
              <Avatar css={{ width: 32, height: 32 }} src={user?.avatar}>
                {user?.name.substring(0, 1)}
              </Avatar>
            </Badge>
            <Box mx={3}>
              <Box fontWeight={600} display="flex">
                {`${user?.name ? user?.name : "-"} ${user?.surname ? user?.surname : "-"}`}
              </Box>
              
              <Box display="flex" fontSize={12} flexWrap="wrap">
                <Box css={{ color: "#5B5B62" }}>
                  {`${
                    user?.gender ? 
                    user?.gender === "m"
                      ? "Male"
                      : user?.gender === "f"
                      ? "Female"
                      : ""
                    : "not specified"
                  }
              | ${user?.nationality ? user?.nationality : "not specified" } | ${place?.name_en ? place?.name_en : "not specified" || user?.yatch_name ? user?.yatch_name : "not specified"}`}
                </Box>
                {fullDetail && (
                  <>
                    <Box
                      mx={{ xs: 0, md: 2 }}
                      mt={{ xs: 1, md: 0 }}
                      css={{ color: "#C83728" }}
                      display="flex"
                      alignItems="center"
                    >
                      <WarningIcon fontSize="small" />
                      <Box ml={0.5}>{dateStr(new Date(user?.created_at))}</Box>
                    </Box>
                    <Box mx={2} display={{ xs: "none", md: "block" }}>
                      <OnlineTab user={user} device={device} />
                    </Box>
                    <Box
                      mx={2}
                      display={{ xs: "none", md: "block" }}
                      css={{ minWidth: 250 }}
                    >
                      {/* <DeviceValueTab device={device} /> */}
                    </Box>
                  </>
                )}
              </Box>
              <Box fontWeight={600} display="flex">
                {!fullDetail && (
                  <Box
                    css={{ color: "#C83728" }}
                    display="flex"
                    alignItems="center"
                    fontSize={12}
                    fontWeight={400}
                  >
                    <WarningIcon fontSize="small" />
                    <Box ml={0.5}>{dateStr(new Date(created_at))}</Box>
                  </Box>
                )}
                {!fullDetail && (
                  <Box>
                    {sos_status === 3 ? 
                      <Box
                        css={{ color: "#249428" }}
                        display="flex"
                        alignItems="center"
                        fontSize={12}
                        fontWeight={400}
                      >
                        <DoneIcon fontSize="small" />
                        <Box ml={0.5}>{dateStr(new Date(created_at))}</Box>
                      </Box> 
                    : null}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box display={{ xs: "none", md: "block" }}>
            {sos_status === 2 ? <AlarmOnIcon css={{ color: "#A9A2A2" }} /> : null}
          </Box>
        </Box>
      </>
    );
  }
);

export const CardSOS = observer(({ fullDetail }: { fullDetail?: boolean }) => {
  const history = useHistory();
  const [page, setPage] = useState<number>(1);
  const [pageComplete, setPageComplete] = useState<number>(1);
  const [pageCount, setPageCont] = useState<number>(0);
  const [pageCountComplete, setPageContComplete] = useState<number>(0);
  const [value, setValue] = React.useState(0);
  const [perPage] = useState(6);

  const { result_userListSOS_raw, loading_userListSOS } = useListSOSUser();
  const { result_userListSOS_Complete_raw, loading_userListSOS_Complete } = useListSOSUserComplete();
  const [total, setTotal] = useState(0);
  const [totalComplete, setTotalComplete] = useState(0);
  useEffect(() => {
    setTotal(result_userListSOS_raw.length);
    setPageCont(Math.ceil(result_userListSOS_raw.length / (perPage)));
    setTotalComplete(result_userListSOS_Complete_raw.length);
    setPageContComplete(Math.ceil(result_userListSOS_Complete_raw.length / (perPage)));
  }, [result_userListSOS_raw, result_userListSOS_Complete_raw]);

  console.log("result_userListSOS_raw", result_userListSOS_raw);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      css={{
        display: "flex",
        flex: 1,
        minHeight: "100%",
        flexFlow: "column",
        // flexDirection: "column",
        borderRadius: 10,
        padding: "0 !important",
        "@media (min-width: 991px)": { backgroundColor: "#fff" },
      }}
      className="relative"
    >
      <AppBar position="static" css={{zIndex: 0, backgroundColor: "unset !important", color: "black !important", boxShadow:"0px 0px 0px !important"}}>
        <Tabs variant="fullWidth" value={value} onChange={handleChangeTab} aria-label="simple tabs example">
          <Tab label="SOS user" {...a11yProps(0)} />
          <Tab label="Complete SOS" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      {loading_userListSOS && (
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        />
      )}
      <Box px={{ xs: 0, md: 0 }} css={{padding: "0px !important"}}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          // mb={2}
        >
          <Box fontSize={14} padding={0} fontWeight={600}>
            {fullDetail ? `Total ${total} User` : `SOS user`}
          </Box>
          <Box display="flex" alignItems="center" fontSize={14}>
            <Box mr={1}>Sort by</Box>
            <Select
              labelId="label"
              id="select"
              value="oldest_first"
              disableUnderline
              css={{ fontSize: 14, fontFamily: "inherit", color: "#4684E2" }}
            >
              <MenuItem value="oldest_first">Oldest first</MenuItem>
            </Select>
          </Box>
        </Box>
        <Box
          css={{
            "@media (max-width: 992px)": {
              backgroundColor: "#fff",
              borderRadius: 5,
            },
          }}
          p={{ xs: 2, md: 0 }}
        >
        <Grid container>
            {(result_userListSOS_raw || []).slice((page - 1) * perPage, page * perPage)
            .map((sosModel, i) => {
              // sos 
                const sos_id = sosModel.id;
                const sos_status = sosModel.sos_status;
                const updated_at = sosModel.updated_at;
                const created_at = sosModel.created_at;
                if(sos_status == 0 || sos_status == 1 || sos_status == 2){
                  return (
                    <Grid item md={6} key={Math.random()}>
                      <SOSItem
                        {...sosModel}
                        sos_id={sos_id}
                        updated_at={updated_at}
                        created_at={created_at}
                        fullDetail={fullDetail}
                        border={i !== result_userListSOS_raw.length - 1}
                      />
                    </Grid>
                  );
                }
            })}
          </Grid>
        </Box>
        {pageCount > 1 && (
          <Box mt={2} css={{marginTop: "8px !important"}}>
            <Pagination
              count={pageCount}
              page={page || 1}
              onChange={handleChange}
              size="small"
            />
          </Box>
        )}
      </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {loading_userListSOS_Complete && (
          <LinearProgress
            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
          />
        )}
        <Box px={{ xs: 0, md: 0 }} css={{padding: "0px !important"}}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // mb={2}
          >
            <Box fontSize={14} padding={0} fontWeight={600}>
              {fullDetail ? `Total ${totalComplete} User` : `SOS user`}
            </Box>
            <Box display="flex" alignItems="center" fontSize={14}>
              <Box mr={1}>Sort by</Box>
              <Select
                labelId="label"
                id="select"
                value="oldest_first"
                disableUnderline
                css={{ fontSize: 14, fontFamily: "inherit", color: "#4684E2" }}
              >
                <MenuItem value="oldest_first">Oldest first</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box
            css={{
              "@media (max-width: 992px)": {
                backgroundColor: "#fff",
                borderRadius: 5,
              },
            }}
            p={{ xs: 2, md: 0 }}
          >
          <Grid container>
            {(result_userListSOS_Complete_raw || []).slice((page - 1) * perPage, page * perPage)
            .map((user, i) => {
                const sos_id = user.id;
                const sos_status = user.sos_status;
                if(sos_status == 3){
                  return (
                    <Grid item md={6} key={i}>
                      <SOSItem
                        {...user}
                        sos_id={sos_id}
                        fullDetail={fullDetail}
                        border={i !== result_userListSOS_Complete_raw.length - 1}
                      />
                    </Grid>
                  );
                }
            })}
          </Grid>
          </Box>
          {pageCountComplete > 1 && (
            <Box mt={2} css={{marginTop: "8px !important"}}>
              <Pagination
                count={pageCountComplete}
                page={pageComplete || 1}
                onChange={handleChange}
                size="small"
              />
            </Box>
          )}
        </Box>
      </TabPanel>
    </Box>
  );
});
