import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, InputBase } from '@material-ui/core';
import { webStore } from '../../stores/webStore';
import SearchIcon from "@material-ui/icons/Search";

export const UserSearch = observer(
    ({
      keywordsearch,
      setKeywordsearch,
      displayFilter,
      setDisplayFilter,
      inputWidthMobile,
    }: {
      inputWidthMobile?: number;
      keywordsearch: String;
      setKeywordsearch: (event: any) => void;
      displayFilter?: boolean;
      setDisplayFilter?: (e: boolean) => void;
    }) => {
      
      return (
        <Box css={{ backgroundColor: "#fff" }} p={1} borderRadius={2}>
          <InputBase
            placeholder="Name or ID card Number Search"
            value={keywordsearch}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              console.log('keyword search value:', event.target.value);
              webStore.setFilterUserListPage(1);
              webStore.setFilterWaitingListPage(1);
              setKeywordsearch(event.target.value as string);
            }}
            css={{
              width: 300,
              padding: "8px 15px",
              "@media (max-width: 992px)": {
                fontSize: 12,
                width: inputWidthMobile ? inputWidthMobile : 235,
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // setKeywordsearch(keywordsearch)
            }}
            css={{
              padding: 8,
              minWidth: "unset",
              boxShadow: "unset",
            }}
          >
            <SearchIcon />
          </Button>
        </Box>
      );
    }
  );