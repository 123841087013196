import React from "react";
import { observer } from "mobx-react-lite";
import {
    Box,
    Button,
    Grid,
    FormControl,
    FormControlLabel ,
    RadioGroup ,
    Radio ,
    Modal
  } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import LuxonUtils from "@date-io/luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CachedIcon from '@material-ui/icons/Cached';

export const CalendarBookingModal = observer(
    ({
      isCalenderBooking,
      label,
      note,
      dateArray,
      onClick,
      setIsCalenderBooking,
      setCalenderBookingValue,
      buttonName = "Confirm",
      startDate, 
      setStartDate, 
      endDate,
      setEndDate,
      type,
      setType
    }: {
      isCalenderBooking: boolean;
      setIsCalenderBooking: any;
      label: string;
      note?: any;
      dateArray: any;
      onClick?: any;
      type?: string;
      setType?: any;
      setCalenderBookingValue?: any;
      buttonName?: string;
      startDate: Date;
      setStartDate: any;
      endDate: Date;
      setEndDate: any;
    }) => {
  
      const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCalenderBookingValue({note: event.target.value as string});
      };
      const [value, setValue] = React.useState('0');
      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        let value = parseInt(event.target.value);
        let startDateValue = new Date(startDate);
        let calculateEndDate;
        if(value === 3){
          setType("3Days");
        }else if(value === 7){
          setType("1Week");
        }else if(value === 1){
          setType("1Month");
        }else {
          setType("1Day");
        }
        if(value === 1) {
          calculateEndDate = new Date(startDateValue.setMonth(startDateValue.getMonth() + 1));
        }else{
          calculateEndDate = addDays(startDateValue , value);
        }
        setEndDate(calculateEndDate ? new Date(calculateEndDate.toString()) : null);
      };
      const addDays = (dateObj : any, numDays : number) => {
        if(numDays === 0 ){
          dateObj.setDate(dateObj.getDate() + numDays);
        }else{
          dateObj.setDate(dateObj.getDate() + numDays - 1);
        }
        
        return dateObj;
      }
      return (
        <Modal
          open={isCalenderBooking}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            css={{ backgroundColor: "#fff", width: 500, borderRadius: 30, border: 'solid 2px #3f51b5' }}
            p={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              mt={1}
            >
              <Box css={{ fontWeight: 600 }}>
                <h3>{label}</h3> 
              </Box>
              <Box
                onClick={() => {
                  setIsCalenderBooking(false);
                  setCalenderBookingValue({note: ""});
                }}
              >
                <CloseIcon css={{ cursor: "pointer" }} />
              </Box>
            </Box>
            <br/>
            <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <KeyboardDatePicker
                            style={{
                            margin: 0,
                            width: "100%",
                            backgroundColor: "#fff",
                            }}
                            
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="from"
                            inputVariant="outlined"
                            label="Start Date"
                            autoOk={true}
                            value={startDate}
                            onChange={(date) => {
                            setStartDate(date ? new Date(date.toString()) : null);
                            // onSearch();
                            }}
                            KeyboardButtonProps={{
                            "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <KeyboardDatePicker
                            style={{
                            margin: 0,
                            width: "100%",
                            backgroundColor: "#fff",
                            }}
                            
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="from"
                            inputVariant="outlined"
                            label="End Date"
                            autoOk={true}
                            value={endDate}
                            onChange={(date) => {
                            setEndDate(date ? new Date(date.toString()) : null);
                            // onSearch();
                            }}
                            KeyboardButtonProps={{
                            "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
            </Box>
            <br/>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              mt={1}
            >
              <Box css={{ fontWeight: 600 }}>
                <h3>Note:</h3> 
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
              margin="dense"
              >
              <TextareaAutosize  name="remark-label" placeholder="Please Enter Note"
               rowsMin={3} value={note} onChange={handleOnChange} 
               style={{border:'solid 1px', borderColor:'#c4c4c4', padding: "10px", borderRadius: '10px'}}
               />
              </FormControl>
              
            </Box>
            <br/>
            <Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <CachedIcon/> <span style={{fontWeight: 600}}>Repeat:</span> 
                  </Grid>
                  <Grid item xs={12}>
                  <FormControl component="fieldset" style={{display :'felx'}}>
                    <RadioGroup row aria-label="repeat" name="repeat" value={value} onChange={handleChange}>
                      <FormControlLabel value="0" control={<Radio color="primary" />} label="Never" />
                      <FormControlLabel value="3" control={<Radio color="primary" />} label="3 days" />
                      <FormControlLabel value="7" control={<Radio color="primary" />} label="1 week" />
                      <FormControlLabel value="1" control={<Radio color="primary" />} label="1 month" />
                    </RadioGroup>
                  </FormControl>
                  </Grid>
                </Grid>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt ={2}
            >
              <Button
                onClick={onClick}
                color="primary"
                size="medium"
                variant="contained"
                style={{marginRight: '3%'}}
              >
                {buttonName}
              </Button>
  
              <Button
                onClick={() =>{
                  setIsCalenderBooking(false);
                  setCalenderBookingValue({note: ""});
                }}
                size="medium"
                variant="contained"
              >
                Cancel
              </Button>
            </Box>
            <br/>
            
          </Box>
        </Modal>
      );
    }
  );