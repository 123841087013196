import { Filter, UserFromAPI } from "../../../types/user";
import { useCallAPISpacetrax } from "../../useCallAPI";
import { processServerUser } from "./useReadUser";

export const useListSOSUser = (filter?: Filter) => {
  const [{ data: res, loading, error }] = useCallAPISpacetrax<{
    data: UserFromAPI[];
  }>({
    url: `/v2/sos/pending`,
    method: "GET",
    params: {
      page: filter?.page || 1,
      perPage: filter?.perPage || 10,
    },
  });

  let user = (res?.data || []).map((v) => {
    return processServerUser(v);
  });
  return {
    result_userListSOS_raw: res?.data || [],
    result_userListSOS: user,
    loading_userListSOS: loading,
    error_userListSOS: error,
  };
};
