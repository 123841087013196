import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { webStore } from "../../../stores/webStore";
import { GetPharmacy,UpdatePharmacyStatus,UploadPrescriptionFile } from "../../../services/api/telemedicine/Pharmacy";
import { useSnackbar } from "notistack";
import { dateStringFullMothName } from "../../../utils";
import {
    Box,
    FormControl,
    Select,
    MenuItem,
    Button,
    TextField,
    MuiThemeProvider,
  } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "material-ui-thai-datepickers";
import LuxonUtils from "@date-io/luxon";
import SearchIcon from "@material-ui/icons/Search";
import { ExportButton } from "./Pharmacy";
import { ExcelExport } from "./ExcelExport";
import { theme } from "./Pharmacy";
import MaterialTable from "material-table";
import tableIcons from "../MaterialTableIcons";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import { UpdateStatusModal } from "./UpdateStatusModal";
import { UploadFileModal } from "./UploadFileModal";
import { DetailInfoModal } from "./DetailInfoModal";
import AttachFileIcon from '@material-ui/icons/AttachFile';

export const Pharmacy_recent = observer(() => {
    const pharmacyRecentData :any = []; 
    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
    const [data, setData] = useState(pharmacyRecentData);
    const [addApprovedValue, setAddApprovedValue] = useState<any>();
    const [isApproved, setIsApproved] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [ drugDeliveryId, setDrugDeliveryId ] = useState<any>();
    const [ showUploadFileModal, setShowUploadFileModal ] = React.useState(false); 
    const [ isDetail, setIsDetail ] = useState(false);
    const [ detailData, setDetailData ] = useState<any>();
    const [ updateData, setUpdateData ] = React.useState<any>();
    const [ pageCount, setPageCount ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ rowsPerPage, setRowsPerPage ] = useState(5);
    const [ changeState, setChangeStatus ] = useState(false);
    const { result_pharmacyData, result_pharmacyMeta } = GetPharmacy( startDate, endDate, 1, page, rowsPerPage, patient_name, changeState); 
    const { fire_update_pharmacy } = UpdatePharmacyStatus();
    const { enqueueSnackbar } = useSnackbar();
    const { fire_uploadPrescription } = UploadPrescriptionFile(drugDeliveryId);
    const [ trackingId, setTrackingId ] = useState("");
    const [ address, setAddress ] = useState("");
    const [ phoneNumber, setPhoneNumber ] = useState("");
    const [ deliveryDate, setDeliveryDate ] = useState("");

    useEffect(() => {
        
        if(result_pharmacyData){
            for (let i = 0; i < result_pharmacyData.length ; i++) {
                pharmacyRecentData.push({
                    idTable: i + 1 + page *  rowsPerPage - rowsPerPage,
                    id: result_pharmacyData[i]?.id,
                    name: result_pharmacyData[i]?.first_name + " " + result_pharmacyData[i]?.last_name,
                    gender: result_pharmacyData[i]?.gender === "m" || result_pharmacyData[i]?.gender === "M" ? "Male" : "Female",
                    date: result_pharmacyData[i]?.delivery_date,
                    invoice: result_pharmacyData[i].invoice_file,
                    prescription: result_pharmacyData[i].prescription,
                    address: result_pharmacyData[i].address,
                    phone: result_pharmacyData[i].phone_no
                });

            }
            setData(pharmacyRecentData);

        }else{
            setData([]);
        }

        if(result_pharmacyMeta){
            setPageCount(result_pharmacyMeta?.pageCount);
            setPage(result_pharmacyMeta?.page);
            setRowsPerPage(result_pharmacyMeta?.take);
        }

    }, [result_pharmacyData, result_pharmacyMeta]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: any }>) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    };
    return(
        <Box className="p-4">
            <Box 
                style={{
                    paddingLeft: "10%", 
                    paddingRight: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextField 
                    id="standard-name"
                    value={patient_name} 
                    label="Patient Name"  
                    margin="normal"
                    onChange={(e) => setPatient_name(e.target.value)} 
                    style={{marginLeft: "10px", marginRight: "10px"}}
                />

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="Start Date"
                    value={startDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    autoOk= {true}
                    yearOffset={543}
                    style={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="End Date"
                    value={endDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setEndDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    autoOk = {true}
                    yearOffset={543}
                    style={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider> 

                <Button
                    color="primary"
                    onClick={() => {
                        console.log("search");
                    }}
                    style={{
                    padding: 8,
                    minWidth: "unset",
                    boxShadow: "unset",
                    marginRight: '2%'
                    }}
                >
                    <SearchIcon />
                </Button>

                <Button
                    type="submit"
                    onClick={() => {
                        setPatient_name("");
                        setStartDate(webStore.startDate);
                        setEndDate(webStore.endDate);
                    }}
                    variant="contained"
                    color="primary"
                    style={{marginLeft: "10px", marginRight: "10px"}}
                >
                    Clear
                </Button>

                <ExportButton
                    type="submit"
                    onClick={() => {
                        ExcelExport(startDate, endDate, 1, "pharmacy_recentList.xlsx", "drug-deliveries", page, rowsPerPage, patient_name);
                    }}
                    variant="contained"
                    color="primary" disableRipple 
                    style={{ marginLeft: "10px", marginRight: "10px", color:'#fff' }}
                >
                    Export to .xls
                </ExportButton>   

            </Box>
            <Box className="pt-5">
            <MuiThemeProvider theme={theme}>
            <MaterialTable
                    icons={tableIcons}
                    columns={[
                        { title: 'No.', field: 'idTable' },
                        { title: 'Name', field: 'name', type: 'string' },
                        { title: 'Gender', field: 'gender', type: 'string' },
                        { title: 'Date', field: 'date', type:'date',
                            render: rowData => 
                            <span> {dateStringFullMothName(rowData.date)} </span>
                        },
                        { title: 'Invoice', field: 'invoice',
                            render: rowData => 
                            <a href={rowData.invoice} target="_blank">
                                <span style={{color: '#6371ff'}}><u>invoice file</u></span>
                            </a>
                        },
                        {
                            title:'Prescription', field: 'prescription',
                            render: rowData => 
                            <>
                                {
                                    rowData.prescription === "" || rowData.prescription === "string" ?
                                    <>
                                        <span 
                                        onClick={() => {
                                            setDrugDeliveryId(rowData);
                                            setShowUploadFileModal(true);
                                            setChangeStatus(true);
                                        }}
                                        style= {{color : '#303f9f'}}
                                        > <u> <AttachFileIcon style={{fontSize: '20px'}}/> Upload file </u> </span>
                                    </>
                                    :
                                    <>
                                        <a href={rowData.prescription} target="_blank">
                                            <span style={{color: '#6371ff'}}><u>prescription file</u></span>
                                        </a>
                                    </>
                                }
                            </>
                        },
                        {
                            title: "Actions",
                            render: rowData => {
                              return (
                                  <IconButton
                                    aria-label="edit"
                                    // disabled={false}
                                    onClick={() => {
                                      setLoading(true);
                                      setIsApproved(rowData);
                                      setUpdateData([rowData]);
                                      setDetailData(rowData);
                                      setDeliveryDate(rowData.date);
                                      setChangeStatus(true);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                              );
                            }
                        },
                        {
                            title: "Detail",
                            render: rowData => {
                              return (
                                <Box
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flex: 1,
                                  borderRadius: 5,
                                }}
                                onClick={() =>{ setIsDetail(true)
                                        setDetailData(rowData);
                                    }}
                              >
                                <Button  variant="contained" color="primary"> More </Button>
                              </Box>
                              );
                            }
                        }
                    ]}
                    data={data}
                     
                    options={{
                        selection: true,
                        showTitle: false,
                        search: false,
                        paging: false
                    }}
                    actions={[
                        {
                          tooltip: 'Update Status for selected users',
                          icon: () => <EditIcon />,
                          onClick: (evt, data) => {
                            setLoading(true);
                            setIsApproved(data);
                            setUpdateData(data);
                            setChangeStatus(true);
                          }

                        }
                    ]}
                />
                <UploadFileModal 
                    isUploadFile={showUploadFileModal}
                    setIsUploadFile= {setShowUploadFileModal} 
                    onClick={async(file: any) =>{
                        console.log("file", file);
                        if(file.length > 0){
                            fire_uploadPrescription({
                                file: file[0],
                            })
                            .then(() => {
                                enqueueSnackbar("File is uploaded.", {
                                variant: "success",
                                autoHideDuration: 3000,
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                },
                                });
                                setChangeStatus(false);
                                setShowUploadFileModal(false);
                            })
                            .catch((ex) => {
                                enqueueSnackbar(
                                "Error" +
                                    (ex ? " : " + ex.toString() : ""),
                                {
                                    variant: "error",
                                    autoHideDuration: 3000,
                                    anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                    },
                                }
                                );
                                setChangeStatus(false);
                                setShowUploadFileModal(false);
                            });
                        }
                    }}
                    />
            </MuiThemeProvider>

            <Box mt={2}>
                  
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handleChange}
                    size="small"
                  />
                  <FormControl style={{float:'right', marginTop: '-36px', marginRight: '2%'}} >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rowsPerPage}
                      onChange={handleChangeRowsPerPage}
                    >
                      <MenuItem value={5}>5/ per page</MenuItem>
                      <MenuItem value={10}>10/ per page</MenuItem>
                      <MenuItem value={25}>25/ per page</MenuItem>
                      <MenuItem value={50}>50/ per page</MenuItem>
                      
                      
                    </Select>
                  </FormControl>
                </Box>
                

                <UpdateStatusModal
                    {...addApprovedValue}
                    isApproved={isApproved}
                    setIsApproved={setIsApproved}
                    setAddApprovedValue={setAddApprovedValue}
                    isDelivery= {false}
                    status = "Delivery"
                    trackingId={trackingId}
                    setTrackingId = { setTrackingId }
                    detailData = { detailData }
                    address = { address }
                    setAddress = { setAddress }
                    deliveryDate = { deliveryDate }
                    setDeliveryDate = { setDeliveryDate }
                    setPhoneNumber = { setPhoneNumber }
                    phoneNumber = { phoneNumber }
                    label={isApproved?.length > 0 ? isApproved?.length + " Item(s) Selected" : isApproved?.name}
                    onClose={async () => {
                        setIsApproved(false);
                        setLoading(false);
                    }}
                    onClick={async () => {
                        setLoading(true);
                        setIsApproved(false);
                        setLoading(false);
                        
                        fire_update_pharmacy( updateData, 2, trackingId, address, phoneNumber, deliveryDate)
                        .then(() => {
                            enqueueSnackbar("Success update data", {
                            variant: "success",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                            });
                            setChangeStatus(false);
                        })
                        .catch((ex) => {
                            enqueueSnackbar(
                            "Error" +
                                (ex ? " : " + ex.toString() : ""),
                            {
                                variant: "error",
                                autoHideDuration: 3000,
                                anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                                },
                            }
                            );
                            setChangeStatus(false);
                        });
                    }}
                />

                    <DetailInfoModal
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    setAddApprovedValue={setAddApprovedValue}
                    address={ detailData?.address }
                    phoneNumber = { detailData?.phone }
                    onClose={async () => {
                        setIsDetail(false);
                    }}
                    onClick={async () => {
                        setIsDetail(false);
                    }}
                />
            </Box>
        </Box>
    );
});