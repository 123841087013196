import { FormInput } from "./Finance";
import { CallTelemed } from "../../useCallAPI";
import { PatientDataFilter, PatientListData } from "../../../types/Appointment";


export const PateintUploadService = () => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
      code: 0 | 1;
      message: string;
      data: any;
    }>(
      {
        url: `/v2/dashboard-patient/import`,
        method: "POST",
        headers: {
          "content-type": "multipart/form-data",
        },
      },
      {
        manual: true,
      }
    );
    return {
      result_form_upload: res?.code === 1 ? res?.data : null,
      loading_form_upload: loading,
      error_form_upload: res?.code === 0 ? res?.message : null,
      fire_form_upload: (FormInput: FormInput) => {
        var formData = new FormData();
        formData.append("upload", FormInput.file, FormInput.file.name);
        return new Promise((resolve, reject) => {
          fire({
            data: formData,
          })
            .then(({ data: { message, code, data } }) => {
              if (code === 0) {
                reject(message);
              } else {
                resolve(data);
              }
            })
            .catch((ex) => {
              reject(ex.toString());
            });
        });
      },
    };
  };

  export const GetPatientData = (filter?: PatientDataFilter) => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: PatientListData[]
      }>(
        {
          url:  "/v2/dashboard-patient/",
          method: "GET",
          params: {
            page: filter?.page,
            limit: filter?.perPage,
            keyword: filter?.filter_keyword
          },
        }
      );
    
      return {
        result_patientListData: res?.data ? res?.data : [],
        loading_patienListtData: loading,
        error_patientListData: error
      };
  }