import React from "react";
import { observer } from "mobx-react-lite";
import {
    Box,
    Button,
    Grid,
    Modal,
    TextField
  } from "@material-ui/core";
import LuxonUtils from "@date-io/luxon";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import { dateStrFormat } from "../../../utils"; 

export const PostponeModal = observer(
    ({
      isPostpone, 
      setIsPostpone,
      postponeDate,
      setPostponeDate,
      postponeTime, 
      setPostponeTime,
      isSuccessPostpone,
      setIsSuccessPostpone,
      postponeEndTime, 
      setPostponeEndTime,
      onClick
    }: {
      isPostpone: boolean;
      setIsPostpone: any;
      postponeDate : Date;
      setPostponeDate: any;
      postponeTime : string;
      setPostponeTime : any;
      isSuccessPostpone: boolean;
      setIsSuccessPostpone: any;
      onClick: any;
      postponeEndTime: any;
      setPostponeEndTime: any;
    }) => {
      return (
        <Modal
          open={isPostpone}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            css={{ backgroundColor: "#fff", width: 500, borderRadius: 30, border: 'solid 2px #3f51b5' }}
            p={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              mt={1}
            >
              <Box css={{ fontWeight: 600}}>
                <h3 style={{fontSize : "20px" }}>Postpone</h3> 
              </Box>
              <Box
                onClick={() => {
                  setIsPostpone(false);
                //   setCalenderBookingValue({note: ""});
                }}
              >
                <CloseIcon css={{ cursor: "pointer" }} />
              </Box>
            </Box>
            <br/>
                
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <KeyboardDatePicker
                            style={{
                            margin: 0,
                            width: "100%",
                            backgroundColor: "#fff",
                            }}
                            
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="from"
                            inputVariant="outlined"
                            label="Select Date"
                            autoOk={true}
                            value={postponeDate}
                            onChange={(date) => {
                                setPostponeDate(date ? new Date(date.toString()) : null);
                            // onSearch();
                            }}
                            KeyboardButtonProps={{
                            "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                        id="time"
                        label="Start Time"
                        type="time"
                        defaultValue="10:00"
                        variant="outlined"
                        onChange={(event) => {
                            console.log("time changes:", event.target.value);
                            setPostponeTime(event.target.value);
                        }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        inputProps={{
                        step: 900
                        }}
                        style={{width: '100%'}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                        <TextField
                        id="time"
                        label="End Time"
                        type="time"
                        defaultValue="10:15"
                        variant="outlined"
                        onChange={(event) => {
                            console.log("time changes:", event.target.value);
                            setPostponeEndTime(event.target.value);
                        }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        inputProps={{
                        step: 900
                        }}
                        style={{width: '100%'}}
                    />
                    </Grid>
                </Grid>
            </Box>
            <br/>
            {
              postponeTime ?
              <Grid container>
                <Grid item xs={12} style={{textAlign:'center'}}>  {"The Appointment will be postponed to "} </Grid>

                <Grid item xs={12} style={{textAlign:'center'}}>  
                    { dateStrFormat(postponeDate) + " ,"} 
                    {
                      postponeTime ? 
                      <>
                        {
                          postponeTime > "12:00" || postponeTime === '12:00'?
                          <>
                            {postponeTime + "PM"}
                          </>
                          :
                          <>
                            {postponeTime + "AM"}
                          </>
                        }
                      </>
                      : '10:00AM' } to 
                      {
                         postponeEndTime ? 
                      <>
                        {
                          postponeEndTime > "12:00" || postponeEndTime === '12:00'?
                          <>
                            {postponeEndTime + "PM"}
                          </>
                          :
                          <>
                            {postponeEndTime + "AM"}
                          </>
                        }
                      </>
                      : '10:15AM' }
                      .
                </Grid>
              </Grid>
              : <></>
            }
                
            <br/>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt ={2}
            >
              <Button
                onClick={onClick}
                color="primary"
                size="medium"
                variant="contained"
                style={{marginRight: '3%'}}
              >
                Update
              </Button>
  
              <Button
                onClick={() =>{
                  setIsPostpone(false);
                }}
                size="medium"
                variant="contained"
              >
                Cancel
              </Button>
            </Box>
            <br/>
            
          </Box>
        </Modal>
      );
    }
  );