import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    TextField,
    FormControl, 
    MenuItem, 
    Select,
    MuiThemeProvider
  } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { webStore } from "../../../stores/webStore";
import { GetFinance } from "../../../services/api/telemedicine/Finance";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "material-ui-thai-datepickers";
import LuxonUtils from "@date-io/luxon";
import SearchIcon from "@material-ui/icons/Search";
import { ExportButton } from "../Pharmacy/Pharmacy";
import { ExcelExport } from "../Pharmacy/ExcelExport";
import Pagination from "@material-ui/lab/Pagination";
import MaterialTable, { MTablePagination } from "material-table";
import tableIcons from "../MaterialTableIcons";
import { theme } from "../Pharmacy/Pharmacy";
import { dateStringFullMothName } from "../../../utils";

export const Finance_Completed = observer(() => {
    const rando = (max: any) => Math.floor(Math.random() * max);
    const financeCompleteData:any = [];

    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
    const [data, setData] = useState(financeCompleteData);
    const [addApprovedValue, setAddApprovedValue] = useState<any>();
    const [isApproved, setIsApproved] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [ pageCount, setPageCount ] = useState(0);
    const [ page, setPage ] = useState(1);
    const [ rowsPerPage, setRowsPerPage ] = useState(5);
    const { result_financeData, loading_financeData,result_meta } = GetFinance(startDate , endDate, 3, page, rowsPerPage, patient_name);

    useEffect(() => {
        if(result_financeData){
            for (let i = 0; i < result_financeData.length ; i++) {
                financeCompleteData.push({
                    idTable: i + 1 + page *  rowsPerPage - rowsPerPage,
                    id: result_financeData[i]?.id,
                    name: result_financeData[i]?.first_name + " " + result_financeData[i]?.last_name,
                    gender: result_financeData[i]?.gender === "m" || result_financeData[i]?.gender === "M" ? "Male" : "Female",
                    invoice: result_financeData[i]?.invoice_file,
                    amount: result_financeData[i]?.amount,
                    status: "Approved",
                    completed_Date: dateStringFullMothName(result_financeData[i]?.invoice_date)
                });
            }
            setData(financeCompleteData);
        }

        if(result_meta){
            setPageCount(result_meta?.pageCount);
            setPage(result_meta?.page);
            setRowsPerPage(result_meta?.take);
        }

    }, [result_financeData, result_meta]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: any }>) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    };

    return(
        <Box className="p-4">
            <Box 
                style={{
                    paddingLeft: "10%", 
                    paddingRight: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                
                <TextField 
                    id="standard-name"
                    value={patient_name} 
                    label="Patient Name"  
                    margin="normal"
                    onChange={(e) => setPatient_name(e.target.value)} 
                    style={{marginLeft: "10px", marginRight: "10px"}}
                />

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="Start Date"
                    value={startDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    autoOk = {true}
                    yearOffset={543}
                    style={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="End Date"
                    value={endDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setEndDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    autoOk = {true}
                    yearOffset={543}
                    style={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider> 

                <Button
                    color="primary"
                    onClick={() => {
                        console.log("search");
                    }}
                    style={{
                    padding: 8,
                    minWidth: "unset",
                    boxShadow: "unset",
                    marginRight: '2%'
                    }}
                >
                    <SearchIcon />
                </Button>

                <Button
                    type="submit"
                    onClick={() => {
                        setPatient_name("");
                        setStartDate(new Date());
                        setEndDate(new Date());
                    }}
                    variant="contained"
                    color="primary"
                    style={{marginLeft: "10px", marginRight: "10px"}}
                >
                    Clear
                </Button>

                <ExportButton
                    type="submit"
                    onClick={() => {
                        ExcelExport(startDate, endDate, 3, "Finance_completed_list.xlsx", "medical-billing", page, rowsPerPage, patient_name);
                    }}
                    variant="contained"
                    color="primary" disableRipple 
                    style={{ marginLeft: "10px", marginRight: "10px", color:'#fff' }}
                    // onClick = {excelExport}
                >
                    Export to .xls
                </ExportButton>   

            </Box>
            <Box className="pt-5">
                <MuiThemeProvider theme={theme}>
                    <MaterialTable
                        icons={tableIcons}  
                        columns={[
                            { title: 'No.', field: 'idTable' },
                            { title: 'Name', field: 'name', type: 'string' },
                            { title: 'Gender', field: 'gender', type: 'date' },
                            { title: 'Invoice', field: 'invoice', type: 'string',
                                render: rowData => 
                                <a href={rowData.invoice} target="_blank">
                                    <span style={{color: '#6371ff'}}><u>invoice file</u></span>
                                </a>
                            },
                            { title: 'Amount', field: 'amount', type: 'string' },
                            { title: 'Status', field: 'status', type: 'string' },
                            { title: 'Completed Date', field: 'completed_Date', type: 'date' },
                        ]}
                        data={data}
                            
                        options={{
                            showTitle: false,
                            search: false,
                            paging: false
                        }}
                        components={{
                            Pagination: (props) => {
                                return(
                                    <Box display="flex" justifyContent="flex-end"> <Box width="260px" justifyContent="flex-end">
                                        <MTablePagination {...props} count={pageCount} onChangePage={(e:any, page:any) => setPage(page + 1)} page={page} rowsPerPage={rowsPerPage} rowsPerPageOptions={[5, 10, 20, 30]}/>
                                    </Box></Box>
                                )
                        }}}
                    />
                </MuiThemeProvider>
                <Box mt={2}>
                  
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handleChange}
                    size="small"
                  />
                  <FormControl style={{float:'right', marginTop: '-36px', marginRight: '2%'}} >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rowsPerPage}
                      onChange={handleChangeRowsPerPage}
                    >
                      <MenuItem value={5}>5/ per page</MenuItem>
                      <MenuItem value={10}>10/ per page</MenuItem>
                      <MenuItem value={25}>25/ per page</MenuItem>
                      <MenuItem value={50}>50/ per page</MenuItem>
                      
                      
                    </Select>
                  </FormControl>
                </Box>
            </Box>
        </Box>
    );
});