import React, { useEffect, useState } from "react";
import {
  Grid
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import '../VideoCallStyle.css';
import { SubBar } from "../../Dashboard/SubBar";
import '../Messenger.css';
import { PatientInfo } from "./PatientInfo";
import { ChatMessage } from "./ChatMessage";

export const Chat  = observer(() => {
  const { index, name, surname, chatChannel, chatChannelType, appointmentId }: any = useParams();
  const [ userId, setUserId ] = useState("");

  
  useEffect(() => {
    
    if(index){
      setUserId(index);
    }
  }, [index]);

  return (
    <>
          <SubBar title={"Messages"} back={true} reload={false}/>
          <Grid container style={{padding: '10px'}}>
                  <Grid item md={4} xs={12}>
                      <PatientInfo/>
                  </Grid>
                  <Grid item  md={8} xs = {12}>
                    <ChatMessage/>
                  </Grid>
          </Grid>
          
          
    </>
  );
});