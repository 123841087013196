import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Button,
  withStyles,
  Typography,
  Tabs,
  Tab,
  createMuiTheme,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import AppBar from "@material-ui/core/AppBar";
import '../Messenger.css';  
import { Pharmacy_recent } from "./PharmacyRecent";
import { Pharmacy_delivery } from "./PharmacyDelivery";
import { Pharmacy_delivered } from "./PharmacyDelivered";
import { Pharmacy_Complete } from "./PharmacyComplete";

export const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#303f9f',
      },
    },

  });

export const ExportButton = withStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      backgroundColor: '#0CA789',
      borderColor: '#0CA789',
      '&:hover': {
        backgroundColor: '#0CA789',
        borderColor: '#0CA789',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0CA789',
        borderColor: '#0CA789',
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
})(Button);
  
export interface customeLabels {
    data: any;
    labels: any;
    color: any;
    showValue?: boolean;
    itemsCountPerPage?: number;
}

    interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box>
            <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

export const Pharmacy = observer(() => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  
  return(
    <Container maxWidth="xl" className="py-4">
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className="flex justify-between items-center flex-wrap"
                    pt={4}
                    mb={3}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        css={{
                            "@media (max-width: 992px)": {
                            width: "100%",
                            justifyContent: "space-between",
                            },
                        }}
                        mb={{ xs: 3, md: 0 }}
                    >
                        <Box fontSize="h5.fontSize" fontWeight={500} mr={3}>
                            Pharmacy
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" style={{backgroundColor: "transparent"}}>
                    <Tabs value={value} onChange={handleChange} style={{color: "#000"}}>
                        <Tab label="Recent" />
                        <Tab label="Delivery" />
                        <Tab label="Delivered" />
                        <Tab label="Completed" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Grid item xs={12} style={{border: "1px solid"}}>
                        <Box className="py-4">
                            <Pharmacy_recent/>
                        </Box>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid item xs={12} style={{border: "1px solid"}}>
                        <Pharmacy_delivery/>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid item xs={12} style={{border: "1px solid"}}>
                        <Pharmacy_delivered/>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Grid item xs={12} style={{border: "1px solid"}}>
                        <Pharmacy_Complete/>
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    </Container>
  );
});
export const MobilePharmacy = Pharmacy;