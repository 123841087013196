import { CallTelemed } from "../../useCallAPI";

export const SentMessage = () =>{
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        code: 0 | 1;
        message: string;
        data: any;
    }>(
      {
        url: `/v2/message/doctor/text`,
        method: "POST",
      },
      { manual: true }
    );
    return {
      result_sentMessage: res?.data,
      loading_sentMessage: loading,
      error_sentMessage: error,
      fire_sentMessage: (channelName: string, message: string) => {
          let requestData = {};
          requestData = {
              channel_name: channelName,
              message_type: 'text',
              message: message
          }
      return new Promise((resolve, reject) => {
        fire({
          data: requestData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

  export const SentVoiceMessage = () =>{
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        code: 0 | 1;
        message: string;
        data: any;
    }>(
      {
        url: `/v2/message/doctor/voice`,
        method: "POST",
      },
      { manual: true }
    );
    return {
      result_sentVoiceMessage: res?.data,
      loading_sentVoiceMessage: loading,
      error_sentVoiceMessage: error,
      fire_sentVoiceMessage: (channelName: string, message: any, duration: any) => {
          let formData = new FormData();
          formData.append("channel_name", channelName);
          formData.append("duration", duration);
          formData.append("message", message);
      return new Promise((resolve, reject) => {
        fire({
          data: formData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

export const GetMessage = (userId: any, channelName: any ) => {
const [{ data: res, loading, error }, fire] = CallTelemed<{
    data: any;
    }>(
    {
        url:  `/v2/message/doctor?channel_name=${channelName}`,
        method: "GET",
    }
    );
    let allMessage = [];
    let messages : any =  [];
    if(res?.data?.message){
      let messageData = res?.data?.message; 
      if(messageData.length > 0){
        localStorage.setItem("lastMessageId", messageData[0].id);
        messages.push({
          msg: {"text" : messageData[0].message},
          uid: messageData[0].sender_id === messageData[0].doctor?.id ? "You" :  messageData[0].patient?.name +" " + messageData[0].patient?.surname,
          date: messageData[0].created_at, 
          messageId: messageData[0].id
        })
      }
      
      for(let i = 0; i< messageData.length; i++){
        allMessage.push({
          msg: {"text" : messageData[i].message},
          uid: messageData[i].sender_id === messageData[i].doctor?.id ? "You" :  messageData[i].patient?.name +" " + messageData[i].patient?.surname,
          date: messageData[i].created_at, 
          messageId: messageData[i].id
        })
        
      }
    }

    return {
    result_getMessage: res?.data ? res?.data : null,
    messages: allMessage.reverse() || [],
    lastMessageData: messages || [],
    loading_getMessage: loading,
    error_getMessage: error
    };
}

export const GetMessageByLimit = (channelName: any , scrollPosition?: any) => {
  let param = {}; 
  if(scrollPosition === 0){
    param = {
      last_message_id: localStorage.getItem("lastMessageId") ? localStorage.getItem("lastMessageId") : "",
      limit: 7,
      scrollPosition: scrollPosition
    };
  }else{
    param = {
      last_message_id: localStorage.getItem("lastMessageId") ? localStorage.getItem("lastMessageId") : "",
      limit: 7
    }
  }
  const [{ data: res, loading, error }, fire] = CallTelemed<{
      data: any;
      }>(
      {
          url:  `/v2/message/doctor?channel_name=${channelName}`,
          method: "GET",
          params: param
      }
      );
      let messages = [];
    
      if(res?.data?.message){
        let messageData = res?.data?.message; 
        for(let i = 0; i< messageData.length; i++){
          messages.push({
            msg: {"text" : messageData[i].message},
            uid: messageData[i].sender_id === messageData[i].doctor?.id ? "You" :  messageData[i].patient?.name +" " + messageData[i].patient?.surname,
            date: messageData[i].created_at, 
            messageId: messageData[i].id
          })
          
        }
      }

      return {
      result_getMessageByLimit: res?.data ? res?.data : null,
      messagesByLimit: messages.reverse() || [],
      loading_getMessageByLimit: loading,
      error_getMessageByLimit: error
      };
  }