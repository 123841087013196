import { dateStrAPIFormat } from "../../../utils";
import axios, { AxiosRequestConfig } from 'axios';
import { saveAs } from "file-saver";

export const ExcelExport = (startDate: any, endDate: any, status: any, fileName: string, exportFor: string, page?: any, rowsPerPage?: any, patientname?: any) => {
    let instance = axios.create({  baseURL: "https://api-navamin9.space-trax.com" }); 
    const method = 'GET';

    var start_date;
    var end_date;
    if(startDate){
        start_date = dateStrAPIFormat(startDate);
    }else{
        start_date = dateStrAPIFormat(new Date());
    };

    if(endDate){
        end_date = dateStrAPIFormat(endDate);
    }else{
        end_date =dateStrAPIFormat(new Date());
    }
    
    const url = "https://api-navamin9.space-trax.com/v2/"+ exportFor +"/excel?page="+ page
    +"&take=" + rowsPerPage + "&invoiceStartDate=" + start_date + "&invoiceEndDate=" + end_date
    +"&invoiceStatus=" + status + "&name=" + patientname;

    const headers = {
      "Project-Key": localStorage.getItem("projectKey"),
        Authorization: "Bearer " + localStorage.getItem("token"),
    }
    let options: AxiosRequestConfig = { 
      url,
      method,
      responseType: 'blob' ,
      headers
    };

    return instance.request<any>(options)
    .then(response => { 
      let url = window.URL
        .createObjectURL(new Blob([response.data]));     
      saveAs(url, fileName);    
  });
  }