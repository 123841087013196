import  React, { useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { dateStringFullMothName,timeString } from "../../../utils";
import { SubBar } from "../../Dashboard/SubBar";
import PersonIcon from '@material-ui/icons/Person';
import { GetBookingInfoById, AcceptBooking, DeclineBooking } from "../../../services/api/telemedicine/BookingService";  
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

export const AcceptNewAppointment  = observer(() => {
  const history = useHistory();
  const { index }: any = useParams();
  const { result_bookingData, loading_bookingData } = GetBookingInfoById(index);
  const { loading_acceptBooking, fire_acceptBooking } = AcceptBooking(index);
  const { loading_declineBooking, fire_declineBooking } = DeclineBooking(index);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
  }, []);

  return (
    <>
          <SubBar title={"New Appointment"} back={true} reload={false}/>
          <Grid item xs={12} css={{ padding: "0 30px" , paddingTop: '30px'}}>            
            <Box
                style={{position: "relative", overflow: "auto", height: '90vh' }}
                css={{ borderRadius: 5 }}
                >
                    <Box
                        css={{ backgroundColor: "#fff", 
                        width: "100%", 
                        borderRadius: 20, 
                        border: 'solid 2px #3f51b5'
                        }}
                        p={2}
                        mb={1}
                        mt={1}
                        display="flex"
                        onClick={() => {}}
                    >
                        <Box pr={8} pt={5} pl={3}
                        >
                        <Avatar style={{ width: 80, height: 80, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <PersonIcon style={{fontSize: '60px'}}/>
                        </Avatar>
                        </Box>

                        <Grid container>
                            <Grid item xs={6}>
                                <Box mb={2} position="relative">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    css={{ color: "#35353F", marginTop:'4px' }}
                                >
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Name: </span>  {result_bookingData?.patient?.name + " " + result_bookingData?.patient?.surname}
                                    </Box>
                                </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Gender: </span>  
                                    { result_bookingData?.patient?.gender === 'm' ? "Male"
                                        : result_bookingData?.patient?.gender === 'f' ? "Female"
                                        : ""
                                    } 
                                    </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Booking Type:</span>{result_bookingData?.appointment_type}
                                    
                                    </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Date: </span> {}
                                    {result_bookingData?.start_time ? dateStringFullMothName(result_bookingData?.start_time) : ""}
                                    </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}>Start Time: </span>  {result_bookingData?.start_time ? timeString(result_bookingData?.start_time): ""}
                                    </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}>End Time: </span>  {result_bookingData?.end_time ? timeString(result_bookingData?.end_time) : ""}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box mb={2} position="relative">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    css={{ color: "#35353F", marginTop:'4px' }}
                                >
                                    <Box fontSize={15} mr={1}>
                                    <span style={{fontWeight: 'bold'}}> Symptom case: </span>
                                    </Box>
                                </Box>
                                    <br/>
                                    <Box fontSize={15} mr={1}>
                                         {
                                            result_bookingData?.symptom
                                        } 
                                        
                                    </Box>
                                    <br/>
                                    <br/>
                                </Box>
                            </Grid>
                            <br/>
                            <Grid item xs={12}>
                                <span style={{fontSize: '18px', fontWeight: 600}}> Please Confirm this appointment </span>

                                <Button  style={{textAlign: 'center', margin: '0 30px'}}
                                    variant="contained"
                                    color= 'primary'
                                    onClick = {async() => {
                                      try {
                                        fire_acceptBooking()
                                          .then(() => {
                                            enqueueSnackbar("Success", {
                                              variant: "success",
                                              autoHideDuration: 3000,
                                              anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                              },
                                            });
                                            history.push("/telemedicine/appiontment");
                                          })
                                          .catch((ex) => {
                                            enqueueSnackbar(
                                              "Error" +
                                                (ex ? " : " + ex.toString() : ""),
                                              {
                                                variant: "error",
                                                autoHideDuration: 3000,
                                                anchorOrigin: {
                                                  vertical: "top",
                                                  horizontal: "center",
                                                },
                                              }
                                            );
                                            history.push("/telemedicine/appiontment");
                                          });
                                      } catch (ex) {}
                                    }}
                                > Accept
                                </Button>

                                <Button  style={{textAlign: 'center'}}
                                    variant="contained" color="secondary"
                                    onClick = {async() => {
                                      try {
                                        fire_declineBooking()
                                          .then(() => {
                                            enqueueSnackbar("Success", {
                                              variant: "success",
                                              autoHideDuration: 3000,
                                              anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                              },
                                            });
                                            history.push("/telemedicine/appiontment");
                                          })
                                          .catch((ex) => {
                                            enqueueSnackbar(
                                              "Error" +
                                                (ex ? " : " + ex.toString() : ""),
                                              {
                                                variant: "error",
                                                autoHideDuration: 3000,
                                                anchorOrigin: {
                                                  vertical: "top",
                                                  horizontal: "center",
                                                },
                                              }
                                            );
                                            history.push("/telemedicine/appiontment");
                                          });
                                      } catch (ex) {}
                                    }}
                                    > Decline
                                </Button>
                            </Grid>
                        </Grid>

                    </Box>
            </Box>
        </Grid>
    </>
  );
});