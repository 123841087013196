import { observer } from "mobx-react-lite";
import {
    Box,
    Button,
    Modal
  } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const DetailInfoModal = observer(
    ({
      isDetail,
      address,
      phoneNumber,
      onClick,
      onClose,
      setIsDetail,
      setAddApprovedValue,
      buttonNameConfirm = "OK",
      buttonNameCANCEL = "CANCEL",

    }: {
      isDetail: boolean;
      setIsDetail: any;
      address: string;
      phoneNumber: string;
      onClick?: any;
      onClose?: any;
      type?: string;
      setAddApprovedValue?: any;
      buttonNameConfirm?: string;
      buttonNameCANCEL?: string;
    }) => {
  
      const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAddApprovedValue({note: event.target.value as string});
      };
  
      return (
        <Modal
          open={isDetail}
          onClose={() => {}}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Box
                style={{ backgroundColor: "#fff", width: 400, borderRadius: 30, border: 'solid 2px #3f51b5' }}
                p={2}
            >
                <Box
                    display="flex"
                    justifyContent="right"
                    alignItems="center"
                    mb={1}
                    mt={1}
                >
                <Box onClick={() =>{setIsDetail(false)}}>
                    <CloseIcon style={{ cursor: "pointer" }} />
                </Box>
            </Box>

            <Box
                display="flex"
                style={{ color: "#4B4C56" }}
            >
                <h1 style={{ color: "#0c1679", fontSize: "20px" }}> More Details </h1>
                
            </Box>
            <Box mt={4}>
                <p style={{ color: "#4B4C56", fontSize: "15px" }}>Address: {address}</p> 
                <p className="mt-3" style={{ color: "#4B4C56", fontSize: "15px" }}>Phone Number: {phoneNumber}</p>
                
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt ={2}
                >
                <Button
                    style={{margin: "10px"}}
                    onClick={onClick}
                    color="primary"
                    size="medium"
                    variant="contained"
                >
                    {buttonNameConfirm}
                </Button>

                </Box>
                <br/>
          </Box>
        </Modal>
      );
    }
);