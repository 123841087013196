import { AppointmentData, PatientData, CalendarData,AppointmentDataById, Doctor } from "../../../types/Appointment";
import { dateStrAPI,timeString } from "../../../utils";
import { CallTelemed } from "../../useCallAPI";

export const GetAppointmentData = () => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: AppointmentData
      }>(
        {
          url:  `/v2/smart-infirmary/booking/doctor`,
          method: "GET",
        }
      );
    
      return {
        result_appointment: res?.data ? res?.data : null,
        loading_appointment: loading,
        error_appoinment: error
      };
  }

  export const GetAccpetedAppointment = () => {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const timezone = offset / (-60);

    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: AppointmentDataById[]
      }>(
        {
          url:  `/v2/dashboard/patient/appointment/status`,
          method: "GET",
          params: {
            tz: setTimezone(timezone.toString()),
            status: "accept"
          }
        }
      );
    
      return {
        result_acceptedAppointment: res?.data ? res?.data : [],
        loading_acceptedAppointment: loading,
        error_acceptedAppoinment: error
      };
  }


  export const GetPatientData = (id : string) => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: PatientData
      }>(
        {
          url:  `/v2/smart-infirmary/booking/patient/` + id + "/all",
          method: "GET"
        }
      );
    
      return {
        result_patientData: res?.data ? res?.data : null,
        loading_patientData: loading,
        error_patientData: error
      };
  }

  export const GetBookingHistory = (id : string) => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: AppointmentDataById[]
      }>(
        {
          url:  `/v2/smart-infirmary/booking/patient/` + id + "/history",
          method: "GET"
        }
      );
    
      return {
        result_bookingHistory: res?.data ? res?.data : [],
        loading_bookingHistory: loading,
        error_bookingHistory: error
      };
  }

  export const CreateCalendar = () =>{
    const [{ data: res, loading, error }, fire] = CallTelemed<{
      code: 0 | 1;
      message: string;
      // data: User;
      data : any;
    }>(
      {
        url:  `/v2/dashboard/calendar`,
        method: "POST",
      },
      {
        manual: true,
      }
    );
    return {
      result_create_calendar: res?.code === 1 ? res?.data : null,
      loading_create_calendar: loading,
      error_create_calendar: res?.code === 0 ? res?.message : null,
      fire_create_calendar: (dateArray: any, startDate: Date, endDate: Date, allBookingTimeData: any, type: any) => {
        let filterDateArray = dateArray
                 .filter((x : {x: any}) => !allBookingTimeData.includes(x))
                 .concat(allBookingTimeData.filter( (x: {x: any}) => !dateArray.includes(x)));
        
        let inputData = {
          period : filterDateArray,
          start_date: dateStrAPI(startDate) + " GMT +07:00",
          end_date: dateStrAPI(endDate)+ " GMT +07:00", 
          type: type
        };
        
        return new Promise((resolve, reject) => {
          fire({
            data: inputData,
          })
            .then(({ data: { data, code, message } }) => {
              if (code === 0) {
                reject(message);
              } else {
                resolve(data);
              }
            })
            .catch((ex) => {
              reject(ex.toString());
            });
        });
      },
    };
  }

  export const GetCalenderByDoctor = (date: Date) => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: CalendarData
      }>(
        {
          url:  `/v2/dashboard/calendar`,
          method: "GET",
          params: {
            date: dateStrAPI(date)
          },
        }
      );

      let result_data = res?.data;
      let firstRow :any = [];
      let secondRow :any = [];
      let thirdRow : any = [];
      let fourthRow : any = [];
      let fifthRow : any = [];
      let sixthRow : any = [];
      let AllBookingTimeData : any = [];
      let idArray : any = [];

      if(result_data){
        for(let i = 0 ; i < result_data.length ; i ++) {
          let startTime = timeString(result_data[i].start_time);
          let endTime = timeString(result_data[i].end_time);
          if( startTime === "07:00" || startTime === "07:15" || startTime === "07:30" || startTime === "07:45"
            || startTime === "08:00" || startTime === "08:15"){
              firstRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);
              idArray.push({ time: startTime+ '-'+ endTime, id: result_data[i].id });
          }
          if( startTime === "08:30" || startTime === "08:45" || startTime === "09:00" || startTime === "09:15"
            || startTime === "09:30" || startTime === "09:45"){
              secondRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);
              idArray.push({ time: startTime+ '-'+ endTime, id: result_data[i].id });
          }
          if( startTime === "10:00" || startTime === "10:15" || startTime === "10:30" || startTime === "10:45"
            || startTime === "11:00" || startTime === "11:15"){
              thirdRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);
              idArray.push({ time: startTime+ '-'+ endTime, id: result_data[i].id });
          }
          if( startTime === "11:30" || startTime === "11:45" || startTime === "12:00" || startTime === "12:15"
            || startTime === "12:30" || startTime === "12:45"){
              fourthRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);    
              idArray.push({ time: startTime+ '-'+ endTime, id: result_data[i].id });          
          }
          if( startTime === "13:00" || startTime === "13:15" || startTime === "13:30" || startTime === "13:45"
            || startTime === "14:00" || startTime === "14:15"){
              fifthRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);
              idArray.push({ stime: startTime+ '-'+ endTime, id: result_data[i].id });
          }
          if( startTime === "14:30" || startTime === "14:45" || startTime === "15:00" || startTime === "15:15"
            || startTime === "15:30" || startTime === "15:45"){
              sixthRow.push(startTime+ '-'+ endTime);
              AllBookingTimeData.push(startTime+ '-'+ endTime);
              idArray.push({ time: startTime+ '-'+ endTime, id: result_data[i].id });
          }
        }
      }
    
      return {
        result_CalendarData: res?.data ? res?.data : null,
        firstRow: firstRow,
        secondRow: secondRow,
        thirdRow: thirdRow,
        fourthRow: fourthRow,
        fifthRow: fifthRow,
        sixthRow: sixthRow,
        allBookingTimeData: AllBookingTimeData, 
        idArray: idArray,
        loading_CalendarData: loading,
        error_Calendar: error
      };
  }

  export const GetBookingInfoById = (id : string) => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: AppointmentDataById
      }>(
        {
          url:  `/v2/smart-infirmary/booking/doctor/` + id ,
          method: "GET"
        }
      );
    
      return {
        result_bookingData: res?.data ? res?.data : null,
        loading_bookingData: loading,
        error_bookingData: error
      };
  }

  export const UpdateCalendar = () =>{
    const [{ data: res, loading, error }, fire] = CallTelemed<{
      code: 0 | 1;
      message: string;
      // data: User;
      data : any;
    }>(
      {
        url:  `/v2/smart-infirmary/booking/update`,
        method: "POST",
      },
      {
        manual: true,
      }
    );
    return {
      result_update_calendar: res?.code === 1 ? res?.data : null,
      loading_update_calendar: loading,
      error_update_calendar: res?.code === 0 ? res?.message : null,
      fire_update_calendar: (dateArray: any, startDate: Date, endDate: Date, allBookingTimeData: any, calendar_id: any) => {
        let filterDateArray = dateArray
                 .filter((x : {x: any}) => !allBookingTimeData.includes(x))
                 .concat(allBookingTimeData.filter( (x: {x: any}) => !dateArray.includes(x)));
        
        let inputData = {
          calendar_id: calendar_id,
          period : filterDateArray,
          start_date: dateStrAPI(startDate) + " GMT +07:00",
          end_date: dateStrAPI(endDate)+ " GMT +07:00"
        };
        return new Promise((resolve, reject) => {
          fire({
            // data: inputData,
          })
            .then(({ data: { data, code, message } }) => {
              if (code === 0) {
                reject(message);
              } else {
                resolve(data);
              }
            })
            .catch((ex) => {
              reject(ex.toString());
            });
        });
      },
    };
  }

  export const UpdateCalendarBooking = () =>{
    const [{ data: res, loading, error }, fire] = CallTelemed<{
      code: 0 | 1;
      message: string;
      data : any;
    }>(
      {
        url:  `/v2/smart-infirmary/booking/update-many`,
        method: "POST",
      },
      {
        manual: true,
      }
    );
    return {
      result_update_calendarBooking: res?.code === 1 ? res?.data : null,
      loading_update_calendarBooking: loading,
      error_update_calendarBooking: res?.code === 0 ? res?.message : null,
      fire_update_calendarBooking: (dateArray: any, startDate: Date, endDate: Date, allBookingTimeData: any, idArray: any) => {
        let filterDateArray = dateArray
                 .filter((x : {x: any}) => !allBookingTimeData.includes(x))
                 .concat(allBookingTimeData.filter( (x: {x: any}) => !dateArray.includes(x)));

        let filterDateArrayUpdate = filterDateArray.filter((val: {val: any}) => !allBookingTimeData.includes(val));
         
        let calendarIdArray : any = [];
        if(filterDateArray.length > 0){
          for(let i = 0 ; i < filterDateArray.length; i ++) {
            for(let j = 0; j< idArray.length; j++ ){
              if(idArray[j].time === filterDateArray[i]){
                calendarIdArray.push(idArray[j].id);
              }
            }
          }
        }
        
        let updateData = {
          calendar_ids : calendarIdArray,
          period : filterDateArrayUpdate,
          start_date: dateStrAPI(startDate) + " GMT +07:00",
          end_date: dateStrAPI(endDate)+ " GMT +07:00"
        };
         
        return new Promise((resolve, reject) => {
          fire({
            data: updateData,
          })
            .then(({ data: { data, code, message } }) => {
              if (code === 0) {
                reject(message);
              } else {
                resolve(data);
              }
            })
            .catch((ex) => {
              reject(ex.toString());
            });
        });
      },
    };
  }

  export const PostponeBooking = () => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
      data: any;
      code: 0 | 1;
      message: string;
    }>(
      {
        url: `/v2/smart-infirmary/booking/postpone/doctor`,
        method: "POST",
      },
      { manual: true }
    );
    return {
      result_postponeBooking: res?.data,
      loading_postponeBooking: loading,
      error_postponeBooking: error,
      fire_postponeBooking: (
        id: any, startDate: Date, startTime: any, endTime: any
      ) => {
        let requestData = {};
        requestData = {
          booking_id: id,
          period : [startTime + "-" + endTime],
          start_date: dateStrAPI(startDate) + " GMT +07:00",
          end_date: dateStrAPI(startDate)+ " GMT +07:00"
        }
        console.log("request data:", requestData);
     
        return new Promise((resolve, reject) => {
            fire({
              data: requestData,
            })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

  export const AcceptBooking = (id : string) => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
      data: any;
      code: 0 | 1;
      message: string;
    }>(
      {
        url: `/v2/smart-infirmary/booking/${id}/accept`,
        method: "PATCH",
      },
      { manual: true }
    );
    return {
      result_acceptBooking: res?.data,
      loading_acceptBooking: loading,
      error_acceptBooking: error,
      fire_acceptBooking: () => {
        return new Promise((resolve, reject) => {
            fire({
              // data: requestData,
            })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

  export const DeclineBooking = (id : string) => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
      data: any;
      code: 0 | 1;
      message: string;
    }>(
      {
        url: `/v2/smart-infirmary/booking/${id}/decline`,
        method: "PATCH",
      },
      { manual: true }
    );
    return {
      result_declineBooking: res?.data,
      loading_declineBooking: loading,
      error_declineBooking: error,
      fire_declineBooking: () => {
        return new Promise((resolve, reject) => {
            fire({
              // data: requestData,
            })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

  export const GetRecentPatientData = () => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: PatientData
      }>(
        {
          url:  "/v2/dashboard/patient/recent",
          method: "GET",
        }
      );
    
      return {
        result_patientRecentData: res?.data ? res?.data : null,
        loading_patientRecentData: loading,
        error_patientRecentData: error
      };
  }

  const setTimezone = (param: any )=> {
    switch(param) {
      case '1': return '+0100';
      case '2': return '+0200';
      case '3': return '+0300';
      case '4': return '+0400';
      case '5': return '+0500';
      case '6': return '+0600';
      case '7': return '+0700';
      case '8': return '+0800';
      case '9': return '+0900';
      case '10': return '+1000';
      case '11': return '+1100';
      case '12': return '+1200';
      case '-1': return '-0100';
      case '-2': return '-0200';
      case '-3': return '-0300';
      case '-4': return '-0400';
      case '-5': return '-0500';
      case '-6': return '-0600';
      case '-7': return '-0700';
      case '-8': return '-0800';
      case '-9': return '-0900';
      case '-10': return '-1000';
      case '-11': return '-1100';
      case '-12': return '-1200';
      default:
        return '+0000';
    }
  }


  export const GetNewAppointment = () => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: AppointmentDataById[]
      }>(
        {
          url:  "/v2/dashboard/patient/appointment",
          method: "GET",
        }
      );
    
      return {
        result_newAppointment: res?.data ? res?.data : [],
        loading_newAppointment: loading,
        error_newAppointment: error
      };
  }

  // status  =avilable , accept, decline, end , all
  export const GetAppointmentByDate = (startDate: any) => {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const timezone = offset / (-60);

    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: AppointmentDataById[]
      }>(
        {
          url:  "/v2/dashboard/patient/appointment/status",
          method: "GET",
          params: {
            start_date: dateStrAPI(new Date(startDate)) + "T00:00:00",
            tz: setTimezone(timezone.toString()),
            status: "recent"
          }
        }
      );
    
      return {
        result_newAppointmentByDate: res?.data ? res?.data : [],
        loading_newAppointmentByDate: loading,
        error_newAppointmentByDate: error
      };
  }

  export const GetDoctorInfo = () => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: Doctor
      }>(
        {
          url:  "/v2/auth/doctor",
          method: "GET",
        }
      );
      
      if(res?.data){
        localStorage.setItem("doctor_id", res?.data?.id);
      }

      return {
        result_doctorInfo: res?.data ? res?.data : null,
        loading_doctorInfo: loading,
        error_doctorInfo: error
      };
  }