import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';

export const CardTopValue = observer(
    ({
      title,
      value,
      total,
      primary,
    }: {
      title: string;
      value: any;
      total: any;
      primary?: boolean;
    }) => {
      return (
        <Box
          css={{
            display: "flex",
            flex: 1,
            minHeight: "100%",
            borderRadius: 5,
            alignItems: "center",
            backgroundColor: primary ? "var(--primary)" : "#fff",
          }}
        >
          <Box
            px={2}
            py={2.5}
            style={{
              color: primary ? "#fff" : "#000",
              textAlign: primary ? "center" : "left",
            }}
          >
            <Box fontSize={14} fontWeight={600}>
              {title}
            </Box>
            <Box fontSize={27} fontWeight={primary ? 600 : 400}>
              {value}
            </Box>
            <Box
              fontSize={14}
              fontWeight={600}
              css={{ color: primary ? "#fff" : "var(--primary)" }}
            >
              {total ? total : "-"}
            </Box>
          </Box>
        </Box>
      );
    }
  );
  