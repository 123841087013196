import { Filter, UserFromAPI } from "../../../types/user";
import { useCallAPISpacetrax } from "../../useCallAPI";
import { processServerUser } from "./useReadUser";

export const useListSOSUserComplete = (filter?: Filter) => {
  const [{ data: res, loading, error } ] = useCallAPISpacetrax<{
    data: UserFromAPI[];
  }>({
    url: `/v2/sos/complete`,
    method: "GET",
    params: {
      page: filter?.page || 1,
      perPage: filter?.perPage || 10,
    },
  });

  let user = (res?.data || []).map((v) => {
    return processServerUser(v);
  });

  return {
    result_userListSOS_Complete_raw: res?.data || [],
    result_userListSOS_Complete: user,
    loading_userListSOS_Complete: loading,
    error_userListSOS_Complete: error,
  };
};
