import { Box, Button, Grid, IconButton } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { webStore } from '../../stores/webStore';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CachedIcon from "@material-ui/icons/Cached";
import { dateStr } from '../../utils';
import SaveAltIcon from "@material-ui/icons/SaveAlt";

export const SubBar = observer(
    ({
      title,
      back,
      reload,
      viewAll,
      importExcel,
      addNewFence,
      children,
    }: {
      title: string | React.ReactElement;
      children?: React.ReactElement;
      back?: boolean;
      reload?: boolean;
      viewAll?: string;
      importExcel?: string;
      addNewFence?: string;
    }) => {
      //
      useEffect(() => {
        webStore.setLatestTimeStamp(new Date());
        webStore.resetFilter();
      }, []);
      const history = useHistory();
      return (
        <Box className="flex justify-between items-center" pt={4} pb={0}>
          <Grid container alignItems="center">
            {back && (
              <IconButton
                onClick={() => {
                  const res = history.goBack();
                }}
              >
                <ArrowBackIcon css={{ color: "black" }} />
              </IconButton>
            )}
  
            <Box fontSize="h5.fontSize" fontWeight={500}>
              {title}
            </Box>
          </Grid>
          {reload !== false && (
            <>
              <Box
                display={{ sm: "none", xs: "none", md: "block" }}
                css={{ minWidth: "fit-content" }}
              >
                <Button
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    webStore.setLatestTimeStamp(new Date());
                  }}
                  color="inherit"
                  startIcon={<CachedIcon />}
                >
                  {dateStr(webStore.latestTimeStamp)}
                </Button>
              </Box>
              <Box display={{ xs: "block", md: "none" }}>
                <Button
                  style={{ width: "30px", minWidth: "auto" }}
                  onClick={() => {
                    webStore.setLatestTimeStamp(new Date());
                  }}
                >
                  <CachedIcon />
                </Button>
              </Box>
            </>
          )}
          {viewAll && (
            <Button
              color="primary"
              css={{ fontWeight: "bold", width: 150 }}
              href={viewAll}
            >
              View All
            </Button>
          )}
          {importExcel && (
            <Box display={{ sm: "none", xs: "none", md: "block" }} width={200}>
              <Button
                color="primary"
                css={{
                  fontWeight: "bold",
                  width: 200,
                  justifyContent: "flex-end",
                }}
                // onClick={importExcel}
                href={importExcel}
                startIcon={<SaveAltIcon />}
                size="small"
                variant="text"
              >
                IMPORT EXCEL
              </Button>
            </Box>
          )}
          {children}
        </Box>
      );
    }
  );