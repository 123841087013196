import { Box, LinearProgress, MenuItem, Select } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Filter, UsersQuarantine } from "../../types/user";
import { UserItem_AlertOximeter } from "./OximeterAlert";

export const CardUserList_Oximeter = observer(
    ({
      result_userList,
      loading_userList,
      total = 0,
      filter,
      mapRef
    }: {
      result_userList: UsersQuarantine[];
      loading_userList: boolean;
      total?: number;
      mapRef?: any;
      filter?: Filter;
    }) => {
      const [userLiser, setUserLiser] = useState<UsersQuarantine[]>([]);
      useEffect(() => {
        setUserLiser(
          result_userList
        );
      }, [result_userList]);

      return (
        <Box
          className="relative"
          css={{
            backgroundColor: "#fff",
            borderRadius: 5,
            cursor: "pointer",
            "@media (max-width: 992px)": { backgroundColor: "transparent" },
            height: "100%",
          }}
        >
          {loading_userList && (
            <LinearProgress
              style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
            />
          )}
          <Box py={2} px={{ xs: 0, md: 2 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box fontSize={14} fontWeight={600}>
              </Box>
              <Box display="flex" alignItems="center" fontSize={14}>
                <Box mr={1}>Sort by</Box>
                <Select
                  labelId="label"
                  id="select"
                  value="oldest_first"
                  disableUnderline
                  css={{ fontSize: 14, fontFamily: "inherit", color: "#4684E2" }}
                >
                  <MenuItem value="oldest_first">Oldest first</MenuItem>
                </Select>
              </Box>
            </Box>
            <Box>
              {(userLiser || []).map((user, i) => {
                if(typeof user.device != 'undefined'){
                  if(user.device.oxygen < 95 && user.device.oxygen !== 0){
                    return (
                      <div key={Math.random()}>
                        <UserItem_AlertOximeter {...user} borderTop={i !== 0} />
                      </div>
                    );
                  }
                }
              })}
            </Box>
          </Box>
        </Box>
      );
    }
  );