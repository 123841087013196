import React from "react";
import {
  Avatar,
  Box,
  Grid,
  FormControl,
  FormLabel ,
  FormControlLabel ,
  RadioGroup ,
  Radio,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { dateStringFullMothName,dateStrFormatTime } from "../../../utils";
import { GetBookingHistory } from "../../../services/api/telemedicine/BookingService";  
import {
    GetUserProfile
  } from "../../../services/api/user/useReadUser";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion } from "./HealthReport";

export const AppointmentHistory  = observer(() => {
  
    const { index }: any = useParams();
    const [expanded, setExpanded] = React.useState<string | false>("panel0");
    const [value, setValue] = React.useState('yes');
    const [labReportVale, setLabReportValue ] = React.useState('yes');
    const [followUpValue, setFollowupValue ] = React.useState('yes');
    const { result_bookingHistory, loading_bookingHistory } = GetBookingHistory(index);
    const { result_getUserProfile,fire_userRead, loading_getUserProfile, ...rest } = GetUserProfile(index);

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleChangeLabReport = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLabReportValue((event.target as HTMLInputElement).value);
    };

    const handleChangeFollowup = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFollowupValue((event.target as HTMLInputElement).value);
    };

    const handleChange = (panel: string) => (
        event: React.ChangeEvent<{}>,
        newExpanded: boolean
      ) => {
        setExpanded(newExpanded ? panel : false);
      };

  return (
    <>
        <br/>
        <Box fontSize="h5.fontSize" fontWeight={500}>
          History
        </Box>
        <br/>
        <Box>
            {result_bookingHistory?.map((bookingHistory, i) => (
                <Accordion
                    key={i}
                    square
                    expanded={expanded === `panel1`}
                    onChange={handleChange(`panel1`)}
                    style={{ marginBottom: '20px'}}
                    >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box
                        css={{ borderRadius: 5}}
                        >
                            <Box display={{ xs: "block", md: "flex" }}>
                                <Box
                                p={4}
                                pl={10}
                                width={{ xs: "100%", md: "20%" }}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                >
                                    <Avatar style={{ width: 70, height: 70,  textAlign:'center'}}>
                                        <img src={bookingHistory?.doctor?.profile_image} alt="" />
                                    </Avatar>
                                </Box>
                                <Box py={4} px={6} width={{ xs: "100%", md: "80%" }}>
                                    <p style={{fontSize:'20px', fontWeight:'bold'}}>
                                        {bookingHistory?.doctor?.name + " " + bookingHistory?.doctor?.surname}
                                    </p>
                                    <p style={{ fontSize: '14px', color: 'gray'}}>
                                        {bookingHistory?.doctor?.specialized + " ," + bookingHistory?.doctor?.department?.name_th}
                                    </p>
                                    <p style={{ fontSize: '14px', color: 'gray'}}>
                                       { dateStringFullMothName(bookingHistory?.start_time) + ", " + dateStrFormatTime(bookingHistory?.start_time) }
                                    </p>
                                </Box>
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box px={{ xs: 0, md: 3 }} width="100%">
                            <Grid container spacing={3}>
                                <Grid item xs={6} >
                                    <p>
                                    <span style={{fontWeight: 'bold'}}>Symptom Case </span> 
                                    </p>
                                <br/>
                                <Box
                                    css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                                    p={2}
                                    >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mb={1}
                                        mt={1}
                                    >
                                        
                                    </Box>

                                    

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <FormControl
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        size="small"
                                        margin="dense"
                                        >
                                        <TextareaAutosize  name="remark-label" placeholder="Type ....."
                                        rowsMin={5} 
                                        value = {bookingHistory?.symptom ? bookingHistory?.symptom : ""}
                                        />
                                        </FormControl>
                                        
                                    </Box>
                                    <br/>
                                    </Box>
                                    <br/>
                                </Grid>
                                <Grid item xs={6}>
                                <p>
                                    <span style={{fontWeight: 'bold'}}>Diagnoses </span> 
                                </p>
                                <br/>
                                <Box
                                    css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                                    p={2}
                                    >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mb={1}
                                        mt={1}
                                    >
                                        
                                    </Box>

                                    

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <FormControl
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        size="small"
                                        margin="dense"
                                        >
                                        <TextareaAutosize  name="remark-label" placeholder="Type ....."
                                        rowsMin={5} 
                                        value = {bookingHistory?.diagnosis ? bookingHistory?.diagnosis : ""}
                                        />
                                        </FormControl>
                                        
                                    </Box>
                                    <br/>
                                    </Box>
                                    <br/>
                                </Grid>
                            </Grid>
                            <Grid container>
                            <FormControl component="fieldset" style={{display :'felx'}}>
                                <FormLabel component="legend" style={{fontWeight: 'bold'}}>Medicine</FormLabel>
                                <RadioGroup row aria-label="medicine" name="medicine" value={bookingHistory?.is_medicines ? "yes" : "no"} onChange={handleChangeValue}>
                                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                            </Grid>
                            <br/>
                            <Grid container>
                            <FormControl component="fieldset" style={{display :'flex'}}>
                                <FormLabel component="legend" style={{fontWeight: 'bold'}}>Lab Report</FormLabel>
                                <RadioGroup row aria-label="medicine" name="medicine" value={bookingHistory?.is_lab_report ? "yes" : "no"} onChange={handleChangeLabReport}>
                                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                            </Grid>
                            <br/>
                            <Grid container>
                            <FormControl component="fieldset" style={{display :'flex'}}>
                                <FormLabel component="legend" style={{fontWeight: 'bold'}}>Follow Up</FormLabel>
                                <RadioGroup row aria-label="medicine" name="medicine" value={bookingHistory?.is_follow_up ? "yes" : "no"} onChange={handleChangeFollowup}>
                                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                            </Grid>
                        </Box>
                        
                    </AccordionDetails>
                </Accordion>
            ))}
            <br/>
        </Box>      
    </>
  );
});