import React, {  useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  LinearProgress
} from "@material-ui/core";
import { webStore } from "../../../stores/webStore";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { dateStringFullMothName,dateStrFormatTime } from "../../../utils";
import { SubBar } from "../../Dashboard/SubBar";
import PersonIcon from '@material-ui/icons/Person';
import LuxonUtils from "@date-io/luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { GetDoctorInfo,GetAppointmentByDate, GetAccpetedAppointment } from "../../../services/api/telemedicine/BookingService";  
export const Appointment  = observer(() => {
    const [startDate, setStartDate] = useState<Date | null>(
        webStore.startDate || new Date()
      );
  const history = useHistory();
  const { result_acceptedAppointment, loading_acceptedAppointment } = GetAccpetedAppointment();
  const { result_newAppointmentByDate, loading_newAppointmentByDate } = GetAppointmentByDate(startDate);
  const {result_doctorInfo, loading_doctorInfo } = GetDoctorInfo();
 
  useEffect(() => {
  }, []);

  return (
    <>
          <SubBar title={"Appointments"} back={true} reload={false}/>
          <Grid container>
            <Grid item md={3}  xs={12} style={{padding: '20px'}}>
            
                <Grid container>
                   
                    <Grid container>
                        <Grid item xs={12} css={{ padding: "0 5px" , backgroundColor: '#fff'}}>
                        <br/>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <KeyboardDatePicker
                                    style={{
                                    margin: 0,
                                    width: "100%",
                                    backgroundColor: "#fff",
                                    }}
                                    
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="from"
                                    inputVariant="outlined"
                                    label="Choose Date"
                                    autoOk={true}
                                    value={startDate}
                                    onChange={(date) => {
                                    setStartDate(date ? new Date(date.toString()) : null);
                                    // onSearch();
                                    }}
                                    KeyboardButtonProps={{
                                    "aria-label": "change date",
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        
                    </Grid>
                    <br/>

                    <Grid item xs={12} css={{ padding: "0 5px",  backgroundColor: '#fff'}}>
                        <h6 style={{padding: '10px', fontWeight: 'bold'}}>
                            New Appointment
                        </h6>
                        
                        <Box
                            style={{position: "relative", overflow: "auto", height: '80vh'}}
                            css={{ borderRadius: 5, backgroundColor: "#fff" }}
                        >
                            {loading_newAppointmentByDate && (
                                <LinearProgress
                                    style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
                                />
                            )}
                            {
                                result_newAppointmentByDate.length === 0 ?
                                <>
                                    <div
                                        style={{
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)",
                                        position: "absolute",
                                        opacity: "0.3",
                                        }}
                                    >
                                        There is no new appointment.
                                    </div>
                                </>
                                :
                                <></>
                            }
                            {(result_newAppointmentByDate || []).map((contactInfo : any) => {
                                
                                return ( 
                                <Box
                                    css={{ backgroundColor: "#fff", 
                                    width: "100%", 
                                    borderRadius: 20, 
                                    border: 'solid 2px #3f51b5',
                                    "&:hover": {
                                        backgroundColor: "rgba(3,169,244,0.08)",
                                        marginTop: '2%'
                                        }
                                    }}
                                    p={2}
                                    mb={1}
                                    mt={1}
                                    display="flex"
                                    onClick = {() => {
                                        history.push("/telemedicine/acceptNewAppointment/" + contactInfo?.id + "/" + contactInfo?.patient?.id );
                                     }}
                                    key={contactInfo?.id}
                                >
                                    <Box pr={2} style={{paddingTop: '7px'}}
                                    >
                                    <Avatar style={{ width: 55, height: 55 }}>
                                        <PersonIcon style={{fontSize: '40px'}}/>
                                    </Avatar>
                                    </Box>

                                    <Grid container>
                                    <Grid item xs={12}>
                                        <Box mb={2} position="relative">
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            css={{ color: "#35353F", marginTop:'4px' }}
                                        >
                                            <Box fontWeight={600} fontSize={15} mr={1}>
                                            {contactInfo.patient?.name + " " + contactInfo.patient?.surname}
                                            </Box>
                                            
                                        </Box>
                                        <Box>
                                            <p style={{fontSize: '12px',color: 'grey'}}> {contactInfo.appointment_name} </p>
                                        </Box>
                                        <Box>
                                            <p style={{fontSize: '12px',color: 'grey'}}> {dateStringFullMothName(contactInfo?.start_time)} </p>
                                        </Box>
                                        </Box>
                                    </Grid>
                                    </Grid>

                                </Box>
                                );
                            })}
                        </Box>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item md={6}  xs={12} style={{height: '100vh', padding: '20px'}} >
            <Grid container>
            <Grid container>
                </Grid>
                    <Grid item xs={12} css={{ padding: "0 5px" , paddingTop: '30px'}}> 
                        <Box
                            style={{position: "relative", overflow: "auto", height: '90vh' }}
                            css={{ borderRadius: 5 }}
                            >
                            {loading_acceptedAppointment && (
                                <LinearProgress
                                    style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
                                />
                            )}
                            {
                                result_acceptedAppointment?.length === 0 ?
                                <>
                                    <div
                                        style={{
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)",
                                        position: "absolute",
                                        opacity: "0.5",
                                        }}
                                    >
                                        There is no appointment.
                                    </div>
                                </>
                                :
                                <>
                                
                                </>
                            }
                            {(result_acceptedAppointment || []).map((contactInfo : any, index) => {
                            
                                return ( 
                                <Box
                                    css={{ backgroundColor: "#fff", 
                                    width: "100%", 
                                    borderRadius: 20, 
                                    border: 'solid 2px #3f51b5',
                                    "&:hover": {
                                        backgroundColor: "rgba(3,169,244,0.08)",
                                        marginTop: '2%'
                                        }
                                    }}
                                    p={2}
                                    mb={1}
                                    mt={1}
                                    display="flex"
                                    onClick={() => {
                                        // setName(contactInfo.name);
                                    }}
                                    key={contactInfo?.id}
                                >
                                    <Box pr={4} pt={5}
                                    >
                                    <Avatar style={{ width: 60, height: 60, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                        {/* <PersonIcon style={{fontSize: '60px'}}/> */}
                                        {/* <img src={contactInfo?.patient?.avartar} alt="" style={{height:'100%'}}/> */}
                                        {
                                            contactInfo?.patient?.avartar ? 
                                            <img src={contactInfo?.patient?.avartar} alt="" style={{height:'100%'}}/>
                                            :
                                            <PersonIcon style={{fontSize: '30px'}}/>
                                        }
                                    </Avatar>
                                    </Box>

                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Box mb={2} position="relative">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                css={{ color: "#35353F", marginTop:'4px' }}
                                            >
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Name: </span>  {contactInfo?.patient?.name + " " + contactInfo?.patient?.surname}
                                                </Box>
                                            </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Gender: </span>  
                                                { contactInfo?.patient?.gender === 'm' ? "Male"
                                                  : contactInfo?.patient?.gender === 'f' ? "Female"
                                                  : ""
                                                }
                                                </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Booking Type: </span>  {contactInfo?.appointment_type}
                                                </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Date: </span>  {dateStringFullMothName(contactInfo?.start_time)}
                                                </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}>Start Time: </span>  {dateStrFormatTime(contactInfo?.start_time)}
                                                </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}>End Time: </span>  {dateStrFormatTime(contactInfo?.end_time)}
                                                </Box>
                                                {/* <br/> */}
                                                {/* <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Session: </span>  30 นาที
                                                </Box> */}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box mb={2} position="relative">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                css={{ color: "#35353F", marginTop:'4px' }}
                                            >
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> Symptom case: </span>
                                                </Box>
                                            </Box>
                                                <br/>
                                                <Box fontSize={15} mr={1}>
                                                    {
                                                        contactInfo?.symptom
                                                    }
                                                </Box>
                                                <br/>
                                                <br/>
                                                <Button  style={{textAlign: 'center'}}
                                                 variant="contained" color="primary"
                                                 onClick = {() => {
                                                    history.push("/telemedicine/viewCase/" + contactInfo?.patient?.id + "/" + contactInfo?.id );
                                                 }}
                                                 > View Case
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                                );
                            })}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={3}  xs={12}>
                <Grid container> 
                    <Grid item xs={12} style={{backgroundColor: '#fff', padding: '20px'}}>
                    {loading_doctorInfo && (
                        <LinearProgress
                            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
                        />
                    )}
                        <Box 
                            style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '20px'
                            }}
                        >
                             <Avatar style={{ width: 100, height: 100,  textAlign:'center'}}>
                                <img src={result_doctorInfo?.profile_image} alt="" />
                            </Avatar>
                        </Box>
                       <Box 
                            style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '10px'
                            }}
                        >
                             <p style={{fontWeight: 'bold'}}>Dr. {result_doctorInfo?.name?  result_doctorInfo?.name + " " + result_doctorInfo?.surname : ""}</p>
                        </Box>
                        <Box 
                            style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                             <p style={{ fontSize: '12px', color: 'gray'}}>{result_doctorInfo?.department?.name_en}</p>
                        </Box>
                        <br/>
                        <hr/>
                        <br/>
                       <Grid container>
                            <Grid item xs={8}>
                                <p style={{ fontSize: '12px', color: 'gray', paddingLeft: '10px'}}>Overall Rating:</p>
                            </Grid>
                            <Grid item xs={4}>
                                <p style={{ fontSize: '14px', fontWeight: 'bold'}}>{result_doctorInfo ? "4.8" : ""}</p>
                            </Grid>
                       </Grid>
                       <br/>
                       <Grid container>
                            <Grid item xs={8}>
                                <p style={{ fontSize: '12px', color: 'gray' , paddingLeft: '10px'}}>Available Slot:</p>
                            </Grid>
                            <Grid item xs={4}>
                                <p style={{ fontSize: '14px', fontWeight: 'bold'}}>{result_doctorInfo ? "38/140" : ""}</p>
                            </Grid>
                       </Grid>
                       <br/>
                       <Grid container>
                            <Grid item xs={8}>
                                <p style={{ fontSize: '12px', color: 'gray',  paddingLeft: '10px'}}>Total Patients:</p>
                            </Grid>
                            <Grid item xs={4}>
                                <p style={{ fontSize: '14px', fontWeight: 'bold'}}>{result_doctorInfo ? "246" : ""}</p>
                            </Grid>
                       </Grid>
                       <br/>
                       <br/>
                    </Grid>
                </Grid>
            </Grid>
          </Grid>
    </>
  );
});