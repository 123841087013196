import { useHistory } from "react-router-dom";
import { useCallAPI, useCallAPISpacetrax } from "../../useCallAPI";

export const useVerifyOTP_Mock = () => {
  const history = useHistory();
  const [{ data: res, loading, error }, fire] = useCallAPI<{
    data: {
      access_token: string;
    };
  }>(
    {
      url: `/api/v1/login`,
      method: "POST",
    },
    { manual: true }
  );
  return {
    result_token: res?.data?.access_token,
    loading_verify_otp: loading,
    error_verify_otp: error,
    fire_verify_otp: (
      ref_code: string,
      otp: string,
      username: string,
      password: string
    ) => {
      return fire({
        data: {
          username,
          password,
        },
      }).then(({ data }) => {
        localStorage.setItem("token", data.data.access_token);
        if(localStorage.getItem("userRole") === 'finance') {
          history.push("/telemedicine/finance");
        }else if (localStorage.getItem("userRole") === 'pharmacy'){
          history.push("/user/listSI")
        }else {
          history.push("/");
        }
      });
    },
  };
};

export const useVerifyOTP = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: {
      token: string;
    };
  }>(
    {
      url: `/v2/auth/verify-login`,
      method: "POST",
    },
    { manual: true }
  );
  return {
    result_token: res?.data?.token,
    loading_verify_otp: loading,
    error_verify_otp: error,
    fire_verify_otp: (
      secret_code : string,
      ref_code: string,
      otp: string,
      username: string,
      password: string
    ) => {
    var formData = new FormData();
      formData.append('secret_code', secret_code.toString());   //append the values with key, value pair
      formData.append('ref_code', ref_code.toString());
      formData.append('otp', otp.toString());
    return fire({
      data: formData
    });
    },
  };
};
