import React from "react";
import { observer } from "mobx-react-lite";
import { Box,Grid } from "@material-ui/core";
import { Health } from "../../types/user";
import { Oximeter } from "../../types/oximeter";
import FavoriteIcon from "../../components/assets/favorite.svg";
import OxygenIcon from "../../components/assets/Oxygen.svg";
import TemperatureIcon from "../../components/assets/temperature.svg";
import BloodIcon from "../../components/assets/blood.svg";

const DeviceValue = observer(
    ({
      icon,
      value,
      position,
    }: {
      icon: any;
      value: string;
      position?: string;
    }) => {
      return (
        <Box
          display={{ md: "flex" }}
          alignItems="center"
          css={{ color: "#595960", height: "100%" }}
          justifyContent={"flex-start"}
          textAlign="center"
        >
          <Box
            justifyContent="center"
            display="flex"
            mb={{ xs: 0.5, md: 0 }}
            height={{ xs: 14, md: "100%" }}
          >
            <img
              src={icon}
              css={{
                "@media (max-width: 992px)": { maxHeight: 14, maxWidth: 14 },
              }}
            />
          </Box>
          <Box ml={0.5} fontSize={13}>
            {value}
          </Box>
        </Box>
      );
    }
  );
  
  export const DeviceValueTab = observer(
    ({ device, oximeter }: { device?: Health, oximeter? : Oximeter }) => {
     
      return (
        <Grid container>
           <Grid item xs={2} style={{ maxWidth: "4.5rem" }}>
            {device?.blood_oxygen ? (
              <DeviceValue
                icon={OxygenIcon}
                value={device?.blood_oxygen.toString()}
              />
            ) : (
              <DeviceValue icon={OxygenIcon} value={"?"} />
            )}
          </Grid>
          <Grid item xs={2} style={{ maxWidth: "4.5rem" }}>
            {device?.body_temp ? (
              <DeviceValue
                icon={TemperatureIcon}
                value={device?.body_temp.toString()}
              />
            ) : (
              <DeviceValue icon={TemperatureIcon} value={"?"} />
            )}
          </Grid>
          <Grid item xs={2} style={{ maxWidth: "4.5rem" }}>
            {device?.heart_rate ? (
              <DeviceValue
                icon={FavoriteIcon}
                value={device?.heart_rate.toString()}
              />
            ) : (
              <DeviceValue icon={FavoriteIcon} value={"?"} />
            )}
          </Grid>
          <Grid item xs={3} style={{ maxWidth: "6rem" }}>
            <DeviceValue
              icon={BloodIcon}
              value={`${device?.blood_systolic || "?"} / ${
                device?.blood_diastolic || "?"
              }`}
            />
          </Grid>
        </Grid>
      );
    }
  );