import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Modal,Box, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const SuccessPostponeModal = observer(
    ({
      isSuccessPostpone, 
      setIsSuccessPostpone,
      isPostpone, 
      setIsPostpone
    }: {
      isSuccessPostpone: boolean;
      setIsSuccessPostpone: any;
      isPostpone: boolean;
      setIsPostpone: any;
    }) => {
  
    const history = useHistory();
      
      return (
        <Modal
          open={isSuccessPostpone}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            css={{ backgroundColor: "#fff", width: 500, borderRadius: 30, border: 'solid 2px #3f51b5' }}
            p={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
              mt={1}
            >
              <Box css={{ fontWeight: 600}}>
                
              </Box>
              <Box
                onClick={() => {
                  setIsSuccessPostpone(false);
                  setIsPostpone(false);
                }}
              >
                <CloseIcon css={{ cursor: "pointer" }} />
              </Box>
            </Box>
            <br/>
                
            <Box style={{textAlign : 'center', alignItems: 'center', color: 'green'}}>
              <CheckCircleIcon style={{fontSize: '60px'}} />
            </Box>
            <br/>
            
            <p style={{textAlign : 'center', alignItems: 'center', fontSize: '18px', fontWeight: 'bold'}} > POSTPONE SUCCESSFULLY </p>    
            <br/>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt ={2}
            >
              <Button
                onClick={() =>{
                  setIsSuccessPostpone(false);
                  setIsPostpone(false);
                  history.push('/telemedicine/appiontment');
                }}
                color="primary"
                size="medium"
                variant="contained"
              >
                Close
              </Button>
            </Box>
            <br/>
            
          </Box>
        </Modal>
      );
    }
);
