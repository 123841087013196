import React, { useState } from "react";

import {
  Box,
  Container,
  Grid,
  Typography,
  Tabs,
  Tab
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import AppBar from "@material-ui/core/AppBar";
import '../Messenger.css';
import { Finance_recent } from "./FinanceRecent";
import { Finance_Wait } from "./FinanceWaiting";
import { Finance_Approved } from "./FinanceApproved";
import { Finance_Completed } from "./FinanceComplete";
  
export interface customeLabels {
    data: any;
    labels: any;
    color: any;
    showValue?: boolean;
    itemsCountPerPage?: number;
}

    interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box>
            <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

export const Finance = observer(() => {
  const [value, setValue] = React.useState(0);
  const [file, setFile] = useState(null);

  const handleChangeDragDrop = (file :any) => {
    setFile(file);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  
  return(
    <Container maxWidth="xl" className="py-4">
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className="flex justify-between items-center flex-wrap"
                    pt={4}
                    mb={3}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        css={{
                            "@media (max-width: 992px)": {
                            width: "100%",
                            justifyContent: "space-between",
                            },
                        }}
                        mb={{ xs: 3, md: 0 }}
                    >
                        <Box fontSize="h5.fontSize" fontWeight={500} mr={3}>
                            Finance
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" style={{backgroundColor: "transparent"}}>
                    <Tabs value={value} onChange={handleChange} style={{color: "#000"}}>
                        <Tab label="Recent" />
                        <Tab label="Waiting for Approve" />
                        <Tab label="Approved" />
                        <Tab label="Completed" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Grid item xs={12} style={{border: "1px solid"}}>
                        <Box className="py-4">
                            <Finance_recent/>
                        </Box>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid item xs={12} style={{border: "1px solid"}}>
                        <Finance_Wait/>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Grid item xs={12} style={{border: "1px solid"}}>
                        <Finance_Approved/>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Grid item xs={12} style={{border: "1px solid"}}>
                        <Finance_Completed/>
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    </Container>
  );
});
export const MobileFinance = Finance;