import { UserByAgeReport } from "../../../types/overviewReport";
import { useCallAPISpacetrax } from "../../useCallAPI";

export const useReadUserByAge = (endDate?: Date, startDate?: Date) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: {
      age: UserByAgeReport;
    };
  }>({
    url: `/v2/dashboard/personals`,
    method: "GET",
    data: {
      startDate,
      endDate,
    },
  });
  return {
    result_user_by_age: res?.data?.age,
    loading_user_by_age: loading,
    error_user_by_age: error,
  };
};
