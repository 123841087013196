import { makeAutoObservable } from "mobx";
import { ReadUserFilter } from "../services/api/user/useReadUser";
import { Profile, Project, Projects } from "../types/profile";

class WebStore {
  device: string = "unknown";
  profile: Profile | null = null;
  selectedProject: Project | null = null;
  projects: Projects | null = null;
  latestTimeStamp: Date = new Date();

  lang: string = "en";
  setLang(lang: string) {
    this.lang = lang;
  }
  //Filter
  startDate: Date | null = null;
  setStartDate(ts: Date | null) {
    this.startDate = ts;
  }
  startDateTracer : Date | null = null;
  setStartDateTracer(ts: Date | null) {
    this.startDateTracer = ts;
  }

  endDate: Date | null = null;
  setEndDate(ts: Date | null) {
    this.endDate = ts;
  }

  endDateTracer: Date | null = null;
  setEndDateTracer(ts: Date | null) {
    this.endDateTracer = ts;
  }

  filter_page: number | 1 = 1;
  setFilterPage(ts:number | 1 ){
    this.filter_page = ts;
  }

  filter_userListPage: number | 1 = 1;
  setFilterUserListPage(ts: number | 1) {
    this.filter_userListPage = ts;
  }

  filter_userListPageSI: number | 1 = 1;
  setFilterUserListPageSI(ts: number | 1) {
    this.filter_userListPageSI = ts;
  }
  
  filter_waitingListPage : number | 1 = 1;
  setFilterWaitingListPage(ts: number | 1){
    this.filter_waitingListPage = ts;
  }

  filter_waitingListPageSI : number | 1 = 1;
  setFilterWaitingListPageSI(ts: number | 1){
    this.filter_waitingListPageSI = ts;
  }

  totalUser : number | 0 = 0;
  setTotalUser(ts: number | 0){
    this.totalUser = ts;
  }

  totalUserDischarge : number | 0  = 0; 
  setTotalUserDischarge(ts: number | 0){
    this.totalUserDischarge = ts;
  }

  list_valueTab : number | 0 = 0 ;
  setListValueTab ( ts: number | 0){
    this.list_valueTab = ts;
  }

  filter_hour: number | null = null;
  set_filter_hour(v: any) {
    this.filter_hour = v;
  }
  filter_temp: string | null = null;
  set_filter_temp(v: any) {
    this.filter_temp = v;
  }
  filter_wear: string | null = null;
  set_filter_wear(v: any) {
    this.filter_wear = v;
  }
  filter_battery: string | null = null;
  set_filter_battery(v: any) {
    this.filter_battery = v;
  }
  filter_yacht_name: string | null = null;
  set_filter_yacht_name(v: any) {
    this.filter_yacht_name = v;
  }
  filter_Keyword: string | null = null;
  set_filter_Keyword(v: any) {
    this.filter_Keyword = v;
  }
  filter_geo_fence: string | null = null;
  set_filter_geo_fence(v: any) {
    this.filter_geo_fence = v;
  }
  
  filter_temperature : string | null = null;
  set_filter_temperature(v:any){
    this.filter_temperature = v;
  }

  filter_period: string | null = null;
  set_filter_period(v: any) {
    this.filter_period = v;
  }

  filter_symptomCase: string | null = null;
  set_filter_symptomCase(v: any) {
    this.filter_symptomCase = v;
  }

  filter_symptom: string | null = null;
  set_filter_symptom(v: any) {
    this.filter_symptom = v;
  }

  filter_spo2: string | null = null;
  set_filter_spo2(v: any) {
    this.filter_spo2 = v;
  }

  filter_assessmentCount : string | null = null;
  set_filter_assessmentCount(v:any){
    this.filter_assessmentCount = v;
  }

  filter_result : string | null = null;
  set_filter_result(v:any){
    this.filter_result = v
  }

  filter_reload : boolean = false;
  set_filter_reload(v:any){
    this.filter_reload = v
  }

  resetFilter() {
    this.setStartDate(null); //
    this.setEndDate(null);
    this.set_filter_hour(null);
    this.set_filter_temp(null);
    this.set_filter_wear(null);
    this.set_filter_battery(null);
    this.set_filter_yacht_name(null);
    this.set_filter_Keyword(null);
    this.set_filter_geo_fence(null);
  }

  resetDailyReportFilter() {
    this.setStartDate(null);
    this.setEndDate(null);
    this.set_filter_temperature(null);
    this.set_filter_period(null);
    this.set_filter_symptom(null);
    this.set_filter_symptomCase(null);
    this.set_filter_spo2(null);
  }

  resetMentalReportFilter(){
    this.setStartDate(null);
    this.setEndDate(null);
    this.set_filter_assessmentCount(null);
    this.set_filter_result(null);
  }

  get readUserFilter() {
    return {
      startDate: this.startDate,
      endDate: this.endDate || this.latestTimeStamp,
      filterHour: this.filter_hour,
    } as ReadUserFilter;
  }
  constructor() {
    makeAutoObservable(this);
  }
  setDevice(device: "unknown" | "mobile" | "desktop") {
    this.device = device;
  }
  setLatestTimeStamp(ts: Date) {
    this.latestTimeStamp = ts;
  }

  setProfile(profile: Profile | null , projects : Projects | null) {
    this.profile = profile;
    this.projects = projects;
    if(this.projects){
      if (this.projects.length> 0) {
        if(this.profile){
          for(let i=0; i < this.projects.length; i++){
            this.profile.project.push(this.projects[i].project);
          }
        }
        this.profile?.project.shift(); // remove first item
      }
    }
    
    let selectedProject = null;
    if (this.profile) {
      if (localStorage.getItem("projectId")) {
        selectedProject = this.profile.project.filter(
          ({ id }) => id === localStorage.getItem("projectId")
        )[0];
        localStorage.setItem("userRole", this.profile.role);
      }
      if (!selectedProject && this.profile.project.length === 1) {
        selectedProject = this.profile.project[0];
      }
    }

    this.setSelectedProject(selectedProject);
  }
  
  setSelectedProject(project: Project | null) {
    if (project) {
      localStorage.setItem("projectKey", project.project_key);
      localStorage.setItem("projectId", project.id);
      localStorage.setItem("projectType", project.type);
    } else {
      // localStorage.removeItem("projectKey");
    }
    this.selectedProject = project;
  }
}
//
export const webStore = new WebStore();
