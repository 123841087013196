import { Profile,Projects } from "../../../types/profile";
import { CallTelemed } from "../../useCallAPI";

export const useMe = () => {
  const [{ data: res, loading, error }, fire] = CallTelemed<{
    data: Profile;
  }>({
    url: `/v2/auth/user`,
    method: "GET",
  });
  
  if(res?.data) {
    localStorage.setItem("projectType", res?.data?.project[0]?.type);
    if(!localStorage.getItem("userId")){
      localStorage.setItem("userId", res?.data?.id);
    }
  }
  if(res?.data?.project){
    if(!localStorage.getItem("projectKey")){
      localStorage.setItem("projectKey", res?.data?.project[0].project_key);
    }
  }
  let user = res?.data;
 
  return {
    result_me: localStorage.getItem("token") ? user : null,
    loading_me: loading,
    error_me: error,
  };
};

export const GetProject = () => {
  const [{ data: res, loading, error }, fire] = CallTelemed<{
    data: Projects;
  }>({
    url: `/v2/dashboard/project`,
    method: "GET",
  });

  let user = res?.data;
  return {
    result_project: res?.data ? user : null,
    loading_project: loading,
    error_project: error,
  };
};
