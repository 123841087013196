import React from "react";
import {
  Box,
  Grid,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import {
  withStyles
} from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { dateStrAPI,timeStr } from "../../../utils";
import MuiAccordion from "@material-ui/core/Accordion";
import {
    GetUserProfile
  } from "../../../services/api/user/useReadUser";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export  const Accordion = withStyles({
    root: {
      borderBottom: "1px solid rgba(0, 0, 0, .1)",
      boxShadow: "none",
      "&:last-child": {
        borderBottom: 0,
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
})(MuiAccordion);

export const HealthReport  = observer(() => {
  
    const { index, appointmentId }: any = useParams();
    const [expanded, setExpanded] = React.useState<string | false>("panel0");
    const { result_getUserProfile,fire_userRead, loading_getUserProfile, ...rest } = GetUserProfile(index);

    const handleChangeHealthData = (panel: string) => (
        event: React.ChangeEvent<{}>,
        newExpanded: boolean
      ) => {
        setExpanded(newExpanded ? panel : false);
      };
  const DeviceField = observer((props: any) => {
    return (
      <Box
        borderRight={props.border ? props.border : 0}
        css={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
      >
        <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
          {props.label}
        </Box>
        <Box fontSize={18}>{props.children}</Box>
      </Box>
    );
  });

  return (
    <>
        <Box className="flex justify-between items-center" mt={8} pb={2}>
            <Grid container>
                <Grid item md={4} xs={12}>
                <Box fontSize="h5.fontSize" fontWeight={500}>
                    Health Report 
                </Box>
                </Grid>
            </Grid>
        </Box>

        <Box>
            <Accordion
            square
            expanded={expanded === `panel0`}
            onChange={handleChangeHealthData(`panel0`)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display={{ xs: "block", md: "flex" }}
                alignItems="center"
                px={{ xs: 0, md: 3 }}
                py={2}
              >
                <Box
                  fontSize={12}
                  css={{ color: "#4B4C56" }}
                  mt={{ xs: 2, md: 0 }}
                >
                  Latest update :
                  {
                    result_getUserProfile?.health?.updated_at ?
                    dateStrAPI(result_getUserProfile?.health?.updated_at) + ", " + timeStr(result_getUserProfile?.health?.updated_at)
                    :
                    ""
                  }
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box px={{ xs: 0, md: 3 }} width="100%">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Box
                      display="flex"
                      width="100%"
                      paddingRight={{ md: 5 }}
                      mb={{ xs: 4, md: 0 }}
                    >
                      <Grid item xs={4}>
                        <DeviceField label="Body temp">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.body_temp ? result_getUserProfile?.health?.body_temp : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField label="Heart rate">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.heart_rate ? result_getUserProfile?.health?.heart_rate : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Blood pressure"
                          border={{ xs: 0, md: 1 }}
                        >
                          <Box fontWeight={600}>
                            { result_getUserProfile?.health?.blood_systolic && result_getUserProfile?.health?.blood_diastolic ?
                            `${result_getUserProfile?.health?.blood_systolic}/${result_getUserProfile?.health?.blood_diastolic}`
                            :
                            "N/A"
                            }</Box>
                        </DeviceField>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" width="100%" mb={{ xs: 4, md: 0 }}>
                      <Grid item xs={4}>
                        <DeviceField label="Blood Sugar">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.blood_sugar ? result_getUserProfile?.health?.blood_sugar : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Blood Oxygen"
                        >
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.blood_oxygen ? result_getUserProfile?.health?.blood_oxygen : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Respiratoin(per min)"
                        >
                          <Box fontWeight={600}>
                              {result_getUserProfile?.health?.breath ? result_getUserProfile?.health?.breath : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        
      </Box>   
    </>
  );
});