import { Avatar, Box, Grid } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Health, SmartInfirmaryUserList, Ward } from "../../types/user";
import { dateStrAPI, timeStr } from "../../utils";
import { DeviceValueTab } from "./DeviceValueTab";

export const UserItem = observer(
    ({
      user,
      ward,
      health,
      borderTop,
      index,
      mapRefLat,
  
    }: {
      user: SmartInfirmaryUserList;
      ward?: Ward;
      health?: Health;
      index: number;
      borderTop?: boolean;
      mapRefLat?: String;
    }) => {
      let dropzoneStyle;
      
      return (
        <Link to={"/user/" + user?.id + "/detail"} target="_blank">
            <Box
          className="relative"
          css={{
            backgroundColor: "#fff",
            borderRadius: 5,
            cursor: "pointer",
            "@media (max-width: 992px)": { marginBottom: 15 },
          }}
        >
          {
            user ? 
            <>
            <Box
            display="flex"
            py={2}
            px={{ xs: 2, md: 0 }}
            borderTop={borderTop ? "1px solid #ddd" : 0}
            style={dropzoneStyle}
          >
            <Box pr={2} pl={2}>
              <Avatar css={{ width: 32, height: 32 }} src={user?.avatar}>
                {user?.name.substring(0, 1)}
              </Avatar>
            </Box>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Box mb={2} position="relative">
                  <Box
                    display="flex"
                    alignItems="center"
                    css={{ color: "#35353F" }}
                  >
                    <Box fontWeight={600} fontSize={14} mr={1}>
                      {`${user?.name} ${user.surname}`}
                    </Box>
                    
                    <Box position="relative">
                      
                    </Box>
                  </Box>
                  <Box fontSize={12} css={{ color: "#5B5B62" }}>
                    {`${
                      user.gender === "m"
                      ? "Male"
                      :user.gender === "M"
                      ? "Male"
                      :user.gender === "male"
                      ? "Male"
                      : user.gender === "f"
                      ? "Female"
                      : user.gender === "F"
                      ? "Female"
                      :user.gender === "female"
                      ? "Female"
                      : "-"
                    }
                | ${user.nationality} | 
                ${ user?.onboard?.hotel ? user?.onboard?.hotel : 
                   user?.onboard?.ward?.name_en? user?.onboard?.ward?.name_en :
                   "-"
                 }`}
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box
                  fontSize={12}
                  textAlign="right"
                  css={{ color: "rgba(30, 30, 30, 0.5)"}}
                  style={{ paddingRight: "11px" }}
                  display={{ xs: "none", md: "block" }}
                >
                  {user?.health.updated_at && dateStrAPI(user?.health.updated_at) + " " + timeStr(user?.health.updated_at)}
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box display={{ xs: "none", md: "block" }} fontSize={12} css={{ color: "#5B5B62" }}>
                   
                   {
                     user?.identity_card?.personal_no ? 
                     <>
                        ID Card No. :{user?.identity_card?.personal_no} 
                     </>
                     : user?.passport?.passport_number ?
                     <>
                        Passport No. : {user?.passport?.passport_number}
                     </>
                     :
                     <>
                        Disease: {user?.health?.disease ? user?.health?.disease : "-"}
                     </>
                     
                   }
                </Box>
              </Grid>
              <Grid
                md={6}
                xs={10}
                item
                // spacing={2}
                style={{ justifyContent: "flex-end" }}
              >
                <DeviceValueTab device={user?.health}/>
              </Grid>
            </Grid>
          </Box> 
          
          <Box
            display={{ xs: "flex", md: "none" }}
            px={2}
            py={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              fontSize={12}
              textAlign="right"
              pr= {2}
              css={{ color: "rgba(30, 30, 30, 0.5)"}}
            >
              {user?.health.updated_at && dateStrAPI(user?.health.updated_at) + " " + timeStr(user?.health.updated_at)}
            </Box>
          </Box>
  
          </>
          :
          
          <div
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              position: "absolute",
              opacity: "0.5",
            }}
          >
              There is no user info.
          </div>
          
          }
          
        </Box>  
        </Link>
          
        
      );
    }
  );