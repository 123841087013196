import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Grid
} from "@material-ui/core";
import { webStore } from "../../stores/webStore";
import { UserListSI } from "../../services/api/user/useListUser";
import {
  CardTopTotalUserByGender,
  CardTotalUserByGender,
} from "./CardTotalUserByGender";
import { CardNationality, CardTopNationality } from "./CardNationality";
import { CardAge, CardTopAge } from "./CardAge";
import { CardUserListSI } from "./CardUserListSI";
import { Swiper, SwiperSlide } from "swiper/react";
import { Filter } from "../../types/user";
import { SubBar } from "./SubBar";

export const DashboardSI = () => {
  useEffect(() => {
    return () => {
      webStore.resetFilter();
    };
  }, []);
  const mapRef = useRef<any>();
  const [page, setPage] = useState<number>(1);
  const filter: Filter = {
    startDate: webStore.startDate || new Date(),
    endDate: webStore.endDate || new Date(),
    filter_temp: webStore.filter_temp,
    filter_wear: webStore.filter_wear,
    filter_battery: webStore.filter_battery,
    filter_yacht_name: webStore.filter_yacht_name,
    filter_Keyword: webStore.filter_Keyword,
    page: page,
    perPage: 10,
  };
    const { result_userList, loading_userList, total } = UserListSI(filter);

  return (
    <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SubBar title={"Summary"} />
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <CardTotalUserByGender />
            </Grid>
            <Grid
              style={{
                minHeight: "100%",
                flex: 1,
                display: "flex",
              }}
              item
              xs={9}
            >
              <Grid
                container
                style={{
                  minHeight: "100%",
                  flex: 1,
                  display: "flex",
                }}
                spacing={1}
              >
                <Grid item xs={6}>
                  <CardNationality />
                </Grid>
                <Grid item xs={6}>
                  <CardAge />
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                  }}
                  xs={12}
                >
                  <Grid
                    container
                    style={{
                      minHeight: "100%",
                      flex: 1,
                    }}
                    spacing={1}
                  >
                        <Grid item xs={6}>
                          <CardTopNationality />
                        </Grid>
                        <Grid item xs={6}>
                          <CardTopAge />
                        </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* spacing={1} */}
        <Grid item xs={12}>
          <Grid item xs={12}>
            <SubBar title={"Users"} reload={false} viewAll={"/user/listSI"} />
          </Grid>
        </Grid>
        
        <Grid item xs={12}>
          <CardUserListSI
            result_userList={result_userList}
            loading_userList={loading_userList}
            total={total}
            filter={filter}
            setPage={setPage}
            mapRef={mapRef}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export const DashboardMobileSI = () => {
  const [page, setPage] = useState<number>(1);
  const filter: Filter = {
    startDate: webStore.startDate || new Date(),
    endDate: webStore.endDate || new Date(),
    filter_temp: webStore.filter_temp,
    filter_wear: webStore.filter_wear,
    filter_battery: webStore.filter_battery,
    filter_yacht_name: webStore.filter_yacht_name,
    filter_Keyword: webStore.filter_Keyword,
    page: page,
    perPage: 10,
  };
    const { result_userlist_raw ,result_userList, loading_userList, total } = UserListSI(filter);

  return (
    <Container maxWidth="lg" className="pb-8">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubBar title={"Summary"} />
        </Grid>
        <Grid item xs={12}>
          <Swiper
            spaceBetween={5}
            slidesPerView={1.5}
            onSwiper={(swiper) => console.log("DashboardMobile", swiper)}
          >
            <SwiperSlide>
              <CardTopTotalUserByGender />
            </SwiperSlide>
            <SwiperSlide>
              <CardTopNationality />
            </SwiperSlide>
            <SwiperSlide>
              <CardTopAge />
            </SwiperSlide>
          </Swiper>
        </Grid>
        <Grid item xs={12}>
          <SubBar title={"Breakdown"} reload={false} />
        </Grid>
        <Grid item xs={12}>
          <CardTotalUserByGender />
        </Grid>
        <Grid item xs={12}>
          <CardNationality />
        </Grid>
        <Grid item xs={12}>
          <CardAge />
        </Grid> 
        <Grid item xs={12}>
          <SubBar title={"Users"} reload={false} viewAll={"/user/listSI"} />
        </Grid>
        <Grid item xs={12}>
          <CardUserListSI
            result_userList={result_userList}
            loading_userList={loading_userList}
            total={total}
            filter={filter}
            setPage={setPage}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
