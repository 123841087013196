import React, {  useEffect, useState } from "react";

import {
  Box,
  Container,
  Grid,
  Button,
  TextField,
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core";
import LuxonUtils from "@date-io/luxon";
import SearchIcon from "@material-ui/icons/Search";
import { webStore } from "../../stores/webStore";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "material-ui-thai-datepickers";
import MaterialTable from "material-table";
import tableIcons from "./MaterialTableIcons";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import './Messenger.css';
import { dateStringFullMothName } from "../../utils";
import { GetPatientRecent } from "../../services/api/telemedicine/Finance";

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#303f9f',
      },
    },

  });

export const ReportInfo = observer(() => {
    const history = useHistory();
    const rawData : any = [];
    const [patient_name, setPatient_name] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(webStore.startDate);
    const [endDate, setEndDate] = useState<Date | null>(webStore.endDate);
    const  { result_patientRecent, loading_patientRecent } = GetPatientRecent(patient_name, startDate, endDate);
    const [data, setData] = useState(rawData);

    useEffect(() =>{
        if(result_patientRecent){
            for (let i = 0; i < result_patientRecent.length ; i++) {
                rawData.push({
                    idTable: i+1,
                    id: result_patientRecent[i]?.id,
                    name: result_patientRecent[i]?.patient?.name + " " + result_patientRecent[i]?.patient?.surname,
                    gender: result_patientRecent[i]?.patient?.gender=== 'm' ?  "Male" : "Female",
                    date: dateStringFullMothName(result_patientRecent[i]?.start_time),
                    doctor: result_patientRecent[i]?.doctor?.name + " " + result_patientRecent[i]?.patient?.surname
                });
            }
            setData(rawData);
        }
    }, [result_patientRecent]);

    return(
        <Box className="p-4">
            <Box 
                style={{
                    paddingLeft: "10%", 
                    paddingRight: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <TextField 
                    id="standard-name"
                    value={patient_name} 
                    label="Patient Name"  
                    margin="normal"
                    onChange={(e) => setPatient_name(e.target.value)} 
                    style={{marginLeft: "10px", marginRight: "10px"}}
                />

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="Start Date"
                    value={startDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setStartDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    autoOk = {true}
                    yearOffset={543}
                    style={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="from"
                    label="End Date"
                    value={endDate || new Date()}
                    onChange={(date: MaterialUiPickersDate) => {
                        setEndDate(date ? new Date(date.toString()) : null);
                    }}
                    KeyboardButtonProps={{
                        "aria-label": "change date",
                    }}
                    autoOk = {true}
                    yearOffset={543}
                    style={{marginLeft: "10px", marginRight: "10px"}}
                    />
                </MuiPickersUtilsProvider> 

                <Button
                    color="primary"
                    onClick={() => {
                        console.log("search");
                    }}
                    style={{
                    padding: 8,
                    minWidth: "unset",
                    boxShadow: "unset",
                    marginRight: '2%'
                    }}
                >
                    <SearchIcon />
                </Button>

                <Button
                    type="submit"
                    onClick={() => {
                        setPatient_name("");
                        setStartDate(new Date());
                        setEndDate(new Date());
                    }}
                    variant="contained"
                    color="primary"
                    style={{marginLeft: "10px", marginRight: "10px"}}
                >
                    Clear
                </Button>
            </Box>
            <Box className="pt-5">
            <MuiThemeProvider theme={theme}>
            <MaterialTable
                    icons={tableIcons}
                    columns={[
                        { title: 'No.', field: 'idTable' },
                        { title: 'Name', field: 'name', type: 'string' },
                        { title: 'Gender', field: 'gender', type: 'string' },
                        { title: 'Date', field: 'date', type:'date'},
                        { title: 'Doctor', field: 'doctor', type:'string'},
                        {
                            title: "Actions",
                            render: rawData => {
                              return (
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                      history.push('/telemedicine/medicalCertificate');
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                              );
                            }
                        }
                    ]}
                    data={data}
                     
                    options={{
                        showTitle: false,
                        search: false,
                    }}
                />
            </MuiThemeProvider>     
            </Box>
        </Box>
    );
});

export const ReportTelemedicine = observer(() => {  
  
  return(
    <Container maxWidth="xl" className="py-4">
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className="flex justify-between items-center flex-wrap"
                    pt={4}
                    mb={3}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        css={{
                            "@media (max-width: 992px)": {
                            width: "100%",
                            justifyContent: "space-between",
                            },
                        }}
                        mb={{ xs: 3, md: 0 }}
                    >
                        <Box fontSize="h5.fontSize" fontWeight={500} mr={3}>
                             Report
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <ReportInfo/>
            </Grid>
        </Grid>
    </Container>
  );
});
export const MobileReportTelemedicine = ReportTelemedicine;