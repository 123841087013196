import {
    Avatar,
    Grid,
    Box
  } from "@material-ui/core";
  import React, { useState } from "react";  
  import { dateStrAPI } from "../../utils";
  import { useSnackbar } from "notistack";
  import { useUpdateUser } from "../../services/api/user/useUpdateUser";
  import { EditField } from "./EditField";
  import { Field } from "./EditField";

 export const calculateAge = (date : any) => {
   if(date){
    let birthDate = new Date(date?.toString());
    let difference = Date.now() - birthDate.getTime();
    
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() -1970);
    return calculatedAge;
   }
  }
  
  export const UserInfoCardSI = ({
    user,
    userRaw,
    reload,
  }: {
    user: any;
    userRaw?: any;
    reload: any;
  }) => {

    const [isEdit, setIsEdit] = useState(false);
    const [editValue, setEditValue] = useState<any>();
    const [loading, setLoading] = useState(false);
    const { fire_userUpdate } = useUpdateUser();

    const { enqueueSnackbar } = useSnackbar();
  
    return (
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          {
            user?.onboard?.hospital?.name_en ? 
            <Field label="Hospital" value={user?.onboard?.hospital?.name_en.toString()} />
            :
            <Field label="Hospital" value="-" />
          }
          
        </Grid>

        <Grid item md={3} xs={12}>
          {
            user?.onboard?.ward?.name_en ? 
            <Field label="Ward" value={user?.onboard?.ward?.name_en.toString()} />
            :
            <Field label="Ward" value="-" />
          }
          
        </Grid>
        <Grid item md={3} xs={12}>
          {
            user.date_of_birth ? 
            <Field label="Date of birth" value={dateStrAPI(user.date_of_birth)} />
            :
            <Field label="Date of birth" value="-" />
          }
          
        </Grid>

        <Grid item md={3} xs={12}>
            {
                user.date_of_birth ? 
                <Field label="Age" value={calculateAge(user.date_of_birth)} />
                :
                <Field label="Age" value="-" />
            }
        </Grid>

        {
           user?.onboard?.bed_number ?
           <>
            <Grid item md={3} xs={12}>
              <Field
                label="Bed Number"
                value={user?.onboard?.bed_number}
                />
            </Grid>
           </>
           :<></>
        }

        {
           user?.onboard?.room_number ?
           <>
            <Grid item md={3} xs={12}>
              <Field
                label="Room Number"
                value={user?.onboard?.room_number}
                />
            </Grid>
           </>
           :<></>
        }
        
        {
           user?.onboard?.patient_info?.medicine_regularly ?
           <>
            <Grid item md={4} xs={12}>
              <Field
                label="Current Taking Medicine"
                value={user?.onboard?.patient_info?.medicine_regularly}
                />
            </Grid>
           </>
           :<></>
        }    

        {
           user?.onboard?.patient_info?.medical_condition ?
           <>
            <Grid item md={4} xs={12}>
              <Field
                label="Medical Condition"
                value={user?.onboard?.patient_info?.medical_condition}
                />
            </Grid>
           </>
           :<></>
        }   


          {
           user?.onboard?.patient_info?.medicine_allergies ?
           <>
            <Grid item md={4} xs={12}>
              <Field
                label="Medicine Allergy"
                value={user?.onboard?.patient_info?.medicine_allergies}
                />
            </Grid>
           </>
           :<></>
        }   



  
        <EditField
          {...editValue}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setValue={setEditValue}
          onClick={async () => {
            setLoading(true);
            await reload();
            setIsEdit(false);
            setLoading(false);
            enqueueSnackbar(`${editValue.label} success`, {
              key: "updateSuccess",
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }}
        />
      </Grid>
    );
  };
  
  export const UserProfileDetail = ({ user, userRaw, reload }: { user: any; userRaw?:any, reload: any }) => {
    return (
      <Box display="flex" alignItems="center">
        <Box mr={3}>
          <Avatar
            src={user.avatar}
            alt={user.name}
            css={{ width: "120px !important", height: "120px !important" }}
          />
        </Box>
        <Box>
          <Box fontSize="h6.fontSize" fontWeight={600}>
            {`${
                user.name ? user.name.toString() : "-"
              } ${
                user.surname ? user.surname.toString() : "-"
              }`}
          </Box>
          <Box fontSize={14}>{`${
            user.gender == "m"
              ? "Male"
              : user.gender == "M"
              ? "Male"
              : user.gender == "Male"
              ? "Male"
              : user.gender == "male"
              ? "Male"
              : user.gender == "f"
              ? "Female"
              : user.gender == "F"
              ? "Female"
              : user.gender == "female"
              ? "Female"
              : user.gender == "Female"
              ? "Female"
              : user.gender ? user.gender.toString() : "-"
          }, ${user.nationality ? user.nationality.toString() : "-"}`}</Box>
          <br/>
          <Box fontSize={16} fontWeight={600}>
             Disease
          </Box>
          <Box fontSize={14}>{`${user?.health?.disease || "-"}`}</Box>
        </Box>
      </Box>
    );
  };
  