import React from "react";
import { observer } from "mobx-react-lite";
import { User } from "../../types/user";
import { userDevice } from "../../types/device";
import { Box } from "@material-ui/core";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import WifiIcon from "@material-ui/icons/Wifi";

export const OnlineTab = observer(
    ({ user, device }: { user: User; device?: userDevice }) => {
      return (
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            mr={3}
            css={{
              color: device?.device_status === 3  ? "#C4C4C4" 
                    :device?.device_status === 4 ? "#C4C4C4"
                    :device?.device_status === 9 ? "#C4C4C4"
                    :"#10940E"
            }}
          >
            <Box
              css={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor:
                device?.device_status === 3  ? "#C4C4C4" 
                :device?.device_status === 4 ? "#C4C4C4"
                :device?.device_status === 9 ? "#C4C4C4"
                :"#10940E"
              }}
            ></Box>
            <Box ml={1} fontSize={12}>
              {
                 device?.device_status === 3  ? "Offline"
                :device?.device_status === 4 ? "Not Wear"
                :device?.device_status === 9 ? "Offline & Not Wear"
                :"Wearing"
              }
            </Box>
          </Box>
  
          {device?.device_status === 3 ? (
            <Box display="flex" alignItems="center" css={{ color: "#C4C4C4" }}>
              <WifiOffIcon css={{ width: 15 }} />
              <Box ml={1} fontSize={12}>
                Offline
              </Box>
            </Box>
          ) 
          :device?.device_status === 4 ? (
            <Box display="flex" alignItems="center" css={{ color: "#C4C4C4" }}>
              <WifiOffIcon css={{ width: 15 }} />
              <Box ml={1} fontSize={12}>
                Offline
              </Box>
            </Box>
          )
          :device?.device_status === 9 ? (
            <Box display="flex" alignItems="center" css={{ color: "#C4C4C4" }}>
              <WifiOffIcon css={{ width: 15 }} />
              <Box ml={1} fontSize={12}>
                Offline
              </Box>
            </Box>
          )
          : (
            <Box display="flex" alignItems="center" css={{ color: "#10940E" }}>
              <WifiIcon css={{ width: 15 }} />
              <Box ml={1} fontSize={12}>
                Online
              </Box>
            </Box>
          )}
        </Box>
      );
    }
  );