import { RecentPatientData } from "../../../types/Message";
import { useCallAPISpacetrax,CallTelemed } from "../../useCallAPI";
export const NotificationToken = () => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        code: 0 | 1;
        message: string;
        data: any;
    }>(
      {
        url: `/v2/notification-token/create`,
        method: "POST",
      },
      { manual: true }
    );
    return {
      result_notification: res?.data,
      loading_notification: loading,
      error_notification: error,
      fire_notification: () => {
      var formData = {
        provider: "firebase",
        token: localStorage.getItem("firebaseToken"),
        user_id: localStorage.getItem("userId")
      }
      return new Promise((resolve, reject) => {
        fire({
          data: formData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

  export const OutgoingCall = () =>{
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        code: 0 | 1;
        message: string;
        data: any;
    }>(
      {
        url: `/v2/notification-token/create`,
        method: "POST",
      },
      { manual: true }
    );
    return {
      result_notification: res?.data,
      loading_notification: loading,
      error_notification: error,
      fire_notification: () => {
      var formData = {
        provider: "firebase",
        token: localStorage.getItem("firebaseToken"),
        user_id: localStorage.getItem("userId")
      }
      return new Promise((resolve, reject) => {
        fire({
          data: formData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

  export const GetAgoraToken = (channelName: any ) => {
    const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
        data: {
          "channel_name": string,
          "token": string,
          "user_id": any
        }
      }>(
        {
          url:  `/v2/video-call/token?channel_name=${channelName}`,
          method: "GET"
        }
      );
    
      return {
        result_agorToken: res?.data ? res?.data : null,
        loading_agoraToken: loading,
        error_Token: error
      };
  }

  export const JoinVideoCall = (channelName: any ) => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: {
          code: 0 | 1;
          message: string;
          data: any;
        }
      }>(
        {
          url:  `/v2/video-call/join?channel_name=${channelName}`,
          method: "GET"
        }
      );
    
      return {
        result_joinCall: res?.data ? res?.data : null,
        loading_joinCall: loading,
        error_joinCall: error
      };
  }

  export const EndCall = () =>{
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        code: 0 | 1;
        message: string;
        data: any;
    }>(
      {
        url: `/v2/video-call/end/doctor`,
        method: "POST",
      },
      { manual: true }
    );
    return {
      result_endCall: res?.data,
      loading_endCall: loading,
      error_endCall: error,
      fire_endCall: (channelName: string) => {
          let requestData = {};
          requestData = {
              channel_name: channelName,
              duration: '10:00'
          }
      return new Promise((resolve, reject) => {
        fire({
          data: requestData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

  export const SubmitMeetingResult = () =>{
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        code: 0 | 1;
        message: string;
        data: any;
    }>(
      {
        url: `/v2/smart-infirmary/booking/end/doctor`,
        method: "POST",
      },
      { manual: true }
    );
    return {
      result_endMeeting: res?.data,
      loading_endMeeting: loading,
      error_endMeeting: error,
      fire_endMeeting: (booking_id: string, diagnosis: string, is_medicines: string, is_lab_report: string, is_follow_up: string) => {
          let requestData = {};
          requestData = {
            booking_id: booking_id,
            diagnosis: diagnosis,
            is_medicines: is_medicines === 'yes' ? true: false, 
            is_lab_report: is_lab_report ==='yes' ? true: false,
            is_follow_up: is_follow_up === 'yes' ? true: false
          }
      return new Promise((resolve, reject) => {
        fire({
          data: requestData,
        })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };

  export const GetRecentPatientListMsg = ( ) => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: RecentPatientData[];
      }>(
        {
          url:  `/v2/dashboard/patient/message`,
          method: "GET",
        }
      );
    
      return {
        result_recentPatientList: res?.data ? res?.data : [],
        loading_recentPatientList: loading,
        error_recentPatientList: error
      };
  }
