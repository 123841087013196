import React from "react";
import { observer } from "mobx-react-lite";
import { User } from "../../types/user";
import { userDevice } from "../../types/device";
import { Places } from "../../types/places";
import { Oximeter } from "../../types/oximeter";
import { useHistory } from "react-router-dom";
import { Avatar, Box, Grid } from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { dateStr } from "../../utils";
import { OnlineTab } from "./OnlineTab";

export const UserItem_AlertTemp = observer(
    ({
      user,
      device,
      place,
      oximeter,
      borderTop,
    }: {
      user: User;
      device?: userDevice;
      place?: Places;
      oximeter?: Oximeter;
      borderTop?: boolean;
    }) => {
      const history = useHistory();
      return (
        <Box
          className="relative"
          css={{
            backgroundColor: "#fff",
            borderRadius: 5,
            cursor: "pointer",
            "@media (max-width: 992px)": { marginBottom: 15 },
          }}
          onClick={() => {
            if(user.id){
              history.push("/user/" + user.id + "/preview");
            }else{
              history.push("/user/" + "userId_empty" + "/preview");
            }
          }}
        >
          <Box
            display="flex"
            py={2}
            px={{ xs: 2, md: 0 }}
            borderTop={borderTop ? "1px solid #ddd" : 0}
            css={{
              opacity: device?.latitude && device?.longitude ? 1 : 0.5,
              "@media (max-width: 992px)": { borderTop: 0 },
              "&:hover": {
                backgroundColor: "rgba(3,169,244,0.08)",
                borderTop: 0,
              },
            }}
          >
            <Box pr={2} pl={2}>
              <Avatar css={{ width: 32, height: 32 }} src={user?.avatar}>
                {user?.name.substring(0, 1)}
              </Avatar>
            </Box>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Box mb={2} position="relative">
                  <Box
                    display="flex"
                    alignItems="center"
                    css={{ color: "#35353F" }}
                  >
                    <Box fontWeight={600} fontSize={14} mr={1}>
                      {`${user?.name} ${user.surname}`}
                    </Box>
                    {user?.isSos && (
                      <ReportProblemIcon
                        css={{
                          color: "#C83728",
                          width: 16,
                          height: 16,
                          marginRight: "0.3em",
                        }}
                      />
                    )}
                  </Box>
                  <Box fontSize={12} css={{ color: "#5B5B62" }}>
                    {`${
                      user.gender === "m"
                      ? "Male"
                      :user.gender === "M"
                      ? "Male"
                      : user.gender === "f"
                      ? "Female"
                      : user.gender === "F"
                      ? "Female"
                      : ""
                    }
                | ${user.nationality} | ${place?.name_en}`}
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box
                  fontSize={12}
                  textAlign="right"
                  css={{ color: "rgba(30, 30, 30, 0.5)" }}
                  display={{ xs: "none", md: "block" }}
                >
                  {device?.updated_at && dateStr(new Date(device?.updated_at))}
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box display={{ xs: "none", md: "block" }}>
                  <OnlineTab user={user} device={device} />
                </Box>
              </Grid>
              <Grid
                md={6}
                xs={10}
                item
                // spacing={2}
                style={{ justifyContent: "flex-end" }}
              >
                {/* <DeviceValueTab device={device} oximeter={oximeter}/> */}
              </Grid>
            </Grid>
          </Box>
          <Box
            display={{ xs: "flex", md: "none" }}
            px={2}
            py={1}
            justifyContent="space-between"
            alignItems="center"
            css={{
              opacity: device?.latitude && device?.longitude ? 1 : 0.5,
              "@media (max-width: 992px)": { borderTop: "1px solid #ddd" },
            }}
          >
            <OnlineTab user={user} device={device} />
            <Box
              fontSize={12}
              textAlign="right"
              css={{ color: "rgba(30, 30, 30, 0.5)" }}
            >
              {device?.updated_at && dateStr(new Date(device?.updated_at))}
            </Box>
          </Box>
        </Box>
      );
    }
  );