import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel ,
  FormControlLabel ,
  RadioGroup ,
  Radio, 
  Modal
} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { dateStringFullMothName,dateStrFormatTime } from "../../../utils";
import '../VideoCallStyle.css';
import '../Messenger.css';

import { SubmitMeetingResult } from "../../../services/api/telemedicine/Videocall"; 
import { GetBookingInfoById } from "../../../services/api/telemedicine/BookingService";  
import {
  GetUserProfile
} from "../../../services/api/user/useReadUser";
import { useSnackbar } from "notistack";

export const PatientInfo  = observer(() => {
  const { index, name, surname, chatChannel, chatChannelType, appointmentId }: any = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = React.useState('yes');
  const [labReportVale, setLabReportValue ] = React.useState('yes');
  const [followUpValue, setFollowupValue ] = React.useState('yes');
  const [ diagnoses, setDiagnoses ] = React.useState("");
  const [ patientName, setName] = useState("");
  const [ symptom, setSymptom ] = useState("");
  const [ userId, setUserId ] = useState("");

  const { result_getUserProfile,fire_userRead, loading_getUserProfile, ...rest } = GetUserProfile(userId);  
  const { result_bookingData } = GetBookingInfoById(appointmentId);
  const { fire_endMeeting } = SubmitMeetingResult();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleChangeLabReport = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabReportValue((event.target as HTMLInputElement).value);
  };

  const handleChangeFollowup = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFollowupValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if(index){
        setUserId(index);
    }

    if(result_bookingData){
      setName(result_bookingData?.patient?.name + " " + result_bookingData?.patient?.surname);
      setDiagnoses(result_bookingData?.diagnosis);
      setFollowupValue(result_bookingData?.is_follow_up? "yes" : "no");
      setLabReportValue(result_bookingData?.is_lab_report ? "yes" : "no");
      setValue(result_bookingData?.is_medicines ? "yes" : "no");
      setSymptom(result_bookingData?.symptom);
    }
  }, [ result_bookingData, index]);

  return (
    <>
        <Box style={{position: "relative", overflow: "auto" }}
            css={{ borderRadius: 5, backgroundColor: "#c5cfe1" , padding : '20px', height: '90vh' }}>
                <p> <span style={{fontWeight: 'bold'}}>Name :</span> 
                    {patientName}
                </p> 
                
                <br/>
                <p> <span style={{fontWeight: 'bold'}}>Date :</span>
                {
                            result_bookingData?
                            <>
                                { dateStringFullMothName(result_bookingData?.start_time) ? 
                                    <>
                                        {dateStringFullMothName(result_bookingData?.start_time) + ", " + dateStrFormatTime(result_bookingData?.start_time)}
                                    </>
                                    :
                                    <></>
                                }
                            </>
                            :
                            <></>
                }
                </p>
                <br/>
                <p>
                <span style={{fontWeight: 'bold'}}>Health Report :</span> 
                <br/>
                
                <span style={{fontWeight: 'bold', paddingRight: '10%', fontSize: '12px', color: '#585151'}}>Body Temp</span> 
                <span style={{fontWeight: 'bold', paddingRight: '10%', fontSize: '12px', color: '#585151'}}>Heart Rate</span> 
                <span style={{fontWeight: 'bold',  fontSize: '12px', color: '#585151'}}>
                    Blood Pressure
                </span> 
                <br/>
                <span style={{paddingRight: '78px'}}> {result_getUserProfile?.health?.body_temp ? result_getUserProfile?.health?.body_temp : "N/A"} </span>

                <span style={{paddingRight: '90px'}}> {result_getUserProfile?.health?.heart_rate ? result_getUserProfile?.health?.heart_rate : "N/A"} </span>

                <span> 
                    { result_getUserProfile?.health?.blood_systolic && result_getUserProfile?.health?.blood_diastolic ?
                    `${result_getUserProfile?.health?.blood_systolic}/${result_getUserProfile?.health?.blood_diastolic}`
                    :
                    "N/A"
                    }
                </span>
                <br/>

                <span style={{fontWeight: 'bold', paddingRight: '9%', fontSize: '12px', color: '#585151'}}>Blood Sugar</span> 
                <span style={{fontWeight: 'bold', paddingRight: '18%', fontSize: '12px', color: '#585151'}}>SPO<sub>2</sub></span> 
                <span style={{fontWeight: 'bold',  fontSize: '12px', color: '#585151'}}>Respiration</span> 
                <br/>
                <span style={{paddingRight: '92px'}}> {result_getUserProfile?.health?.body_temp ? result_getUserProfile?.health?.blood_sugar : "N/A"} </span>

                <span style={{paddingRight: '90px'}}> {result_getUserProfile?.health?.heart_rate ? result_getUserProfile?.health?.blood_oxygen : "N/A"} </span>

                <span> 
                    {result_getUserProfile?.health?.breath ? result_getUserProfile?.health?.breath : "N/A"}
                </span>
                <br/>
                
                </p>
                <br/>
                <br/>
                <p>
                    <span style={{fontWeight: 'bold'}}>Symptom Case :</span> 
                    {symptom}
                </p>
                <br/>

                <hr/>
                <br/>
                <p>
                    <span style={{fontWeight: 'bold'}}>Diagnoses </span> 
                </p>
                <br/>
                <Box
                    css={{ backgroundColor: "#fff", borderRadius: 30, border: 'solid 2px #3f51b5' }}
                    p={2}
                >
                    <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={1}
                    mt={1}
                    >
                    
                    </Box>

                    <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                    <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    size="small"
                    margin="dense"
                    >
                    <TextareaAutosize  name="remark-label" placeholder="Type ....."
                    rowsMin={5} 
                    value={diagnoses} onChange={(event) =>{
                        setDiagnoses(event.target.value);
                    }}
                    />
                    </FormControl>
                    
                    </Box>
                    <br/>
                </Box>
                <br/>
                
                <FormControl component="fieldset" style={{display :'felx'}}>
                    <FormLabel component="legend">Medicine</FormLabel>
                    <RadioGroup row aria-label="medicine" name="medicine" value={value} onChange={handleChange}>
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" style={{display :'flex'}}>
                    <FormLabel component="legend">Lab Report</FormLabel>
                    <RadioGroup row aria-label="medicine" name="medicine" value={labReportVale} onChange={handleChangeLabReport}>
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                </FormControl>
                <FormControl component="fieldset" style={{display :'flex'}}>
                    <FormLabel component="legend">Follow Up</FormLabel>
                    <RadioGroup row aria-label="medicine" name="medicine" value={followUpValue} onChange={handleChangeFollowup}>
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                </FormControl>

                
                <Box
                    style={{
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 5,
                    marginTop: '20px'
                    }}
                >
                    <Button  variant="contained" color="primary"
                    onClick ={async()=>{
                        try{
                        fire_endMeeting(appointmentId, diagnoses, value, labReportVale, followUpValue)
                        .then(() => {
                            enqueueSnackbar("Success", {
                            variant: "success",
                            autoHideDuration: 3000,
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                            });
                        })
                        .catch((ex) => {
                            enqueueSnackbar(
                            "Error" +
                                (ex ? " : " + ex.toString() : ""),
                            {
                                variant: "error",
                                autoHideDuration: 3000,
                                anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                                },
                            }
                            );
                        });
                        }catch(ex){

                        }
                    }}
                    > Submit</Button>
                </Box>
        </Box> 
    </>
  );
});