import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
    Box,
    Button,
    Modal
  } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { BasicDropZone } from "./DropZone";

export const UploadFileModal = observer(
    ({
      isUploadFile,
      onClick,
      setIsUploadFile,
      buttonNameConfirm = "CONFIRM",
      buttonNameCANCEL = "CANCEL",
    }: {
      isUploadFile: boolean;
      setIsUploadFile: any;
      onClick?: any;
      type?: string;
      buttonNameConfirm?: string;
      buttonNameCANCEL?: string;
    }) => {
      const [ file, setFile ] = useState<any>();

      return (
        <Modal
          open={isUploadFile}
          onClose={() => {}}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Box
                style={{ backgroundColor: "#fff", width: 400, borderRadius: 30, border: 'solid 2px #3f51b5' }}
                p={2}
            >
                <Box
                    display="flex"
                    justifyContent="right"
                    alignItems="center"
                    mb={1}
                    mt={1}
                >
                <Box onClick={()=> {setIsUploadFile(false);}}>
                    <CloseIcon style={{ cursor: "pointer" }} />
                </Box>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center" 
                style={{ color: "#4B4C56" }}
            >
                <h1 style={{ color: "#0c1679", fontSize: "24px" }}> Upload File </h1>
                
            </Box>
            <Box
                className="mt-3"
                textAlign= "center"

                // display="flex"
                // justifyContent="center"
                // alignItems="center"
            >
                <BasicDropZone setFile={setFile}/>
                
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt ={2}
                >
                <Button
                    style={{margin: "10px"}}
                    onClick={async() => {
                      onClick(file);
                    }}
                    color="primary"
                    size="medium"
                    variant="contained"
                >
                    {buttonNameConfirm}
                </Button>

                <Button
                    style={{margin: "10px"}}
                    onClick={() => {
                      setIsUploadFile(false);
                    }}
                    size="medium"
                    variant="contained"
                >
                    {buttonNameCANCEL}
                </Button>
                </Box>
                <br/>
          </Box>
        </Modal>
      );
    }
);