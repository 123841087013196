import { useCallAPI, useCallAPISpacetrax } from "../../useCallAPI";
import { ProjectInfo } from "../../../types/ProjectInfo";
import { Agencies } from "../../../types/agencies";
import { Branch } from "../../../types/Branch";
import { Places } from "../../../types/places";
import { Hospital } from "../../../types/hospital";

export const useListBranches = () => {
  const [{ data: res, loading, error }, fire] = useCallAPI<{
    data: { id: string; name_en: string; name_th: string }[];
  }>({
    url: `/api/v1/branches`,
    method: "GET",
  });
  return {
    result_branches: res?.data || [],
    loading_branches: loading,
    error_branches: error,
  };
};

export const useListProject = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      agency? : Agencies[],
      project? : ProjectInfo,
    };
  }>({
    url: `/v2/dashboard/project/${localStorage.getItem('projectId')}`,
    method: "GET",
  });
  const result_projectData = res?.data?.project;
  let result_branch : Branch[] = [];
  let result_place: null | Places[] = null;
  let result_hospital: null | Hospital[] = null;
  if (result_projectData) {
    result_branch = result_projectData.branch;
  }
  

  return {
    result_agency: res?.data?.agency || [] ,
    result_branch,
    loading_project: loading,
    error_project: error,
  };
};
