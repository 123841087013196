import React, { useRef, useState } from "react";

import {
  Box,
  Container,
  Grid,
  Button
} from "@material-ui/core";
import { CardUserListByStatusSI } from "../Dashboard/CardUserListByStatusSI";
import AddIcon from "@material-ui/icons/Add";
import { webStore } from "../../stores/webStore";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Filter, Value, ReloadValue } from "../../types/user";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { UserListSI,UserListSIDischarge } from "../../services/api/user/useListUser";
import { UserSearch } from "./UserSearch";

export const UserListSmartInfirmary = observer(() => {
  const history = useHistory();
  const [displayUserLocation, setDisplayUserLocation] = useState<boolean>(
    false
  );
  const [keywordsearch, setKeywordsearch] = useState(String);
  const mapRef = useRef<any>();
  const [page, setPage] = useState<number>(1);
  const [pageWaiting, setPageWaiting] = useState<number>(1);
  const [value, setValue] = React.useState(0);
  const [filterDischarge, setFilterDischarge] = useState<string>("discharge");
  
  const reloadValue: ReloadValue = {
    reload: webStore.filter_reload
  }

  const filter: Filter = {
    startDate: webStore.startDate || new Date(),
    endDate: webStore.endDate || new Date(),
    filter_temp: webStore.filter_temp,
    filter_wear: webStore.filter_wear,
    filter_battery: webStore.filter_battery,
    filter_yacht_name: webStore.filter_yacht_name,
    filter_Keyword: keywordsearch,
    page: webStore.filter_userListPage,
    perPage: 10,
    waitingPage: webStore.filter_waitingListPage,
    waitingPerPage: 10
  };

  const valueTab: Value = {
    value: webStore.list_valueTab,
    filter_waiting: filterDischarge
  };

  const { result_userList, loading_userList, total } = UserListSI(filter, reloadValue);
  const { result_userList_discharge, loading_userList_discharge, total_discharge } = UserListSIDischarge(filter, valueTab, reloadValue);
  
  return (
    <Container maxWidth="xl" className="py-4">
      <Grid container>
        <Grid item xs={12}>
          <Box
            className="flex justify-between items-center flex-wrap"
            pt={4}
            mb={3}
          >
            <Box
              display="flex"
              alignItems="center"
              css={{
                "@media (max-width: 992px)": {
                  width: "100%",
                  justifyContent: "space-between",
                },
              }}
              mb={{ xs: 3, md: 0 }}
            >
              <Box fontSize="h5.fontSize" fontWeight={500} mr={3}>
                User List
              </Box>
              <UserSearch
              keywordsearch={keywordsearch}
              setKeywordsearch={setKeywordsearch}
            />
            </Box>
            <Box display="flex" alignItems="center">
                {
                  localStorage.getItem("projectType") ? 
                  <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    if(localStorage.getItem("projectType") === "PHUIS"){
                      history.push("/user/createUser");
                    }else if(localStorage.getItem("projectType") === "SI"){
                      history.push("/user/createUserSI")
                    }
                    else{
                      history.push("/user/create");
                    }
                  }}
                  css={{
                    "@media (max-width: 992px)": {
                      backgroundColor: "transparent",
                      boxShadow: "unset",
                      color: "#4684E2",
                    },
                  }}
                >
                  
                  Add user
                </Button>
                : <></>
                }
              </Box>
            
          </Box>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {/* <CardUserListSI
              result_userList={result_userList}
              loading_userList={loading_userList}
              total={total}
              filter={filter}
              setPage={setPage}
              mapRef={mapRef}
            /> */}
            <CardUserListByStatusSI
              result_userList={result_userList}
              loading_userList={loading_userList}
              result_userList_waiting={result_userList_discharge}
              loading_userList_waiting={loading_userList_discharge}
              total={total}
              total_waiting={total_discharge}
              filter={filter}
              setPage={setPage}
              setValue={setValue}
              setPageWaiting={setPageWaiting}
              setFilterWaiting= {setFilterDischarge}
              mapRef={mapRef}
              valueTab = {valueTab}
            />
          </Grid>
        </Grid>
      </Grid>
      {displayUserLocation && (
        <Grid
          container
          className="fixed w-screen h-screen left-0 top-0"
          style={{ zIndex: 9991 }}
        >
          <Box className="absolute w-full z-10 ">
            <Box className="flex justify-center items-center bg-white">
              <Button
                variant="text"
                onClick={() => {
                  setDisplayUserLocation(false);
                }}
                css={{
                  padding: "8px 8px 8px 15px",
                  minWidth: "unset",
                  boxShadow: "unset",
                  borderRight: "1px solid #ddd",
                  borderRadius: 0,
                }}
              >
                <ArrowBackIosIcon style={{ width: 20 }} />
              </Button>
              <UserSearch
                inputWidthMobile={220}
                keywordsearch={keywordsearch}
                setKeywordsearch={setKeywordsearch}
              />
            </Box>
          </Box>
        </Grid>
      )}
    </Container>
  );
});
export const UserListMobileSmartInfirmary = UserListSmartInfirmary;