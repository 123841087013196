import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Container,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import { Filter } from "../../types/user";
import { webStore } from "../../stores/webStore";
import { useParams } from "react-router-dom";
import { GetNoteList } from "../../services/api/user/useReadUser";
import { SubBar } from "../Dashboard/SubBar";
import Pagination from "@material-ui/lab/Pagination";
import { dateStrAPIFormat } from "../../utils";

export const ViewNoteList = observer((props: any) => {
  const { quarantineId }: any = useParams();
  useEffect(() => {
    return () => {
      webStore.resetFilter();
    };
  }, []);
  const [page, setPage] = useState<number>(1);
  const filter: Filter = {
    startDate: webStore.startDate || new Date(),
    endDate: webStore.endDate || new Date(),
    filter_temp: webStore.filter_temp,
    filter_wear: webStore.filter_wear,
    filter_battery: webStore.filter_battery,
    filter_yacht_name: webStore.filter_yacht_name,
    filter_Keyword: webStore.filter_Keyword,
    page: page,
    perPage: 10,
  };
  const {result_getNoteList, loading_getNoteList, total} = GetNoteList(quarantineId, filter);
  console.log('note list:', result_getNoteList);

  const pageCount = Math.ceil(total / (filter?.perPage || 10));
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };


  return (
    <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16">
        {loading_getNoteList && (
          <LinearProgress
            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
          />
        )}

        <Box mb={2}>
            <Grid item xs={12}>
                <SubBar title={"View Notes"} reload={false} back={true}  />
            </Grid>
        </Box>
        <Grid container>
        {(result_getNoteList || []).map((noteList, i) => {
            var index =
            ((filter?.page || 1) - 1) * (filter?.perPage || 10) + i + 1;
            return (
            <Box
                css={{ backgroundColor: "#fff", width: "100%", borderRadius: 40, border: 'solid 2px #3f51b5' }}
                p={2}
                mb={1}
                mt={1}
            >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
                mt={1}
            >
                <Box css={{ color: "black" }} >
                    <p style={{fontSize: '23px'}}>{dateStrAPIFormat(noteList.created_at)}</p> 
                </Box>
            </Box>
            <Box
                display="flex"
            >
                <p>{noteList.note}</p>
            </Box>
            <br/>
          </Box>
            );
        })}
          {pageCount > 1 && (
            <Box mt={2}>
              <Pagination
                count={pageCount}
                page={filter?.page || 1}
                onChange={handleChange}
                size="small"
              />
            </Box>
          )}
        </Grid>
    </Container>
  );
});