import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Grid,
    Button,
    TextField,
    LinearProgress
  } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { GetPatientRecent, CreateInvoiceService } from "../../../services/api/telemedicine/Finance";
import { DateStringAPI, dateStringFullMothName, timeFormat, timeString } from "../../../utils";
import PersonIcon from '@material-ui/icons/Person';

export const Finance_recent = observer(() => {
    const [ name, setName ] = useState('');
    const [ genderInfo , setGender ] = useState('');
    const [ date, setDate ] = useState('');
    const [ userId, setUserId ] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [ invoiceNumber, setInvoiceNumber ] = useState('');
    const [ showMessage, setShowMessage ] = useState(false);
    const [ amount, setAmount ] = useState("");
    const [ invoiceDate, setInvoiceDate ] = useState<any>();
    const [ bookingId, setBookingId ] = useState('');

    let { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    
    let files = acceptedFiles.map(file => (
        <>
            <li key={file.name}>
                {file.name}
            </li>
        </>
      
    ));
    const { result_patientRecent, loading_patientRecent } = GetPatientRecent();
    const { result_create_invoice, fire_create_invoice } = CreateInvoiceService(userId);

    useEffect(() => {
        if(result_patientRecent){
            if(result_patientRecent?.length>0){
                let invoiceDateFormat = DateStringAPI(new Date()) + "T" + timeFormat(new Date()) +"Z";

                setName(result_patientRecent[0].patient?.name + " " + result_patientRecent[0].patient?.surname);
                setGender(result_patientRecent[0]?.patient?.gender === 'm' ? "Male"
                : result_patientRecent[0]?.patient?.gender === 'f' ? "Female"
                : "");
                let dateInfo = dateStringFullMothName(result_patientRecent[0]?.start_time);
                setDate(dateInfo ? dateInfo : "");
                setUserId(result_patientRecent[0]?.patient?.id);
                setBookingId(result_patientRecent[0].id);
                setInvoiceDate(invoiceDateFormat);
            }
        }        
    }, [result_patientRecent, result_create_invoice]);

    return(
        <Box className="p-4">   
           <Grid container>
                <Grid item xs={12} md={4}>
                <Grid item xs={12} style={{ padding: "0 5px" }}>
                 
                        <Box
                            style={{position: "relative", overflow: "auto", height: '70vh',borderRadius: 5 }}
                            >
                                {loading_patientRecent && (
                                    <LinearProgress
                                        style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
                                    />
                                )} 
                            {(result_patientRecent || []).map((contactInfo : any, index) => {    
                                return ( 
                                <Box
                                    css={{ backgroundColor: "#fff", 
                                    width: "100%", 
                                    borderRadius: 20, 
                                    border: 'solid 2px #3f51b5',
                                    "&:hover": {
                                        backgroundColor: "rgba(3,169,244,0.08)",
                                        marginTop: '2%'
                                        }
                                    }}
                                    p={2}
                                    mb={1}
                                    mt={1}
                                    display="flex"
                                    onClick={() => {
                                        let invoiceDateFormat = DateStringAPI(new Date()) + "T" + timeFormat(new Date()) +"Z";
                                        setName(contactInfo?.patient?.name + " " + contactInfo?.patient?.surname);
                                        setGender(contactInfo?.patient?.gender === 'm' ? "Male"
                                        : contactInfo?.patient?.gender === 'f' ? "Female"
                                        : "");
                                        let dateInfo = dateStringFullMothName(contactInfo?.start_time);
                                        setDate(dateInfo ? dateInfo : "");
                                        setUserId(contactInfo?.patient?.id);
                                        setInvoiceNumber("");
                                        setBookingId(contactInfo?.id);
                                        setAmount("");
                                        setInvoiceDate(invoiceDateFormat);
                                        acceptedFiles.length = 0;
                                    }}
                                    key={index}
                                >
                                    <Box pr={4} pt={1}
                                    >
                                    <Avatar style={{ width: 50, height: 50, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                        {
                                            contactInfo?.patient?.avartar ? 
                                            <img src={contactInfo?.patient?.avartar} alt="" style={{height:'100%'}}/>
                                            :
                                            <PersonIcon style={{fontSize: '30px'}}/>
                                        }
                                    </Avatar>
                                    </Box>

                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box mb={2} position="relative">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                style={{ color: "#35353F", marginTop:'4px' }}
                                            >
                                                <Box fontSize={15} mr={1}>
                                                <span style={{fontWeight: 'bold'}}> {contactInfo?.patient?.name + " " + contactInfo?.patient?.surname} </span>  
                                                </Box>
                                            </Box>
                                                <Box fontSize={15} mr={1} mt={1}>
                                                  {dateStringFullMothName(contactInfo?.start_time)}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                                );
                            })}
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={8} style={{ padding: "0 5px" }}>
                        <Box fontSize={15} mr={1}>
                            <span style={{fontWeight: 'bold', paddingLeft: '25px'}}> Name: </span>  {name}
                        </Box>
                        <br/>
                        <Box fontSize={15} mr={1}>
                            <span style={{fontWeight: 'bold', paddingLeft: '25px'}}> Gender: </span>  
                            {genderInfo}
                        </Box>
                        <br/>
                        <Box fontSize={15} mr={1}>
                            <span style={{fontWeight: 'bold', paddingLeft: '25px'}}> Date: </span>  {date}
                        </Box>
                        
                        <br/>
                        <Box fontSize={15} mr={1}>
                            <span style={{fontWeight: 'bold', paddingLeft: '25px'}}> Invoice number: </span>
                            <TextField 
                                id="invoice number"
                                value={invoiceNumber} 
                                label=""  
                                margin="normal"
                                onChange={(e) => setInvoiceNumber(e.target.value)} 
                                style={{marginLeft: "10px", marginRight: "10px", marginTop: "-10px"}}
                            /> 
                        </Box>

                        <br/>
                        <Box fontSize={15} mr={1}>
                            <span style={{fontWeight: 'bold', paddingLeft: '25px'}}> Amount: </span>
                            <TextField 
                                id="amount"
                                value={amount} 
                                label=""  
                                margin="normal"
                                onChange={(e) => setAmount(e.target.value)} 
                                style={{marginLeft: "10px", marginRight: "10px", marginTop: "-10px"}}
                            /> 
                            <span style={{fontWeight: 'bold', paddingLeft: '2px'}}> Baht </span>
                        </Box>
                       
                        

                        <br/>
                            <p style={{fontWeight: 'bold', textAlign: 'center', fontSize: '20px'}}> Upload Invoices* </p> 
                            {/* <BasicDropZone/> */}
                            <section>
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    <p>Drag and drop files, or click to select files</p>
                                </div>
                                <aside>
                                    {
                                        files? 
                                        <>
                                            <ul style={{paddingLeft: '25px', color: '#111a79'}}>{files}</ul>
                                        </>
                                        :
                                        <></>
                                    }
                                
                                </aside>
                            </section>
                            <br/>
                            
                            {
                                acceptedFiles.length > 0 ? 
                                <>
                                    <Box
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 5,
                                            marginTop: '20px'
                                        }}
                                        onClick = {() => {
                                            if(invoiceNumber){
                                                fire_create_invoice({
                                                    file: acceptedFiles[0],
                                                },
                                                invoiceNumber,
                                                bookingId,
                                                invoiceDate,
                                                amount
                                                ).then(() => {
                                                    enqueueSnackbar(
                                                    "Inovice file have been uploaded successfully!",
                                                    { variant: "success" }
                                                    );
                                                    acceptedFiles.length = 0;
                                                    setInvoiceNumber("");
                                                    setAmount("");
                                                    
                                                })
                                                .catch((ex) => {
                                                    enqueueSnackbar("Upload Fail!:" + (ex ? " Please review all of information is correct or not" : "."), {
                                                    variant: "error",
                                                    });
                                                });
                                            }else{
                                                setShowMessage(true);
                                            }
                                            
                                        }}
                                    >
                                        <Button  variant="contained" color="primary"> Submit</Button>
                                    </Box>
                                </>
                                :
                                <>
                                    <Box
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: 5,
                                            marginTop: '20px'
                                        }}

                                        onClick= {()  => {
                                            setShowMessage(true);
                                        }}
                                    >
                                        <Button  variant="contained" color="default"> Submit</Button>
                                    </Box>
                                </>
                            }
                            
                            {
                                showMessage ? 
                                <Box
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: 5,
                                    marginTop: '20px'
                                }}
                            >
                                <p style={{color: 'red'}}> Please fill inovice number or Choose file. </p>
                            </Box>
                            :
                            <></>
                            }
                            
                </Grid>
            </Grid>
            
        </Box>
    );
});