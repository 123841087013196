import { CallTelemed } from "../../useCallAPI";
import { dateStrAPIFormat, dateStrFormat } from "../../../utils";
import { PharmacyData } from "../../../types/PharmacyData";
import { FormInput } from "./Finance";

export const GetPharmacy = (startDate: any, endDate: any, status: any, page?: any, rowsPerPage?: any, patientname?: any, changeState?: any) => {
    var start_date;
    var end_date;
    if(startDate){
        start_date = dateStrAPIFormat(startDate);
    }else{
        start_date = dateStrAPIFormat(new Date());
    };

    if(endDate){
        end_date = dateStrAPIFormat(endDate);
    }else{
        end_date =dateStrAPIFormat(new Date());
    }

    if(!page || page === 0){
      page = 1;
    }

    if(!rowsPerPage){
      rowsPerPage = 5;
    }

    if(!patientname){
      patientname = "";
    }

    const [{ data: res, loading, error }, fire] = CallTelemed<{
      data: PharmacyData;
        }>(
        {
            url:  `/v2/drug-deliveries/list`,
            method: "GET",
            params: {
                page: page,
                take: rowsPerPage,
                startDate: start_date,
                endDate: end_date,
                status: status,
                name: patientname,
                changeState: changeState
              },
        }
        );

        return {
        result_pharmacyData: res?.data ? res?.data?.datas : null,
        result_pharmacyMeta: res?.data?.meta ? res?.data?.meta : null,
        loading_pharmacyData: loading,
        error_pharmacyData: error
        };
}

export const UpdatePharmacyStatus = () => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
      data: any;
      code: 0 | 1;
      message: string;
    }>(
      {
        url: `/v2/drug-deliveries/status`,
        method: "PATCH",
      },
      { manual: true }
    );
    return {
      result_updatepharmacy: res?.data,
      loading_updatepharmacy: loading,
      error_updatepharmacy: error,
      fire_update_pharmacy: (
        id: any, status: number, trackingId?: any, address?: any, phoneNumber?: any, deliveryDate?: any
      ) => {
        let idArray: any = [];
        for(let i =0; i < id.length; i++){
          idArray.push(id[i].id?.toString());
        }
        let requestData = {};
        if(status === 2 ){
          requestData = {
              delivery_status: status,
              delivery_ids: idArray,
              tracking_id: trackingId? trackingId : "",
              delivery_date: deliveryDate? dateStrFormat(deliveryDate) : "",
              address: address? address : "",
              phone_no: phoneNumber? phoneNumber : ""
          }
        }else {
          requestData = {
            delivery_status: status,
            delivery_ids: idArray
        }
        }
        console.log("request data:", requestData);
        return new Promise((resolve, reject) => {
            fire({
              data: requestData,
            })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };


export const UploadPrescriptionFile = (data : any) => {
  const [{ data: res, loading, error }, fire] = CallTelemed<{
    data: any;
    code: 0 | 1;
    message: string;
  }>(
    {
      url: `/v2/drug-deliveries/${data?.id}/prescription`,
      method: "PATCH",
    },
    { manual: true }
  );
  return {
    result_uploadPrescription: res?.data,
    loading_uploadPrescription: loading,
    error_uploadPrescription: error,
    fire_uploadPrescription: (
      file: FormInput
    ) => {
      console.log("data: ", file);
      var formData = new FormData();
      formData.append("file", file.file, file.file.name);
      return new Promise((resolve, reject) => {
          fire({
            data: formData,
          })
        .then(({ data: { data, code, message } }) => {
          if (code === 0) {
            reject(message);
          } else {
            resolve(data);
          }
        })
        .catch((ex) => {
          reject(ex.toString());
        });
    });
    },
  };
};