import React from "react";
import {
  Box,
  Button,
  Container,
  Grid
} from "@material-ui/core";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import {  makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { SubBar } from "../Dashboard/SubBar";

export const MedicalCertificate = () =>{
    const onCapturePNG = () =>{
        htmlToImage.toPng(document.getElementById("saveAnotherPNG") as HTMLElement)
        .then(function (dataUrl) {
            const pdf = new jsPDF();
            var link = document.createElement('a');
            link.download = 'my-image-name.jpeg';
            const imgProps= pdf.getImageProperties(dataUrl);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            console.log("pdfHeight:", pdfHeight, imgProps.height);
            pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
            pdf.autoPrint({variant: 'non-conform'});
            pdf.save("Medical Certificate.pdf"); 
        });
        
      }
    
      return (
        <>
            <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16" >
                <SubBar title={"Medical Certificate"} back={true} reload={false}/>
                <br/>
                <Grid container id="saveAnotherPNG">
                    <Grid container style={{marginTop: '5%', marginLeft: '30px', fontSize: '18px'}}>
                        <Grid item xs={12}> 
                          <p style={{float : 'left', fontSize: '25px'}}> i don't know what to do 
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '20px' } }}
                              style={{marginLeft: '5px', width: 150}}
                            />
                            
                          </p>
                         </Grid>
                    </Grid>
                </Grid>
                <Box mt={8} pb={2} style={{textAlign: 'center'}}>
                <Grid container>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={4} xs={12}> 
                        <Button  variant="contained" color="primary" onClick={onCapturePNG} style={{marginRight: '10px'}}> 
                              Submit
                        </Button>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                </Grid>
                </Box>
            </Container>
              
        </>
      );
}
