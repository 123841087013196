import React from "react";
import {
  Box,
  LinearProgress,
} from "@material-ui/core";
import { webStore } from "../../stores/webStore";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { timeStr } from "../../utils";
import { UserPreviewData } from "../../types/UserPreviewData";
import { ResponsiveLineChart } from "../../components/ResponsiveLineChat";

export const OximeterGraph = observer(
  ({
    data: { loading, result_oximeter_history },
  }: {
    data: UserPreviewData;
  }) => {
    const { userId }: any = useParams();
    const GraphHeight = 300;
    const GraphColor = ["#4CCBEA", "#FF0000"];
    const GraphData = (canvas: any) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, GraphHeight);
      gradient.addColorStop(0, GraphColor[0]);
      gradient.addColorStop(1, `#DBF5FB`);
      return {
        datasets: [
          {
            data: (result_oximeter_history || []).map(
              ({ oxygen }) => oxygen
            ),
            borderColor: GraphColor[0],
            backgroundColor: gradient,
            fill: "start",
            // pointBackgroundColor: "transparent",
            // pointBorderColor: "transparent",
          },
          {
            label: "minimum standard",
            data: (result_oximeter_history || []).map(({ oxygen }) => 95),
            borderColor: GraphColor[1],
            fill: false,
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
            // backgroundColor: gradient2,
          },
        ],
        labels: (result_oximeter_history || []).map(({ timestamp }, i) =>
          i === 0 ? "" : timeStr(timestamp)
        ),
      };
    }; //
    const lineProps = {
      legend: { position: "bottom", display: false },
      options: {
        maintainAspectRatio: false,

        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMin: 0,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false,
              },
            },
          ],
        },
      },
      data: GraphData,
    };
    return (
      <Box css={{ borderRadius: 5, backgroundColor: "#fff", padding: 20 }}>
        {loading && (
          <LinearProgress
            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
          />
        )}
        <Box fontWeight={600} mb={2}>
          SPO<sub>2</sub>
        </Box>
        <ResponsiveLineChart
          {...{
            device: webStore.device,
            lineProps: lineProps,
            GraphHeight,
          }}
        />
      </Box>
    );
  }
);
