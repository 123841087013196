import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid
} from "@material-ui/core";
import { webStore } from "../../../stores/webStore";
import { observer } from "mobx-react-lite";
import CloseIcon from "@material-ui/icons/Close";
import { SubBar } from "../../Dashboard/SubBar";
import {
  withStyles,
  Theme,
} from '@material-ui/core/styles';
import {createMuiTheme} from "@material-ui/core/styles";
import DatePicker from 'sassy-datepicker';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useSnackbar } from "notistack";
import { CreateCalendar,GetCalenderByDoctor, UpdateCalendarBooking } from "../../../services/api/telemedicine/BookingService";
import { CalendarBookingModal } from "./CalendarBookingModal";

const CustomButton = withStyles((theme: Theme) => ({
  root: {
    // color: theme.palette.getContrastText('#3f51b5'),
    backgroundColor: '#fff',
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: '1px solid #e1dfdf',
    padding: '20px 60px ', 
    color: 'black', 
    fontSize: '16px',
    '&.Mui-selected' :{
      backgroundColor: '#0687f53d',
      border: '1px solid #4684e2',
    },
    '&.Mui-selected:hover':{
      backgroundColor: '#0687f53d'
    },
    '&.MuiToggleButtonGroup-groupedHorizontal' : {
      borderLeft: '1px solid #e1dfdf'
    },
    '&.Mui-disabled' : {
      color: '#00000070'
    }
  }
}))(ToggleButton);

export const Booking  = observer(() => {
  const [startDate, setStartDate] = useState<Date>(
        webStore.startDate || new Date()
      );
  const [endDate, setEndDate ] = useState<Date>(
    webStore.endDate || new Date()
  );
  const [chooseDate, setChooseDate ] = useState<Date>(new Date());
  const { enqueueSnackbar } = useSnackbar();

  const [isCalenderBooking, setIsCalenderBooking] = useState(false);
  const [ isUpdateBooking, setIsUpdateBooking ] = useState(false);
  const [ calenderBookingValue, setCalenderBookingValue ] = useState<any>();
  // const [ show]
  const [ formats, setFormats] = React.useState(() => []);
  const [ secondRowTime , setSecondRowTime ] = React.useState(() => []);
  const [ thirdRowTime , setThirdRowTime ] = React.useState(() => []);
  const [ fourthRowTime, setFourthRowTime ] = React.useState(() => []);
  const [ fifthRowTime, setFifthRowTime ] = React.useState(() => []);
  const [ sixRowTime, setSixRowTime ] = React.useState(() => []);
  const [ result, setResultArray ] = React.useState(()=> []);
  const [ type, setType ] = React.useState("1Day");

  const [ firstRow_buttonOne,  set_firstRow_buttonOne ] = useState(false);
  const [ firstRow_buttonTwo,  set_firstRow_buttonTwo ] = useState(false);
  const [ firstRow_buttonThree,  set_firstRow_buttonThree ] = useState(false);
  const [ firstRow_buttonFour,  set_firstRow_buttonFour ] = useState(false);
  const [ firstRow_buttonFive,  set_firstRow_buttonFive ] = useState(false);
  const [ firstRow_buttonSix,  set_firstRow_buttonSix ] = useState(false);

  const [ secondRow_buttonOne, set_secondRow_buttonOne ] = useState(false);
  const [ secondRow_buttonTwo, set_secondRow_buttonTwo ] = useState(false);
  const [ secondRow_buttonThree, set_secondRow_buttonThree ] = useState(false);
  const [ secondRow_buttonFour, set_secondRow_buttonFour ] = useState(false);
  const [ secondRow_buttonFive, set_secondRow_buttonFive ] = useState(false);
  const [ secondRow_buttonSix, set_secondRow_buttonSix ] = useState(false);

  const [ thirdRow_buttonOne, set_thirdRow_buttonOne ] = useState(false);
  const [ thirdRow_buttonSecond, set_thirdRow_buttonSecond ] = useState(false);
  const [ thirdRow_buttonThird, set_thirdRow_buttonThird ] = useState(false);
  const [ thirdRow_buttonFourth, set_thirdRow_buttonFourth ] = useState(false);
  const [ thirdRow_buttonFifth, set_thirdRow_buttonFifth ] = useState(false);
  const [ thirdRow_buttonSixth, set_thirdRow_buttonSixth ] = useState(false);

  const[ fourthRow_butonOne, set_fourthRowOne ] = useState(false);
  const[ fourthRow_butonTwo, set_fourthRowTwo ] = useState(false);
  const[ fourthRow_butonThird, set_fourthRowThird ] = useState(false);
  const[ fourthRow_butonFourth, set_fourthRowFourth ] = useState(false);
  const[ fourthRow_butonFifth, set_fourthRowFifth ] = useState(false);
  const[ fourthRow_butonSixth, set_fourthRowSixth ] = useState(false);

  const[ fifthRow_buttonOne, set_fifthRowOne ] = useState(false);
  const[ fifthRow_buttonTwo, set_fifthRowTwo ] = useState(false);
  const[ fifthRow_buttonThree, set_fifthRowThree ] = useState(false);
  const[ fifthRow_buttonFourth, set_fifthRowFourth ] = useState(false);
  const[ fifthRow_buttonFifth, set_fifthRowFifth ] = useState(false);
  const[ fifthRow_buttonSixth, set_fifthRowSixth ] = useState(false);

  const[ sixthRow_buttonOne, set_sixthRowOne ] = useState(false);
  const[ sixthRow_buttonTwo, set_sixthRowTwo ] = useState(false);
  const[ sixthRow_buttonThree, set_sixthRowThree ] = useState(false);
  const[ sixthRow_buttonFourth, set_sixthRowFourth ] = useState(false);
  const[ sixthRow_buttonFifth, set_sixthRowFifth ] = useState(false);
  const[ sixthRow_buttonSixth, set_sixthRowSixth ] = useState(false);

  const [ showUpdateButton, set_showUpdateButton ] = useState(false);

  
  const {fire_create_calendar, loading_create_calendar, error_create_calendar} = CreateCalendar();
  const { fire_update_calendarBooking, result_update_calendarBooking, loading_update_calendarBooking } = UpdateCalendarBooking();
  const { result_CalendarData, firstRow, secondRow, thirdRow, fourthRow, fifthRow, sixthRow, allBookingTimeData, idArray } = GetCalenderByDoctor(chooseDate);

  const handleFormat = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setFormats(newFormats);
  };

  const handleSecondRowTime = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setSecondRowTime(newFormats);
  };

  const handleThirdRowTime = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setThirdRowTime(newFormats);
  };

  const handleFourthRowTime = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setFourthRowTime(newFormats);
  };

  const handleFifthRowTime = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setFifthRowTime(newFormats);
  };

  const handleSixRowTime = (event: React.MouseEvent<HTMLElement>, newFormats: []) => {
    setSixRowTime(newFormats);
  };
  
  useEffect(() => {
    setFormats(firstRow);
    setSecondRowTime(secondRow);
    setThirdRowTime(thirdRow);
    setFourthRowTime(fourthRow);
    setFifthRowTime(fifthRow);
    setSixRowTime(sixthRow);

    if(allBookingTimeData.length > 0) {
        set_firstRow_buttonOne(true);
        set_firstRow_buttonTwo(true);
        set_firstRow_buttonThree(true);
        set_firstRow_buttonFour(true);
        set_firstRow_buttonFive(true);
        set_firstRow_buttonSix(true);
        set_secondRow_buttonOne(true);
        set_secondRow_buttonTwo(true);
        set_secondRow_buttonThree(true);
        set_secondRow_buttonFour(true);
        set_secondRow_buttonFive(true);
        set_secondRow_buttonSix(true);
        set_thirdRow_buttonOne(true);
        set_thirdRow_buttonSecond(true);
        set_thirdRow_buttonThird(true);
        set_thirdRow_buttonFourth(true);
        set_thirdRow_buttonFifth(true);
        set_thirdRow_buttonSixth(true);
        set_fourthRowOne(true);
        set_fourthRowTwo(true);
        set_fourthRowThird(true);
        set_fourthRowFourth(true);
        set_fourthRowFifth(true);
        set_fourthRowSixth(true);
        set_fifthRowOne(true);
        set_fifthRowTwo(true);
        set_fifthRowThree(true);
        set_fifthRowFourth(true);
        set_fifthRowFifth(true);
        set_fifthRowSixth(true);
        set_sixthRowOne(true);
        set_sixthRowTwo(true);
        set_sixthRowThree(true);
        set_sixthRowFifth(true);
        set_sixthRowFourth(true);
        set_sixthRowSixth(true);
    }
  }, [result_CalendarData]);

  const onChange = (date: any) => {
    setChooseDate(date);
    setStartDate(date);
    setEndDate(date);
    setFormats([]);
    setSecondRowTime([]);
    setThirdRowTime([]);
    setFourthRowTime([]);
    setFifthRowTime([]);
    setSixRowTime([]);
    set_firstRow_buttonOne(false);
    set_firstRow_buttonTwo(false);
    set_firstRow_buttonThree(false);
    set_firstRow_buttonFour(false);
    set_firstRow_buttonFive(false);
    set_firstRow_buttonSix(false);
    set_secondRow_buttonOne(false);
    set_secondRow_buttonTwo(false);
    set_secondRow_buttonThree(false);
    set_secondRow_buttonFour(false);
    set_secondRow_buttonFive(false);
    set_secondRow_buttonSix(false);
    set_thirdRow_buttonOne(false);
    set_thirdRow_buttonSecond(false);
    set_thirdRow_buttonThird(false);
    set_thirdRow_buttonFourth(false);
    set_thirdRow_buttonFifth(false);
    set_thirdRow_buttonSixth(false);
    set_fourthRowOne(false);
    set_fourthRowTwo(false);
    set_fourthRowThird(false);
    set_fourthRowFourth(false);
    set_fourthRowFifth(false);
    set_fourthRowSixth(false);
    set_fifthRowOne(false);
    set_fifthRowTwo(false);
    set_fifthRowThree(false);
    set_fifthRowFourth(false);
    set_fifthRowFifth(false);
    set_fifthRowSixth(false);
    set_sixthRowOne(false);
    set_sixthRowTwo(false);
    set_sixthRowThree(false);
    set_sixthRowFifth(false);
    set_sixthRowFourth(false);
    set_sixthRowSixth(false);

  };
  
  return (
    <>
        <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16" >
            <SubBar title={"Calendar Booking"} back={true} reload={false}/>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    {/* <Calendar date={new Date} onChange={(date) => {setStartDate(date ? new Date(date.toString()) : null)}}/> */}
                    <Box fontSize="h6.fontSize" fontWeight={500}>
                        Select Month <DateRangeIcon style={{fontSize: '25px'}}/>
                    </Box>
                    <br/>
                    <DatePicker onChange={onChange} style={{minWidth: "100%", minHeight: '50%'}} />
                    <br/>
                    <Box 
                        style={{
                            width: "100%",
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 5
                        }}
                    >
                        {
                          showUpdateButton ?
                          <Button variant="contained" color="primary"
                            onClick={()=> {
                              setResultArray([...formats, ...secondRowTime, ...thirdRowTime, ...fourthRowTime, ...fifthRowTime, ...sixRowTime] as any);
                              setIsCalenderBooking(true);
                              setIsUpdateBooking(true);
                            }}
                            > Update Calendar  </Button>
                          :
                          <Button variant="contained" color="primary"
                          onClick={()=> {
                            setResultArray([...formats, ...secondRowTime, ...thirdRowTime, ...fourthRowTime, ...fifthRowTime, ...sixRowTime] as any);
                            setIsCalenderBooking(true);
                            setIsUpdateBooking(false);
                          }}> Create Calendar  </Button>
                        }
                        
                    </Box>
                    <CalendarBookingModal
                      {...calenderBookingValue}
                      isCalenderBooking={isCalenderBooking}
                      setIsCalenderBooking={setIsCalenderBooking}
                      setCalenderBookingValue={setCalenderBookingValue}
                      startDate = {startDate}
                      setStartDate = {setStartDate}
                      endDate = {endDate}
                      setEndDate = {setEndDate}
                      dateArray={result}
                      label = {"Telemedicine"}
                      type = { type }
                      setType = { setType }
                      onClick={async () => {
                        if(isUpdateBooking) {
                          try {
                            fire_update_calendarBooking(result, startDate, endDate, allBookingTimeData, idArray)
                              .then(() => {
                                enqueueSnackbar("Success", {
                                  variant: "success",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                });
                                set_firstRow_buttonOne(true);
                                set_firstRow_buttonTwo(true);
                                set_firstRow_buttonThree(true);
                                set_firstRow_buttonFour(true);
                                set_firstRow_buttonFive(true);
                                set_firstRow_buttonSix(true);
                                set_secondRow_buttonOne(true);
                                set_secondRow_buttonTwo(true);
                                set_secondRow_buttonThree(true);
                                set_secondRow_buttonFour(true);
                                set_secondRow_buttonFive(true);
                                set_secondRow_buttonSix(true);
                                set_thirdRow_buttonOne(true);
                                set_thirdRow_buttonSecond(true);
                                set_thirdRow_buttonThird(true);
                                set_thirdRow_buttonFourth(true);
                                set_thirdRow_buttonFifth(true);
                                set_thirdRow_buttonSixth(true);
                                set_fourthRowOne(true);
                                set_fourthRowTwo(true);
                                set_fourthRowThird(true);
                                set_fourthRowFourth(true);
                                set_fourthRowFifth(true);
                                set_fourthRowSixth(true);
                                set_fifthRowOne(true);
                                set_fifthRowTwo(true);
                                set_fifthRowThree(true);
                                set_fifthRowFourth(true);
                                set_fifthRowFifth(true);
                                set_fifthRowSixth(true);
                                set_sixthRowOne(true);
                                set_sixthRowTwo(true);
                                set_sixthRowThree(true);
                                set_sixthRowFifth(true);
                                set_sixthRowFourth(true);
                                set_sixthRowSixth(true);
                                setIsCalenderBooking(false);
                                set_showUpdateButton(false);
                              })
                              .catch((ex) => {
                                enqueueSnackbar(
                                  "Error" +
                                    (ex ? " : " + ex.toString() : ""),
                                  {
                                    variant: "error",
                                    autoHideDuration: 3000,
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "center",
                                    },
                                  }
                                );
                                setIsCalenderBooking(false);
                              });
                          } catch (ex) {}
                        }else{
                          try {
                            fire_create_calendar(result, startDate, endDate, allBookingTimeData, type)
                              .then(() => {
                                enqueueSnackbar("Success", {
                                  variant: "success",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                });
                                setIsCalenderBooking(false);
                              })
                              .catch((ex) => {
                                enqueueSnackbar(
                                  "Error" +
                                    (ex ? " : " + ex.toString() : ""),
                                  {
                                    variant: "error",
                                    autoHideDuration: 3000,
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "center",
                                    },
                                  }
                                );
                                setIsCalenderBooking(false);
                              });
                          } catch (ex) {}
                        }
                        
                      }}/>
                </Grid>

                <Grid item xs={12} md={8}>
                    {/* <Calendar date={new Date} onChange={(date) => {setStartDate(date ? new Date(date.toString()) : null)}}/> */}
                    <Box fontSize="h6.fontSize" fontWeight={500}>
                        Select Time <AccessTimeIcon style={{fontSize: '25px'}}/>
                        {
                          showUpdateButton ? 
                            <Button
                            // color="primary"
                            onClick={() => {
                              setFormats(firstRow);
                              setSecondRowTime(secondRow);
                              setThirdRowTime(thirdRow);
                              setFourthRowTime(fourthRow);
                              setFifthRowTime(fifthRow);
                              setSixRowTime(sixthRow);
                              if(allBookingTimeData.length > 0) {
                                set_firstRow_buttonOne(true);
                                set_firstRow_buttonTwo(true);
                                set_firstRow_buttonThree(true);
                                set_firstRow_buttonFour(true);
                                set_firstRow_buttonFive(true);
                                set_firstRow_buttonSix(true);
                                set_secondRow_buttonOne(true);
                                set_secondRow_buttonTwo(true);
                                set_secondRow_buttonThree(true);
                                set_secondRow_buttonFour(true);
                                set_secondRow_buttonFive(true);
                                set_secondRow_buttonSix(true);
                                set_thirdRow_buttonOne(true);
                                set_thirdRow_buttonSecond(true);
                                set_thirdRow_buttonThird(true);
                                set_thirdRow_buttonFourth(true);
                                set_thirdRow_buttonFifth(true);
                                set_thirdRow_buttonSixth(true);
                                set_fourthRowOne(true);
                                set_fourthRowTwo(true);
                                set_fourthRowThird(true);
                                set_fourthRowFourth(true);
                                set_fourthRowFifth(true);
                                set_fourthRowSixth(true);
                                set_fifthRowOne(true);
                                set_fifthRowTwo(true);
                                set_fifthRowThree(true);
                                set_fifthRowFourth(true);
                                set_fifthRowFifth(true);
                                set_fifthRowSixth(true);
                                set_sixthRowOne(true);
                                set_sixthRowTwo(true);
                                set_sixthRowThree(true);
                                set_sixthRowFifth(true);
                                set_sixthRowFourth(true);
                                set_sixthRowSixth(true);
                              }
                              set_showUpdateButton(false);

                            }}
                            startIcon={<CloseIcon />}
                            size="small"
                            variant="text"
                            style={{float: 'right'}}
                          >
                            Close Edit Mode
                          </Button>
                        :
                          <Button
                            // color="primary"
                            onClick={() => {
                              set_firstRow_buttonOne(false);
                              set_firstRow_buttonTwo(false);
                              set_firstRow_buttonThree(false);
                              set_firstRow_buttonFour(false);
                              set_firstRow_buttonFive(false);
                              set_firstRow_buttonSix(false);
                              set_secondRow_buttonOne(false);
                              set_secondRow_buttonTwo(false);
                              set_secondRow_buttonThree(false);
                              set_secondRow_buttonFour(false);
                              set_secondRow_buttonFive(false);
                              set_secondRow_buttonSix(false);
                              set_thirdRow_buttonOne(false);
                              set_thirdRow_buttonSecond(false);
                              set_thirdRow_buttonThird(false);
                              set_thirdRow_buttonFourth(false);
                              set_thirdRow_buttonFifth(false);
                              set_thirdRow_buttonSixth(false);
                              set_fourthRowOne(false);
                              set_fourthRowTwo(false);
                              set_fourthRowThird(false);
                              set_fourthRowFourth(false);
                              set_fourthRowFifth(false);
                              set_fourthRowSixth(false);
                              set_fifthRowOne(false);
                              set_fifthRowTwo(false);
                              set_fifthRowThree(false);
                              set_fifthRowFourth(false);
                              set_fifthRowFifth(false);
                              set_fifthRowSixth(false);
                              set_sixthRowOne(false);
                              set_sixthRowTwo(false);
                              set_sixthRowThree(false);
                              set_sixthRowFifth(false);
                              set_sixthRowFourth(false);
                              set_sixthRowSixth(false);
                              set_showUpdateButton(true);
                            }}
                            startIcon={<BorderColorIcon />}
                            size="small"
                            variant="text"
                            style={{float: 'right'}}
                          >
                            Edit
                          </Button>
                        }
                    </Box>
                    <br/>
                    <Box 
                        style={{
                            width: "100%",
                            borderRadius: 5,
                        }}
                    >

                        <ToggleButtonGroup value={formats} onChange={handleFormat} aria-label="text formatting"
                        >
                          <CustomButton disabled={firstRow_buttonOne} value="07:00-07:15">
                            07:00<br/> - <br/>07:15
                          </CustomButton>
                          <CustomButton disabled={firstRow_buttonTwo} value="07:15-07:30">
                            07:15 <br/> - <br/>07:30
                          </CustomButton>
                          <CustomButton disabled={firstRow_buttonThree} value="07:30-07:45">
                            07:30<br/> - <br/>07:45
                          </CustomButton>
                          <CustomButton disabled={firstRow_buttonFour} value="07:45-08:00">
                            07:45 <br/>- <br/>08:00
                          </CustomButton>
                          <CustomButton disabled={firstRow_buttonFive} value="08:00-08:15">
                            08:00 <br/> - <br/>08:15
                          </CustomButton>
                          <CustomButton disabled={firstRow_buttonSix} value="08:15-08:30">
                            08:15 <br/> - <br/>08:30
                          </CustomButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup value={secondRowTime} onChange={handleSecondRowTime}>
                          <CustomButton disabled={secondRow_buttonOne} value="08:30-08:45">
                              08:30<br/> - <br/>08:45
                          </CustomButton>
                          <CustomButton disabled={secondRow_buttonTwo} value="08:45-09:00">
                              08:45 <br/> - <br/>09:00
                          </CustomButton>
                          <CustomButton disabled={secondRow_buttonThree} value="09:00-09:15">
                              09:00 <br/> - <br/>09:15
                          </CustomButton>
                          <CustomButton disabled={secondRow_buttonFour} value="09:15-09:30">
                              09:15 <br/> - <br/>09:30
                          </CustomButton>
                          <CustomButton disabled={secondRow_buttonFive} value="09:30-09:45">
                              09:30 <br/> - <br/>09:45
                          </CustomButton>
                          <CustomButton disabled={secondRow_buttonSix} value="09:45-10:00">
                             09:45 <br/> - <br/>10:00
                          </CustomButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup value={thirdRowTime} onChange={handleThirdRowTime}>
                          <CustomButton disabled={thirdRow_buttonOne} value="10:00-10:15">
                          10:00 <br/> - <br/>10:15
                          </CustomButton>
                          <CustomButton disabled={thirdRow_buttonSecond} value="10:15-10:30">
                          10:15<br/> - <br/>10:30
                          </CustomButton>
                          <CustomButton disabled={thirdRow_buttonThird} value="10:30-10:45">
                          10:30<br/>- <br/>10:45
                          </CustomButton>
                          <CustomButton disabled={thirdRow_buttonFourth} value="10:45-11:00">
                          10:45<br/> - <br/>11:00
                          </CustomButton>
                          <CustomButton disabled={thirdRow_buttonFifth} value="11:00-11:15">
                          11:00<br/> - <br/>11:15
                          </CustomButton>
                          <CustomButton disabled={thirdRow_buttonSixth} value="11:15-11:30">
                          11:15<br/> - <br/>11:30
                          </CustomButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup value={fourthRowTime} onChange={handleFourthRowTime}>
                          <CustomButton disabled={fourthRow_butonOne} value="11:30-11:45">
                          11:30<br/> - <br/>11:45
                          </CustomButton>
                          <CustomButton disabled={fourthRow_butonTwo} value="11:45-12:00">
                          11:45<br/> - <br/>12:00
                          </CustomButton>
                          <CustomButton disabled={fourthRow_butonThird} value="12:00-12:15">
                          12:00<br/> - <br/>12:15
                          </CustomButton>
                          <CustomButton disabled={fourthRow_butonFourth} value="12:15-12:30">
                          12:15<br/> - <br/>12:30
                          </CustomButton>
                          <CustomButton disabled={fourthRow_butonFifth} value="12:30-12:45">
                          12:30<br/> - <br/>12:45
                          </CustomButton>
                          <CustomButton disabled={fourthRow_butonSixth} value="12:45-13:00">
                          12:45<br/> - <br/>13:00
                          </CustomButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup value={fifthRowTime} onChange={handleFifthRowTime}>
                          <CustomButton disabled={fifthRow_buttonOne} value="13:00-13:15">
                          13:00<br/> - <br/>13:15
                          </CustomButton>
                          <CustomButton disabled={fifthRow_buttonTwo} value="13:15-13:30">
                          13:15<br/> - <br/>13:30
                          </CustomButton>
                          <CustomButton disabled={fifthRow_buttonThree} value="13:30-13:45">
                          13:30<br/> - <br/>13:45
                          </CustomButton>
                          <CustomButton disabled={fifthRow_buttonFourth} value="13:45-14:00">
                          13:45<br/> - <br/>14:00
                          </CustomButton>
                          <CustomButton disabled={fifthRow_buttonFifth} value="14:00-14:15">
                          14:00<br/> - <br/>14:15
                          </CustomButton>
                          <CustomButton disabled={fifthRow_buttonSixth} value="14:15-14:30">
                          14:15<br/> - <br/>14:30
                          </CustomButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup value={sixRowTime} onChange={handleSixRowTime}>
                          <CustomButton disabled={sixthRow_buttonOne} value="14:30-14:45">
                            14:30<br/> - <br/>14:45
                          </CustomButton>
                          <CustomButton disabled={sixthRow_buttonTwo} value="14:45-15:00">
                          14:45<br/> - <br/>15:00
                          </CustomButton>
                          <CustomButton disabled={sixthRow_buttonThree} value="15:00-15:15">
                          15:00<br/> - <br/>15:15
                          </CustomButton>
                          <CustomButton disabled={sixthRow_buttonFourth} value="15:15-15:30">
                          15:15<br/> - <br/>15:30
                          </CustomButton>
                          <CustomButton disabled={sixthRow_buttonFifth} value="15:30-15:45">
                          15:30<br/> - <br/>15:45
                          </CustomButton>
                          <CustomButton disabled={sixthRow_buttonSixth} value="15:45-16:00">
                          15:45<br/> - <br/>16:00
                          </CustomButton>
                        </ToggleButtonGroup>
                    </Box>
                    
                </Grid>
              </Grid>
        </Container>
          
    </>
  );
});