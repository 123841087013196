import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
    Box,
    FormControl,
    Button,
    Modal,
    Radio ,
    RadioGroup,
    FormControlLabel,
    FormLabel, 
    TextField,
    Grid
  } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "material-ui-thai-datepickers";
import LuxonUtils from "@date-io/luxon";

export const UpdateStatusModal = observer(
    ({
      isApproved,
      label,
      onClick,
      onClose,
      setIsApproved,
      setAddApprovedValue,
      buttonNameConfirm = "CONFIRM",
      buttonNameCANCEL = "CANCEL",
      isDelivery,
      status,
      trackingId,
      setTrackingId,
      address,
      setAddress,
      phoneNumber,
      setPhoneNumber,
      deliveryDate,
      setDeliveryDate,
      detailData,
      statusId,
      setStatusId
    }: {
      isApproved: boolean;
      setIsApproved: any;
      label: string;
      onClick?: any;
      onClose?: any;
      type?: string;
      setAddApprovedValue?: any;
      buttonNameConfirm?: string;
      buttonNameCANCEL?: string;
      isDelivery?: boolean;
      status?: string;
      trackingId?: string;
      setTrackingId?: any;
      address?: string;
      setAddress?: any;
      phoneNumber?: string;
      setPhoneNumber?: any;
      deliveryDate?: any;
      setDeliveryDate?: any;
      detailData?: any;
      statusId?: any;
      setStatusId?: any;
    }) => {
        const [value, setValue] = React.useState('delivered');

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setValue((event.target as HTMLInputElement).value);
          if((event.target as HTMLInputElement).value === 'delivered'){
            setStatusId(3);
          }else {
            setStatusId(4);
          }
        };

        useEffect(()=>{
          if(detailData){
            setAddress(detailData?.address);
            setPhoneNumber(detailData?.phone);
            setDeliveryDate(detailData?.date);
          }
        },[]);
  
      return (
        <Modal
          open={isApproved}
          onClose={() => {}}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <Box
                style={{ backgroundColor: "#fff", width: 400, borderRadius: 30, border: 'solid 2px #3f51b5' }}
                p={2}
            >
                <Box
                    display="flex"
                    justifyContent="right"
                    alignItems="center"
                    mb={1}
                    mt={1}
                >
                <Box onClick={onClose}>
                    <CloseIcon style={{ cursor: "pointer" }} />
                </Box>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center" 
                style={{ color: "#4B4C56" }}
            >
                <h1 style={{ color: "#0c1679", fontSize: "24px" }}> Update Status</h1>
                
            </Box>
            <Box
                className="mt-3"
                textAlign= "center"
            >
                 {
                     isDelivery ?
                     <>
                        <p style={{ color: "#0c1679", fontSize: "18px" }}>{label}</p> 
                        <br/>
                        <FormControl component="fieldset">
                        <FormLabel component="legend">Status will be updated to </FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                            <FormControlLabel value="delivered" control={<Radio size="small" color="primary" />} label="Delivered" />
                            <FormControlLabel value="completed" control={<Radio  size="small" color="primary" />} label="Completed" />
                        </RadioGroup>
                        </FormControl>
                     </>
                     :
                     <>
                        <p style={{ color: "#0c1679", fontSize: "18px" }}>{label}</p> 
                        <p className="mt-3" style={{ color: "#4B4C56", fontSize: "18px" }}>{"Status will be updated to '" +status + "'"}</p>
                        {
                          status === 'Delivery' ?
                          <>
                              <Box fontSize={15} mr={1} mt={4}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <span style={{fontWeight:'bold', paddingLeft: '25px'}}> Tracking Id: </span>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField 
                                          id="trackingid"
                                          value={trackingId} 
                                          label=""  
                                          margin="normal"
                                          onChange={(e) => setTrackingId(e.target.value)} 
                                          style={{marginLeft: "10px", marginRight: "10px", marginTop: "-10px"}}
                                        /> 
                                    </Grid>
                                </Grid>
                              </Box>

                              <Box fontSize={15} mr={1} mt={4}>
                                <Grid container>
                                  <Grid item xs={4}>
                                      <span style={{fontWeight:'bold', paddingLeft: '25px'}}> Address: </span>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <TextField 
                                      id="address"
                                      value={address} 
                                      label=""  
                                      margin="normal"
                                      onChange={(e) => setAddress(e.target.value)} 
                                      style={{marginLeft: "10px", marginRight: "10px", marginTop: "-10px"}}
                                    /> 
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box fontSize={15} mr={1} mt={4}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <span style={{fontWeight:'bold', paddingLeft: '25px'}}> Phone: </span>
                                    </Grid> 
                                    <Grid item xs={8}>
                                      <TextField 
                                          id="phoneNumber"
                                          value={phoneNumber} 
                                          label=""  
                                          margin="normal"
                                          onChange={(e) => setPhoneNumber(e.target.value)} 
                                          style={{marginLeft: "10px", marginRight: "10px", marginTop: "-10px"}}
                                        /> 
                                    </Grid>
                                </Grid>
                              </Box>

                              <Box fontSize={15} mr={1} mt={4}>
                                <Grid container>
                                      <Grid item xs={4}>
                                        <span style={{fontWeight:'bold'}}> Deliver Date: </span>
                                      </Grid> 
                                      <Grid item xs={8}>
                                        <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
                                          <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            value={deliveryDate || new Date()}
                                            onChange={(date: MaterialUiPickersDate) => {
                                                setDeliveryDate(date ? new Date(date.toString()) : null);
                                            }}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            autoOk= {true}
                                            yearOffset={543}
                                            style={{marginLeft: "20px", marginRight: "20px", marginTop: "-10px"}}
                                            />
                                      </MuiPickersUtilsProvider>
                                      </Grid>
                                </Grid>

                              </Box>
                              
                          </>
                          
                          :
                          <></>
                        }
                          
                     </>
                 }
                
                
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt ={2}
                >
                <Button
                    style={{margin: "10px"}}
                    onClick={onClick}
                    color="primary"
                    size="medium"
                    variant="contained"
                >
                    {buttonNameConfirm}
                </Button>

                <Button
                    style={{margin: "10px"}}
                    onClick={()=>{
                        setIsApproved(false);
                    }}
                    size="medium"
                    variant="contained"
                >
                    {buttonNameCANCEL}
                </Button>
                </Box>
                <br/>
          </Box>
        </Modal>
      );
    }
);