import { UserByGenderReport } from "../../../types/overviewReport";
import { useCallAPISpacetrax } from "../../useCallAPI";

export const useReadUserByGender = (endDate?: Date, startDate?: Date) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: {
      gender: UserByGenderReport;
    };
  }>({
    url: `/v2/dashboard/personals`,
    method: "GET",
    data: {
      startDate,
      endDate,
    },
  });
  return {
    result_user_by_gender: res?.data?.gender,
    loading_user_by_gender: loading,
    error_user_by_gender: error,
  };
};
