import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { dateStringFullMothName,dateStrFormatTime } from "../../../utils";
import { SubBar } from "../../Dashboard/SubBar";
import PersonIcon from '@material-ui/icons/Person';
import { GetBookingInfoById,PostponeBooking } from "../../../services/api/telemedicine/BookingService";  
import {
    GetUserProfile
  } from "../../../services/api/user/useReadUser";
import { useSnackbar } from "notistack";
import { PostponeModal } from "./PostPoneModal";
import { SuccessPostponeModal } from "./SuccessPostPoneModal";
import { AppointmentHistory } from "./AppointmentHistory";
import { HealthReport } from "./HealthReport";

export const ViewCase  = observer(() => {
  
    const { index, appointmentId }: any = useParams();
    const history = useHistory();
    const { result_getUserProfile,fire_userRead, loading_getUserProfile, ...rest } = GetUserProfile(index);
    const { result_bookingData, loading_bookingData } = GetBookingInfoById(appointmentId);
    const { loading_postponeBooking, fire_postponeBooking } = PostponeBooking();

    const [ isPostpone, setIsPostpone ] = useState(false);
    const [ postponeDate , setPostponeDate ] = useState<Date>(new Date());
    const [ postponeTime, setPostponeTime ] = useState("10:00");
    const [ postponeEndTime, setPostponeEndTime] = useState("10:15");
    const [ isSuccessPostpone, setIsSuccessPostpone ] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

  return (
    <>
    <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16" >
        <SubBar title={"View Case"} back={true} reload={false}/>
        <Box>
            <Box
            css={{ borderRadius: 5, backgroundColor: "#2B5698", color: "#fff" }}
            >
            <Box display={{ xs: "block", md: "flex" }}>
                <Box
                p={4}
                width={{ xs: "100%", md: "20%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                >
                    <Avatar style={{ width: 100, height: 100,  textAlign:'center'}}>
                        <PersonIcon style={{fontSize: '50px'}}/>
                    </Avatar>
                </Box>
                <Box py={4} px={6} width={{ xs: "100%", md: "40%" }}>
                    
                    <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Name:</p>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                result_bookingData ?
                                <>
                                  {result_bookingData?.patient.name + " " + result_bookingData?.patient.surname}
                                </>
                                :
                                <></>
                            }
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Date:</p>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                result_bookingData ?
                                <>
                                  {dateStringFullMothName(result_bookingData?.start_time)}
                                </>
                                :
                                <></>
                            }
                        </Grid>
                    </Grid>
                    
                    <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Start Time:</p>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                result_bookingData ?
                                <>
                                  {dateStrFormatTime(result_bookingData?.start_time)}
                                </>
                                :
                                <></>
                            }
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>End Time:</p>
                        </Grid>
                        <Grid item xs={6}>
                        {
                                result_bookingData ?
                                <>
                                  {dateStrFormatTime(result_bookingData?.end_time)}
                                </>
                                :
                                <></>
                            }
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={4}>
                            <p style={{fontWeight: 'bold'}}>Booking Type:</p>
                        </Grid>
                        <Grid item xs={6}>
                          {
                                result_bookingData ?
                                <>
                                  {result_bookingData?.appointment_type}
                                </>
                                :
                                <></>
                            }
                            
                        </Grid>
                    </Grid>
                    
                    <br/>
                    <Button  style={{textAlign: 'center'}}
                        variant="contained" color="secondary" size='small'
                        onClick = {() => {
                            setIsPostpone(true);
                        }}
                        > Postpone
                    </Button>

                    <PostponeModal
                      isPostpone = { isPostpone }
                      setIsPostpone = { setIsPostpone }
                      postponeDate = {postponeDate}
                      setPostponeDate = { setPostponeDate }
                      postponeTime = {postponeTime}
                      setPostponeTime = {setPostponeTime}
                      postponeEndTime = { postponeEndTime }
                      setPostponeEndTime = { setPostponeEndTime }
                      isSuccessPostpone = { isSuccessPostpone }
                      setIsSuccessPostpone = { setIsSuccessPostpone }
                      onClick = {async()=> {
                        try{
                          fire_postponeBooking(appointmentId,postponeDate, postponeTime, postponeEndTime )
                            .then(() => {
                              enqueueSnackbar("Success", {
                                variant: "success",
                                autoHideDuration: 3000,
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "center",
                                },
                              });
                              setIsSuccessPostpone(true);
                              setIsPostpone(false);
                            })
                            .catch((ex) => {
                              enqueueSnackbar(
                                "Error" +
                                  (ex ? " : " + ex.toString() : ""),
                                {
                                  variant: "error",
                                  autoHideDuration: 3000,
                                  anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                }
                              );
                              setIsPostpone(false);
                            });
                        }catch(ex) {

                        }
                        
                      }}
                    />

                    <SuccessPostponeModal
                        isSuccessPostpone = {isSuccessPostpone}
                        setIsSuccessPostpone = { setIsSuccessPostpone }
                        isPostpone = { isPostpone }
                        setIsPostpone = { setIsPostpone }
                      />
                </Box>
                <Box py={4} px={6} width={{ xs: "100%", md: "40%" }}>
                    <Box mb={2} position="relative">
                        <Box
                            display="flex"
                            alignItems="center"
                            css={{ marginTop:'4px' }}
                        >
                            <Box fontSize={15} mr={1}>
                            <p style={{fontWeight: 'bold'}}> Symptom case: </p>
                            </Box>
                        </Box>
                            <br/>
                            <Box fontSize={15} mr={1}>
                            {
                                result_bookingData ?
                                <>
                                  {result_bookingData?.symptom}
                                </>
                                :
                                <></>
                            }
                            </Box>
                            <br/>
                            <br/>
                            {
                              localStorage.getItem("userRole") === 'nurse' ?
                              <></>
                              :
                              <>
                                {
                                  result_bookingData?.appointment_type === 'VIDEO' && localStorage.getItem("userRole") === 'doctor' || localStorage.getItem("userRole") === 'admin' ?
                                    <Button  style={{textAlign: 'center', color : '#2b5698'}}
                                      variant="contained"
                                      onClick = {() => {
                                      history.push("/telemedicine/videocall/" + index + "/" + result_bookingData?.patient?.name + "/" + result_bookingData?.patient?.surname + '/' + result_bookingData?.video_channel?.channel_name + "/" + result_bookingData?.video_channel?.channel_type + "/" + appointmentId );
                                      }}
                                      > Start Video Call
                                    </Button>
                                    :
                                    <></>
                                }

                                {
                                  result_bookingData?.appointment_type !== 'VIDEO' && localStorage.getItem("userRole") === 'doctor' || localStorage.getItem("userRole") === 'admin' ?
                                    <Button  style={{textAlign: 'center', color : '#2b5698', marginLeft: '10px'}}
                                        variant="contained"
                                        onClick = {() => {
                                        history.push("/telemedicine/chat/" + index + "/" + result_bookingData?.patient?.name + "/" + result_bookingData?.patient?.surname + '/' + result_bookingData?.chat_channel?.channel_name + "/" + result_bookingData?.chat_channel?.channel_type + "/" + appointmentId);
                                        }}
                                        > Start Messaging
                                    </Button>
                                    :
                                    <></>
                                }   
                                  
                              </>
                            }
                            

                        </Box>
                    </Box>
            </Box>
            </Box>
        </Box>
        <br/>
        <HealthReport/>
        <br/>
        <AppointmentHistory/>
    </Container>
          
    </>
  );
});