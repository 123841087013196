import { Avatar, Box, Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UpdatePhuketUserQuarantineStatus } from '../../services/api/user/useReadUser';
import { webStore } from '../../stores/webStore';
import { Health, SmartInfirmaryUserList, Ward } from '../../types/user';
import { dateStrAPI, timeStr } from '../../utils';
import { ColorButton } from './CardUserListSI';
import { ConfirmStatusModal } from './ConfirmStatusModal';
import { DeviceValueTab } from './DeviceValueTab';

export const UserItemDischarge = observer(
    ({
      user,
      ward,
      health,
      borderTop,
      index,
      mapRefLat,
      quarantine_result_status,
      id
    }: {
      user: SmartInfirmaryUserList;
      ward?: Ward;
      health?: Health;
      index: number;
      borderTop?: boolean;
      mapRefLat?: String;
      quarantine_result_status: number;
      id: string;
    }) => {
      const { fire_quarantineStatus_update } = UpdatePhuketUserQuarantineStatus(id);
      const [isConfirm, setIsConfirm] = useState(false);
      const { enqueueSnackbar } = useSnackbar();
      let dropzoneStyle;
      const getNextResultText = (nextResult : any) =>{
        let next_result;
         if(nextResult === 2){
           next_result = "In Quarantine"
         }else if (nextResult === 3){
           next_result = "Discharge"
         }
         return next_result;
      }
  
      const getNextResult = (nextResult : any) =>{
        let next_result = "";
         if(nextResult === 2){
           next_result = "1"
         }else if (nextResult === 3){
           next_result = "4"
         }
         return next_result;
      }
  
      return (
        <>
          {(user?.onboard?.quarantine_result_status != 4 ?
            <Box
                className="relative"
                css={{
                  backgroundColor: "#fff",
                  borderRadius: 5,
                  cursor: "pointer",
                  "@media (max-width: 992px)": { marginBottom: 15 },
                }}
              >
                {
                  user ? 
                  <>
                
                  <Box
                  display="flex"
                  py={2}
                  px={{ xs: 2, md: 0 }}
                  borderTop={borderTop ? "1px solid #ddd" : 0}
                  style={dropzoneStyle}
                >
                  <Link to={"/user/" + user?.id + "/detail"} target="_blank">
                    <Box pr={2} pl={2}>
                      <Avatar css={{ width: 32, height: 32 }} src={user?.avatar}>
                        {user?.name.substring(0, 1)}
                      </Avatar>
                    </Box>
                  </Link>
                  
                  <Grid container>
                    <Grid item xs={12} md={8}>
                    <Link to={"/user/" + user?.id + "/detail"} target="_blank">
                      <Box mb={2} position="relative">
                        <Box
                          display="flex"
                          alignItems="center"
                          css={{ color: "#35353F" }}
                        >
                          <Box fontWeight={600} fontSize={14} mr={1}>
                            {`${user?.name} ${user.surname}`}
                          </Box>
                          
                          <Box position="relative">
                            
                          </Box>
                        </Box>
                        <Box fontSize={12} css={{ color: "#5B5B62" }}>
                          {`${
                            user.gender === "m"
                            ? "Male"
                            :user.gender === "M"
                            ? "Male"
                            :user.gender === "male"
                            ? "Male"
                            : user.gender === "f"
                            ? "Female"
                            : user.gender === "F"
                            ? "Female"
                            :user.gender === "female"
                            ? "Female"
                            : "-"
                          }
                      | ${user.nationality} | 
                      ${ user?.onboard?.hotel ? user?.onboard?.hotel : 
                        user?.onboard?.ward?.name_en? user?.onboard?.ward?.name_en :
                        "-"
                      }`}
                        </Box>
                        
                      </Box>
                    </Link>
                    </Grid>
                    <Grid item md={4}>
                      <Link to={"/user/" + user?.id + "/detail"} target="_blank">
                      <Box
                        fontSize={12}
                        textAlign="right"
                        css={{ color: "rgba(30, 30, 30, 0.5)"}}
                        style={{ paddingRight: "11px" }}
                        display={{ xs: "none", md: "block" }}
                      >
                        {user?.health.updated_at && dateStrAPI(user?.health.updated_at) + " " + timeStr(user?.health.updated_at)}
                      </Box>
                      </Link>
                    </Grid>
  
                    <Grid item md={6}>
                    <Link to={"/user/" + user?.id + "/detail"} target="_blank">
                      <Box display={{ xs: "none", md: "block" }} fontSize={12} css={{ color: "#5B5B62" }}>
                        
                        {
                          user?.identity_card?.personal_no ? 
                          <>
                              ID Card No. :{user?.identity_card?.personal_no} 
                          </>
                          : user?.passport?.passport_number ?
                          <>
                              Passport No. : {user?.passport?.passport_number}
                          </>
                          :
                          <>
                              Disease: {user?.health?.disease ? user?.health?.disease : "-"}
                          </>
                          
                        }
                      </Box>
                      </Link>
                    </Grid>
                    <Grid
                      md={6}
                      xs={10}
                      item
                      // spacing={2}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Grid container>
                        <Grid item md={6} xs={12}>
                            <DeviceValueTab device={user?.health}/>
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <ColorButton
                              variant="outlined"
                              color="primary"
                              style={{maxWidth: '100px', maxHeight: '50px', minWidth: '100px', minHeight: '50px', fontSize: '12px', float: 'right',marginBottom: '2%'}}
                              onClick={() => {
                                  setIsConfirm(true);
                              }}
                              >   
                              {getNextResultText(quarantine_result_status)}
                          </ColorButton>
                        </Grid> 
                        <Grid item md={3} xs={12}>
                        <Link to={"/user/" + user?.id + "/diagonoses/" + user?.onboard?.id} target="_blank">
                        
                          <ColorButton
                            variant="outlined"
                            color="primary"
                            style={{maxWidth: '100px', maxHeight: '50px', minWidth: '100px', minHeight: '50px', fontSize: '12px', float: 'right', marginBottom: '2%'}}
                            >   
                              View 
  
                            </ColorButton>
                          </Link>
                        </Grid>
                      </Grid>
                      <ConfirmStatusModal
                        isConfirm={isConfirm}
                        setIsConfirm={setIsConfirm}
                        status = {getNextResultText(quarantine_result_status)}
                        onClick={async () => {
                          
                          fire_quarantineStatus_update(
                            getNextResult(quarantine_result_status)
                          )
                            .then(({ data }: { data: any }) => {
                              webStore.set_filter_reload(true);
                              enqueueSnackbar(`Update Status Success`, {
                                key: "editSuccess",
                                variant: "success",
                                autoHideDuration: 3000,
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "right",
                                },
                              });
                            })
                            .catch((ex: any) => {
                              enqueueSnackbar("Update Status Failed", {
                                key: "editError",
                                variant: "error",
                                autoHideDuration: 3000,
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "right",
                                },
                              });
                            });
                            setIsConfirm(false);
                          
                        }}
                      />
  
                      
                    </Grid>
                  </Grid>
                </Box> 
  
                <Link to={"/user/" + user?.id + "/detail"} target="_blank">
                  <Box
                    display={{ xs: "flex", md: "none" }}
                    px={2}
                    py={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      fontSize={12}
                      textAlign="right"
                      pr= {2}
                      css={{ color: "rgba(30, 30, 30, 0.5)"}}
                    >
                      {user?.health.updated_at && dateStrAPI(user?.health.updated_at) + " " + timeStr(user?.health.updated_at)}
                    </Box>
                    
                  </Box>
                </Link>
          
  
          
  
          </>
          :
          
          <div
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              position: "absolute",
              opacity: "0.5",
            }}
          >
              There is no user info.
          </div>
          
          }
          
        </Box>      
          : "")}
        </>
      );
    }
  );