import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  InputBase,
  Tab,
  TextField
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { SubBar } from "../../Dashboard/SubBar";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
//   DatePicker
// } from "@material-ui/pickers";

import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from "material-ui-thai-datepickers";
import { Filter,DailyReportFilter } from "../../../types/user";
import { webStore } from "../../../stores/webStore";
import LuxonUtils from "@date-io/luxon";
import 'moment/locale/th';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import SearchIcon from "@material-ui/icons/Search";
import {  makeStyles, useTheme, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { dateStr, dateStrAPI, timeStr } from "../../../utils";
import { DailyReportList, DailyReport_List } from "../../../services/api/report/DailyReportList";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import axios, { AxiosRequestConfig, Method } from 'axios';
import {saveAs}  from 'file-saver';
import { useHistory } from "react-router-dom";
import { GetPatientData } from "../../../services/api/telemedicine/PatientServices";
import { PatientDataFilter } from "../../../types/Appointment";
import { ExportButton } from "../Pharmacy/Pharmacy";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%'
  },
  container: {
    maxHeight: 500,
  },
});


const UserFilter = observer(({ display = true }: { display?: boolean }) => {
  const [ filterKeyword, setFilterKeyword ] = useState<string | null> (webStore.filter_Keyword || "");
  const history = useHistory();

  useEffect(() => {
    webStore.set_filter_Keyword(filterKeyword);
  }, [
    filterKeyword
  ]);


  return (
    <Box mb={3}>
      {display && (
        <>
          <Box
          
            display="flex"
            py={2}
            css={{
              fontSize: 13,
              borderRadius: 5,
              backgroundColor: "#fff",
              "@media (max-width: 992px)": {
                flexFlow: "wrap",
                backgroundColor: "transparent",
              },
            }}
          >
            <Box
              px={2}
              mb={{ xs: 3, md: 0 }}
              borderRight="1px solid #ddd"
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
            </Box>
            <Box
              display="flex"
              borderRight="1px solid #ddd"
              px={2}
              mb={{ xs: 3, md: 0 }}
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
              <FormControl css={{ width: "100%" }}>
               
                <TextField
                  id="keyword"
                  label="Please enter Name or ID Card No."
                  value = { filterKeyword }
                  onChange= { (e)=>{
                     setFilterKeyword(e.target.value);
                  } }
                />
              </FormControl>
            </Box>
            
            <Box
              borderRight="1px solid #ddd"
              display="flex"
              px={2}
              mb={{ xs: 3, md: 0 }}
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                },
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  console.log("search");
                }}
                css={{
                  padding: 8,
                  minWidth: "unset",
                  boxShadow: "unset",
                  marginRight: '2%'
                }}
              >
                <SearchIcon />
              </Button>
              <Button
                  variant="contained"
                  color="primary"
                  css={{ padding:8, fontSize: 12, marginRight: '2%' }}
                  onClick={() => {
                    webStore.set_filter_Keyword(null);
                    setFilterKeyword("");
                  }}
                >
                  Clear
              </Button>
              <ExportButton
                  style= {{marginLeft: "3%", fontSize : '12px', float: 'right'}}
                  variant="contained"
                  color="primary" disableRipple 
                  css={{ padding:8, color:'#fff' }}
                  onClick={() => {
                      history.push("/telemedicine/importUser");
                  }}
              >
                  UPLOAD
              </ExportButton>
            </Box>

            <Box
              
              px={2}
              mb={{ xs: 3, md: 0 }}
              display="flex"
              justifyContent="center"
              width="100%"
              css={{
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "50%",
                  borderWidth: 0,
                },
              }}
            >
    
            </Box>

          </Box>
          <Box textAlign="right">
            
          </Box>
        </>
      )}
    </Box>
  );
});

export const UserListTelemed = observer((props: any) => {
  const classes = useStyles();
  useEffect(() => {
    return () => {
      webStore.resetDailyReportFilter();
    };
  }, []);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  
  const filter : PatientDataFilter = {
    filter_keyword : webStore.filter_Keyword,
    page: page,
    perPage: rowsPerPage,
  };

  const { result_patientListData, loading_patienListtData } = GetPatientData(filter);
  
  return (
    <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16">
      <Box mb={2}>
        <Grid item xs={12}>
          <SubBar title={"Patient List"} reload={false} back={true}  />
        </Grid>
      </Box>
      <Grid item xs={12} sm={12}>
        <Box display={{ xs: "block", md: "block" }}>
          <UserFilter/>
        </Box>
      </Grid>
      
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { backgroundColor: "transparent" },
          height: "100%",
        }}
      >
           <Paper className={classes.root}>
           {loading_patienListtData && (
                <LinearProgress
                  style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
                />
              )}
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{width: '250px'}}>Name</TableCell>
                        <TableCell>Phone No.</TableCell>
                        <TableCell>Id Card No.</TableCell>
                        <TableCell>Gender</TableCell>
                        <TableCell>Disease</TableCell>
                        <TableCell>Admitted Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    { result_patientListData?.length === 0 ? <TableRow>
                        <TableCell colSpan={4}>There is no data.</TableCell>
                       </TableRow> : <></> 
                    }
                    { result_patientListData?.map((userInfo) => (
                      <TableRow key={userInfo.id}>
                        <TableCell> {userInfo.name  + " " + userInfo.last_name} </TableCell>
                        <TableCell> {userInfo.phone} </TableCell>
                        <TableCell>{userInfo.identity_number}</TableCell>
                        <TableCell> {userInfo.gender === 'm' || userInfo.gender === 'M' ? "Male" : "Female"} </TableCell>
                        <TableCell>{userInfo.disease}</TableCell>
                        <TableCell> {dateStrAPI(userInfo.date_admitted)} </TableCell>
                      </TableRow>
                      
                    ))}
                        
                    </TableBody>
                  </Table>
                </TableContainer>
                
                {/* <Box mt={2}>
                  
                  <Pagination
                    count={pageCount}
                    page={filter?.page || 1}
                    onChange={handleChange}
                    size="small"
                  />
                  <FormControl style={{float:'right', marginTop: '-36px', marginRight: '2%'}} >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rowsPerPage}
                      onChange={handleChangeRowsPerPage}
                    >
                      <MenuItem value={10}>10/ per page</MenuItem>
                      <MenuItem value={25}>25/ per page</MenuItem>
                      <MenuItem value={50}>50/ per page</MenuItem>
                      <MenuItem value={100}>100/ per page</MenuItem>
                      
                    </Select>
                  </FormControl>
                </Box> */}
                <br/>
              </Paper>


      </Box>
      

    </Container>
  );
});