import { Filter, User, UserFromAPI,dailyReport,UserFromAPIUpdate,MentalReport,
   UpdateStatusData,CreateNoteData,ContactTracerInfo, UserProfile } from "../../../types/user";
import _ from "lodash";
import { callAPI, useCallAPISpacetrax } from "../../useCallAPI";
import { DateTime } from "luxon";
import { dateStrAPI, DateString } from "../../../utils";
import { useHistory } from "react-router-dom";
import {
  BatteryHistoryReport,
  BloodPressureHistoryReport,
  HeartRateHistoryReport,
  TempHistoryReport,
  WearingHistoryReport,
  LocationsReport,
  OximeterHistoryReport,
  BreathHistoryReport,
  BloodSugarHistoryReport
} from "../../../types/personalReport";

import  BlueUserIcon  from '../../../components/assets/BlueUserIcon.jpg';
import  RedUserIcon  from '../../../components/assets/RedUserIcon.jpg';

let imei_Id :any; 
export const processServerUser = (serverUser: UserFromAPI) => {
  // serverUser.user.avatar = "https://material-ui.com/static/images/avatar/1.jpg";
  if(serverUser.user){
    serverUser.user.isSos = false;
    serverUser.user.yatch_name = serverUser.place?.name_th || "";
    serverUser.user.length_of_stay = serverUser.length_of_stay;
    serverUser.user.quarantine_start = serverUser.user.quarantine_start
      ? new Date(serverUser.user.quarantine_start)
      : new Date();
    serverUser.user.quarantine_end = serverUser.user.quarantine_end
      ? new Date(serverUser.user.quarantine_end)
      : new Date();
      let device;
      if (serverUser.device) {
        device = serverUser.device;
      } else if (serverUser.devices) {
        device = _.last(serverUser.devices);
      }
    
      if (serverUser.devices) {
        serverUser.user.total_step = _.sumBy(serverUser.devices, "step");
      } else if (serverUser.device) {
        serverUser.user.total_step = serverUser.device.step;
      } else {
        serverUser.user.total_step = "?";
      }
    
      if (device) {
        serverUser.user.imei = device.imei;
        serverUser.user.latest_device_status =
          device.device_status > 0 ? true : false;
        serverUser.user.latest_battery = device.battery;
        imei_Id = device.imei;
      }
    
      if (device && device.heart_rate) {
        serverUser.user.latest_heart_rate = device.heart_rate;
      } else if (serverUser.heart_rate && serverUser.heart_rate[0]) {
        serverUser.user.latest_heart_rate = serverUser.heart_rate[0].heart_rate;
      } else {
        serverUser.user.latest_heart_rate = "?";
      }
    
      if (device && device.body_temp) {
        serverUser.user.latest_body_temp = device.body_temp;
      } else if (serverUser.temperature && serverUser.temperature[0]) {
        serverUser.user.latest_body_temp = serverUser.temperature[0].body_temp;
      } else {
        serverUser.user.latest_body_temp = "?";
      }
    
      if (device && device.latitude >= 0 && device.longitude >= 0) {
        serverUser.user.latest_lat = device.latitude;
        serverUser.user.latest_lng = device.longitude;
      } else if (serverUser.locations && serverUser.locations[0]) {
        serverUser.user.latest_lat = serverUser.locations[0].latitude;
        serverUser.user.latest_lng = serverUser.locations[0].longitude;
      } else {
        serverUser.user.latest_lat = 0.1;
        serverUser.user.latest_lng = 0.1;
      }
    
      if (device && device.blood_systolic && device.blood_diastolic) {
        serverUser.user.latest_blood_pressure =
          device.blood_systolic + "/" + device.blood_diastolic;
      } else if (serverUser.blood_pressure && serverUser.blood_pressure[0]) {
        serverUser.user.latest_blood_pressure =
          serverUser.blood_pressure[0].blood_systolic +
          "/" +
          serverUser.blood_pressure[0].blood_diastolic;
      } else {
        serverUser.user.latest_blood_pressure = "?";
      }
      if (!serverUser.user.latest_blood_pressure) {
        serverUser.user.latest_blood_pressure = "";
      }
      serverUser.user.timeStamp = new Date(serverUser.updated_at);
    
      let lastDevice = _.last(serverUser.devices);
      if (lastDevice) {
        serverUser.user.latest_device_timeStamp = new Date(lastDevice.updated_at);
      }
    
      serverUser.user.date_start = serverUser.date_start;
      serverUser.user.date_end = serverUser.date_end;
    
  }


   return serverUser.user;
};

export const callReadUser = (
  userId: string,
  endDate?: Date,
  startDate?: Date,
  filterHour?: string
) => {
  return new Promise<User>((resolve, reject) => {
    callAPI({
      url: `/api/v1/dashboard/quarantines/users/${userId}`,
      method: "GET",
      data: {
        userId,
      },
      params: {
        start_date: startDate,
        end_date: endDate,
        filter_hour: filterHour,
      },
    }).then((res: { data: { data: UserFromAPI } }) => {
      if (!res) {
        reject();
      } else {
        resolve(processServerUser(res?.data.data));
      }
    });
  });
};

export type ReadUserFilter = {
  endDate: Date | string | null;
  startDate?: Date | string | null;
  filterHour?: number | null;
};
export const useReadUser = (
  userId: string,
  filter: ReadUserFilter = {
    endDate: new Date(),
    startDate: new Date(),
  },
  manual = false
) => {
  if (filter.endDate && typeof filter.endDate !== "string") {
    filter.endDate = dateStrAPI(filter.endDate);
  }
  if (filter.startDate && typeof filter.startDate !== "string") {
    filter.startDate = dateStrAPI(filter.startDate);
  }
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: UserFromAPI;
  }>(
    {
      // url: `/api/v1/dashboard/quarantines/users/${userId}`,
      url: `/v2/dashboard/user/${userId}`,
      method: "GET",
      data: {
        userId,
      },
      params: {
        start_date: filter.startDate,
        end_date: filter.endDate,
        filter_hour: filter.filterHour,
      },
    },
    {
      manual,
    }
  );
  
  const result_userRead_raw = res?.data;
  let result_battery_history: null | BatteryHistoryReport = null;
  if (result_userRead_raw) {
    result_battery_history = _.sortBy(
      (result_userRead_raw.batteries || []).map(({ battery, created_at }) => {
        const timestamp = new Date(created_at);
        const diff = DateTime.fromJSDate(new Date()).diff(
          DateTime.fromJSDate(new Date(created_at)),
          "days"
        ).days;

        return {
          battery: battery,
          length_of_stay: Math.floor(diff),
          timestamp: timestamp,
        };
      }),
      "timestamp","length_of_stay"
    );
  }
  let result_blood_pressure_history: null | BloodPressureHistoryReport = null;
  if (result_userRead_raw) {
    result_blood_pressure_history = _.sortBy(
      (result_userRead_raw.blood_pressure || []).map(
        ({ blood_systolic, blood_diastolic, device_id, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            blood_pressure: blood_systolic + "/" + blood_diastolic,
            blood_systolic: blood_systolic,
            blood_diastolic: blood_diastolic,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_heart_rate_history: null | HeartRateHistoryReport = null;
  if (result_userRead_raw) {
    result_heart_rate_history = _.sortBy(
      (result_userRead_raw.heart_rate || []).map(
        ({ heart_rate, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            heart_rate: heart_rate,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_temp_history: null | TempHistoryReport = null;
  if (result_userRead_raw) {
    result_temp_history = _.sortBy(
      (result_userRead_raw.temperature || []).map(
        ({ body_temp, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            body_temp: body_temp,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_wearing_history: null | WearingHistoryReport = null;
  if (result_userRead_raw) {
    result_wearing_history = _.sortBy(
      (result_userRead_raw.device_status || []).map(
        ({ device_status, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            device_status: device_status,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_locations_history: null | LocationsReport = null;
  if (result_userRead_raw) {
    result_locations_history = _.sortBy(
      (result_userRead_raw.locations || []).map(
        ({ latitude, longitude, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            latitude: latitude,
            longitude: longitude,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_oximeter_history: null | OximeterHistoryReport = null;
  if (result_userRead_raw) {
    result_oximeter_history = _.sortBy(
      (result_userRead_raw.oximeter || []).map(
        ({ oxygen, created_at, BTUtcTime }) => {
          const timestamp = new Date(BTUtcTime);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(BTUtcTime)),
            "days"
          ).days;

          return {
            oxygen: oxygen,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  return {
    result_userRead: res?.data ? processServerUser(res?.data) : null,
    result_battery_history,
    result_blood_pressure_history,
    result_heart_rate_history,
    result_temp_history,
    result_wearing_history,
    result_locations_history,
    result_oximeter_history,
    result_userRead_raw: res?.data,
    loading_userRead: loading,
    fire_userRead: fire,
  };
};

export const CallDailyReports = () => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: dailyReport;
  }>
  ({
    url: `/v2/dashboard/user/${imei_Id}/daily-list`,
    method: "GET",
    data: {
      imei_Id,
    },
  });
  console.log("imei id : ", imei_Id);
  console.log("daily report list:" , res);
  return {
    result_dailyReport: res?.data ? res?.data : [],
    loading_dailyReport: loading,
    error_dailyReport: error,
  };
};

export const CallDailyReportsIsolation = (userId: string) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: dailyReport;
  }>
  ({
    url: `/v2/dashboard/user/${userId}/daily-list`,
    method: "GET",
    data: {
      userId,
    },
  });
  return {
    result_dailyReport: res?.data ? res?.data : [],
    loading_dailyReport: loading,
    error_dailyReport: error,
  };
};

export const CallMentalReportsIsolation = (userId: string) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: MentalReport;
  }>
  ({
    url: `/v2/dashboard/user/${userId}/mental-list`,
    method: "GET",
    data: {
      userId,
    },
  });
  console.log("mental report list isolation:" , res);
  return {
    result_metalReport: res?.data ? res?.data : [],
    loading_mentalReport: loading,
    error_mentalReport: error,
  };
};

export const useReadUserUpdate = (
  userId: string,
  filter: ReadUserFilter = {
    endDate: new Date(),
    startDate: new Date(),
  },
  manual = false
) => {
  if (filter.endDate && typeof filter.endDate !== "string") {
    filter.endDate = dateStrAPI(filter.endDate);
  }
  if (filter.startDate && typeof filter.startDate !== "string") {
    filter.startDate = dateStrAPI(filter.startDate);
  }
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: UserFromAPIUpdate;
  }>(
    {
      // url: `/api/v1/dashboard/quarantines/users/${userId}`,
      url: `/v2/dashboard/user/${userId}`,
      method: "GET",
      data: {
        userId,
      },
      params: {
        start_date: filter.startDate,
        end_date: filter.endDate,
        filter_hour: filter.filterHour,
      },
    },
    {
      manual,
    }
  );
  
  const result_userRead_raw = res?.data;
  let result_battery_history: null | BatteryHistoryReport = null;
  if (result_userRead_raw) {
    result_battery_history = _.sortBy(
      (result_userRead_raw.battery || []).map(({ battery, created_at }) => {
        const timestamp = new Date(created_at);
        const diff = DateTime.fromJSDate(new Date()).diff(
          DateTime.fromJSDate(new Date(created_at)),
          "days"
        ).days;

        return {
          battery: battery,
          length_of_stay: Math.floor(diff),
          timestamp: timestamp,
        };
      }),
      "timestamp","length_of_stay"
    );
  }
  let result_blood_pressure_history: null | BloodPressureHistoryReport = null;
  if (result_userRead_raw) {
    result_blood_pressure_history = _.sortBy(
      (result_userRead_raw.blood_pressure || []).map(
        ({ blood_systolic, blood_diastolic, device_id, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            blood_pressure: blood_systolic + "/" + blood_diastolic,
            blood_systolic: blood_systolic,
            blood_diastolic: blood_diastolic,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_heart_rate_history: null | HeartRateHistoryReport = null;
  if (result_userRead_raw) {
    result_heart_rate_history = _.sortBy(
      (result_userRead_raw.heart_rate || []).map(
        ({ heart_rate, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            heart_rate: heart_rate,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_temp_history: null | TempHistoryReport = null;
  if (result_userRead_raw) {
    result_temp_history = _.sortBy(
      (result_userRead_raw.temperature || []).map(
        ({ body_temp, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            body_temp: body_temp,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_wearing_history: null | WearingHistoryReport = null;
  if (result_userRead_raw) {
    result_wearing_history = _.sortBy(
      (result_userRead_raw.wearing_status || []).map(
        ({ device_status, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;
          return {
            device_status: device_status,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_locations_history: null | LocationsReport = null;
  if (result_userRead_raw) {
    result_locations_history = _.sortBy(
      (result_userRead_raw.locations || []).map(
        ({ latitude, longitude, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;
          const location = "( " + latitude.toFixed(4) +", "+ longitude.toFixed(4) + " )";

          return {
            latitude: latitude,
            longitude: longitude,
            location: location,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_oximeter_history: null | OximeterHistoryReport = null;
  if (result_userRead_raw) {
    result_oximeter_history = _.sortBy(
      (result_userRead_raw.oximeter || []).map(
        ({ oxygen, created_at, BTUtcTime }) => {
          const timestamp = new Date(created_at);
          // const timestamp = BTUtcTime;
          // const timestamp = dateStrAPI(BTUtcTime) + " " + timeStr(BTUtcTime);
          // console.log("timestamp:", timestamp);
          // console.log("timestr:" , timeStr(BTUtcTime));
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            oxygen: oxygen,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }
  if(!res?.data){
    imei_Id = "";
  }
  return {
    result_userRead: res?.data ? processServerUserUpdate(res?.data) : null,
    result_battery_history,
    result_blood_pressure_history,
    result_heart_rate_history,
    result_temp_history,
    result_wearing_history,
    result_locations_history,
    result_oximeter_history,
    result_userRead_raw: res?.data,
    loading_userRead: loading,
    fire_userRead: fire,
  };
};

export const processServerUserUpdate = (serverUser: UserFromAPIUpdate) => {
  // serverUser.user.avatar = "https://material-ui.com/static/images/avatar/1.jpg";
  if(serverUser.user){
    serverUser.user.isSos = false;
    serverUser.user.yatch_name = serverUser.place?.name_th || "";
    serverUser.user.length_of_stay = serverUser.length_of_stay;
    serverUser.user.quarantine_start = serverUser.user.quarantine_start
      ? new Date(serverUser.user.quarantine_start)
      : new Date();
    serverUser.user.quarantine_end = serverUser.user.quarantine_end
      ? new Date(serverUser.user.quarantine_end)
      : new Date();
      let device; 
      if (serverUser.device) {
        if(Array.isArray(serverUser.device)){
          device = serverUser.device[0];
        }else{
          device = serverUser.device;
        }
      } else if (serverUser.devices) {
        device = _.last(serverUser.devices);
      }
    
      if (serverUser.devices) {
        serverUser.user.total_step = _.sumBy(serverUser.devices, "step");
      } else if (serverUser.device) {
        // serverUser.user.total_step = serverUser.device.step;
        serverUser.user.total_step = _.sumBy(serverUser.device, "step");
      } else {
        serverUser.user.total_step = "?";
      }
    
      if (device) {
        serverUser.user.imei = device.imei;
        serverUser.user.latest_device_status =
          device.device_status > 0 ? true : false;
        serverUser.user.latest_battery = device.battery;
        imei_Id = device.imei;
      }
    
      if (device && device.heart_rate) {
        serverUser.user.latest_heart_rate = device.heart_rate;
      } else if (serverUser.heart_rate && serverUser.heart_rate[0]) {
        serverUser.user.latest_heart_rate = serverUser.heart_rate[0].heart_rate;
      } else {
        serverUser.user.latest_heart_rate = "?";
      }
    
      if (device && device.body_temp) {
        serverUser.user.latest_body_temp = device.body_temp;
      } else if (serverUser.temperature && serverUser.temperature[0]) {
        serverUser.user.latest_body_temp = serverUser.temperature[0].body_temp;
      } else {
        serverUser.user.latest_body_temp = "?";
      }
    
      if (device && device.latitude >= 0 && device.longitude >= 0) {
        serverUser.user.latest_lat = device.latitude;
        serverUser.user.latest_lng = device.longitude;
      } else if (serverUser.locations && serverUser.locations[0]) {
        serverUser.user.latest_lat = serverUser.locations[0].latitude;
        serverUser.user.latest_lng = serverUser.locations[0].longitude;
      } else {
        serverUser.user.latest_lat = 0.1;
        serverUser.user.latest_lng = 0.1;
      }
    
      if (device && device.blood_systolic && device.blood_diastolic) {
        serverUser.user.latest_blood_pressure =
          device.blood_systolic + "/" + device.blood_diastolic;
      } else if (serverUser.blood_pressure && serverUser.blood_pressure[0]) {
        serverUser.user.latest_blood_pressure =
          serverUser.blood_pressure[0].blood_systolic +
          "/" +
          serverUser.blood_pressure[0].blood_diastolic;
      } else {
        serverUser.user.latest_blood_pressure = "?";
      }
      if (!serverUser.user.latest_blood_pressure) {
        serverUser.user.latest_blood_pressure = "";
      }
      serverUser.user.timeStamp = new Date(serverUser.updated_at);
    
      let lastDevice = _.last(serverUser.devices);
      if (lastDevice) {
        serverUser.user.latest_device_timeStamp = new Date(lastDevice.updated_at);
      }
    
      serverUser.user.date_start = serverUser.date_start;
      serverUser.user.date_end = serverUser.date_end;
    
  }


   return serverUser.user;
};

export const UpdateQuarantineStatus = (id: string) => {
  const history = useHistory();
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: UpdateStatusData
  }>(
    {
      url: `/v2/dashboard/user/quarantine/${id}`,
      method: "PATCH",
    },
    { manual: true }
  );
  return {
    result_quarantineStatus_update: res?.data,
    loading_quarantineStatus_update: loading,
    error_quarantineStatus_update: error,
    fire_quarantineStatus_update: (
      // room_number: string,
      // bed_number: string, 
      quarantine_result_status: string,
      remark?: string
    ) => {
    var formData = new FormData();
      // formData.append('room_number', room_number.toString());   //append the values with key, value pair
      // formData.append('bed_number', bed_number.toString());
      formData.append('quarantine_result_status', quarantine_result_status);
      if(remark){
        formData.append('remark', remark);
      }else{
        formData.append('remark', "");
      }
    return fire({
      data: formData
    });
    },
  };
};

export const UpdatePhuketUserQuarantineStatus = (id: string) => {
  const history = useHistory();
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: UpdateStatusData
  }>(
    {
      url: `/v2/dashboard/user/onboard/${id}`,
      method: "PATCH",
    },
    { manual: true }
  );
  return {
    result_quarantineStatus_update: res?.data,
    loading_quarantineStatus_update: loading,
    error_quarantineStatus_update: error,
    fire_quarantineStatus_update: (
      // room_number: string,
      // bed_number: string, 
      quarantine_result_status: string,
      remark?: string
    ) => {
    var formData = new FormData();
      formData.append('quarantine_result_status', quarantine_result_status);
      if(remark){
        formData.append('remark', remark);
      }else{
        formData.append('remark', "");
      }
    return fire({
      data: formData
    });
    },
  };
};

export const CreateNote = (id: any) => {
  const history = useHistory();
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: CreateNoteData
  }>(
    {
      url: `/v2/dashboard/user/note/${id}`,
      method: "POST",
    },
    { manual: true }
  );
  return {
    result_createNote: res?.data,
    loading_createNote: loading,
    error_createNote: error,
    fire_createNote: (
      note: string
    ) => {
    var formData = new FormData();
      formData.append('note', note);
    return fire({
      data: formData
    });
    },
  };
};

// https://api.space-trax.com/v2/dashboard/user/note/baeb1189-193e-49d3-b5c5-2fcada56c774?limit=10&page=1

export const GetNoteList = (quarantineId: any, filter?: Filter) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      note?: CreateNoteData[];
      total?: number
    };
  }>({
    url: `/v2/dashboard/user/note/${quarantineId}`,
    method: "GET",
    data: {
      quarantineId,
    },
    params: {
      page: filter?.page || 1,
      limit: filter?.perPage || 10,
    },
  });

  return {
    result_getNoteList: res?.data?.note || [],
    loading_getNoteList: loading,
    total: res?.data?.total || 0,
    error_getNoteList: error,
  };
};

export const GetStatusForUser = (userId: string) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      quarantine_result_status?: any;
    };
  }>({
    // url: `/v2/dashboard/user/status/${userId}/history`,
    url: `/v2/dashboard/user/status/${userId}`,
    method: "GET",
  });

  return {
    result_getStatus: res?.data?.quarantine_result_status || [],
    loading_getStatus: loading,
    error_userList_getStatus: error,
  };
};

// https://api.space-trax.com/v2/dashboard/user/onboard/status/87e87e13-3152-4201-a6aa-4b492b9c483c

export const GetStatusForPhuketUser = (userId: string) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: {
      quarantine_result_status?: any;
    };
  }>({
    // url: `/v2/dashboard/user/status/${userId}/history`,
    url: `/v2/dashboard/user/onboard/status/${userId}`,
    method: "GET",
  });

  return {
    result_getStatus: res?.data?.quarantine_result_status || [],
    loading_getStatus: loading,
    error_userList_getStatus: error,
  };
};


export const GetContactTracerInfo = (userId: string, startDate?: any, endDate?: any) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: ContactTracerInfo;
  }>({
    url: `/v2/social-distancing/user/${userId}`,
    method: "GET",
    // data: {
    //   userId,
    // },
    // params: {
    //   start_date: DateString(startDate),
    //   end_date: DateString(endDate)
    // },
  });

  const result_info = res?.data;
  let contactInfo: any = [];
  let nodeArray: {id:any, shape:any, image:any, brokenImage: any, label:any, color:any} [] =[];
  let edgeArray: {from:any, to:any}[] = [];

  if (result_info) {
    
    if(result_info.social){
      contactInfo = 
        (result_info.social || []).map(({ device, user, distance }, i) => {
          return {
            name: user?.name,
            surname: user?.surname,
            gender: user?.gender,
            nationality: user?.nationality,
            latitude: device?.latitude,
            longitude: device?.longitude,
            id: user?.id,
            distance: distance
          };
        });

        nodeArray = (result_info.social || []).map(({ user, distance }, i) => {
          let returnData;
          if(distance < 2){
            returnData = {
              id: i,
              shape: 'circularImage',
              image: RedUserIcon,
              brokenImage: BlueUserIcon,
              label: user?.name,
              color: "#f7f0c8"
            }
          }else {
            returnData = {
              id: i,
              shape: 'circularImage',
              image: BlueUserIcon,
              brokenImage: BlueUserIcon,
              label: user?.name,
              color: "#f7f0c8"
            }
          }
          return returnData;
        });

        edgeArray = 
        (result_info.social || []).map(({ device, user, distance }, i) => {
          let edge;
          if(distance<=2){
            edge={
              from: res?.data?.id,
              to: i,
              label: distance + 'm',
              color: 'red',
              font: { color: 'red' },
              width: 2
             };
          }else{
            edge= {
              from: res?.data?.id,
              to: i,
              label: distance + 'm',
              color: 'green',
              font: { color: 'green' },
              width: 2
             };
          }
          return edge; 
        });
    }
    contactInfo.push({
      name: res?.data?.name,
      surname: res?.data?.surname,
      id: res?.data?.id,
      gender: res?.data?.gender,
      nationality: res?.data?.nationality,
      longitude: res?.data?.device?.longitude,
      latitude: res?.data?.device.latitude,
      distance: null
    });
    if(res?.data?.id !== null){
      nodeArray.push(
        {
          id: res?.data?.id,
          shape: 'circularImage',
          image: RedUserIcon,
          brokenImage: BlueUserIcon,
          label: res?.data?.name,
          color: "#f7f0c8"
        }
      );
    }
    
    console.log("contact info filter:", contactInfo.reverse());
  }

  return {
    result_getContactTracerInfo: res?.data || null,
    result_contactInfo : contactInfo.reverse() || [],
    result_nodeInfo: nodeArray.reverse() || [],
    result_edgeArray: edgeArray || [],
    loading_getContactTracerInfo: loading,
    error_getContactTracerInfo: error,
  };
};


// https://api.space-trax.com/v2/social-distancing/user/{{user_id}}/history

export const ContactTracerHistroyLog = (userId: string,
  startDate?: any,
  endDate?: any) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data?: ContactTracerInfo;
  }>({
    url: `/v2/social-distancing/user/${userId}/history`,
    method: "GET",
    data: {
      userId,
    },
    params: {
      start_date: DateString(startDate),
      end_date: DateString(endDate)
    }
  });

  return {
    result_historyLog: res?.data || null,
    loading_historyLog: loading,
    error_historyLog: error,
  };
};

// https://api.space-trax.com/v2/smart-infirmary/users/4c997130-e9fc-4198-b83f-a69260c2e0d7/profile
export const GetUserProfile = (userId: any,
  filter: ReadUserFilter = {
    endDate: new Date(),
    startDate: new Date(),
  },
  ) => {
  if (filter.endDate && typeof filter.endDate !== "string") {
    filter.endDate = dateStrAPI(filter.endDate);
  }
  if (filter.startDate && typeof filter.startDate !== "string") {
    filter.startDate = dateStrAPI(filter.startDate);
  }

  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: UserProfile;
  }>({
    url: `/v2/smart-infirmary/users/${userId}/profile`,
    method: "GET",
    data: {
      userId,
    },
    params: {
      start_date: filter.startDate,
      end_date: filter.endDate,
      filter_hour: filter.filterHour,
    },
  });

  const result_userRead_raw = res?.data;

  let result_blood_pressure_history: null | BloodPressureHistoryReport = null;
  if (result_userRead_raw) {
    result_blood_pressure_history = _.sortBy(
      (result_userRead_raw.blood_pressure || []).map(
        ({ blood_systolic, blood_diastolic, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            blood_pressure: blood_systolic + "/" + blood_diastolic,
            blood_systolic: blood_systolic,
            blood_diastolic: blood_diastolic,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_heart_rate_history: null | HeartRateHistoryReport = null;
  if (result_userRead_raw) {
    result_heart_rate_history = _.sortBy(
      (result_userRead_raw.heart_rate || []).map(
        ({ heart_rate, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            heart_rate: heart_rate,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_temp_history: null | TempHistoryReport = null;
  if (result_userRead_raw) {
    result_temp_history = _.sortBy(
      (result_userRead_raw.tempurature || []).map(
        ({ body_temp, created_at }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            body_temp: body_temp,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_oximeter_history: null | OximeterHistoryReport = null;
  if (result_userRead_raw) {
    result_oximeter_history = _.sortBy(
      (result_userRead_raw.oximeter || []).map(
        ({ blood_oxygen, created_at, BTUtcTime }) => {
          const timestamp = new Date(created_at);
          // const timestamp = BTUtcTime;
          // const timestamp = dateStrAPI(BTUtcTime) + " " + timeStr(BTUtcTime);
          // console.log("timestamp:", timestamp);
          // console.log("timestr:" , timeStr(BTUtcTime));
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            oxygen: blood_oxygen,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_breath_history: null | BreathHistoryReport = null;
  if (result_userRead_raw) {
    result_breath_history = _.sortBy(
      (result_userRead_raw.breath || []).map(
        ({ breath, created_at, BTUtcTime }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            breath: breath,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  let result_blood_sugar_history: null | BloodSugarHistoryReport = null;
  if (result_userRead_raw) {
    result_blood_sugar_history = _.sortBy(
      (result_userRead_raw.blood_sugar || []).map(
        ({ blood_sugar, created_at, BTUtcTime }) => {
          const timestamp = new Date(created_at);
          const diff = DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(created_at)),
            "days"
          ).days;

          return {
            blood_sugar: blood_sugar,
            length_of_stay: Math.floor(diff),
            timestamp: timestamp,
          };
        }
      ),
      "timestamp","length_of_stay"
    );
  }

  return {
    result_getUserProfile: res?.data,
    result_blood_pressure_history,
    result_heart_rate_history,
    result_temp_history,
    result_oximeter_history,
    result_blood_sugar_history,
    result_breath_history,
    loading_getUserProfile: loading,
    error_getUserProfile: error,
    fire_userRead: fire,
  };
};

export const GetPoccUserId = (userId: string) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
      data: any
    }>(
      {
        // /v2/dashboard/user/pocc/9ba8bfcb-12f9-42a9-99f3-aaf35ecba5e6/detail
        url:  `/v2/dashboard/user/pocc/${userId}/detail`,
        method: "GET",
        data: {
          userId,
        },
      }
    );
     console.log("userid pocc:", res?.data);
    return {
      result_data_userId: res?.data ? res?.data?.id : null,
      loading_data: loading,
      error_data: error
    };
}

