import React, { useEffect, useState, useRef, CSSProperties } from "react";
import {
  Box,
  Button,
  FormControl, 
  Modal
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { DateAndTimeString } from "../../../utils";
import '../VideoCallStyle.css';
import '../Messenger.css';
import { SentMessage,GetMessage, SentVoiceMessage, GetMessageByLimit } from '../../../services/api/telemedicine/Message'; 
import { GetBookingInfoById } from "../../../services/api/telemedicine/BookingService";  
import {
  GetUserProfile
} from "../../../services/api/user/useReadUser";
import { RtmMessage } from 'agora-rtm-react';
import { useReactMediaRecorder } from "react-media-recorder";
import MicIcon from '@material-ui/icons/Mic';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from "@material-ui/icons/Close";
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import StopIcon from '@material-ui/icons/Stop';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { v4 as uuidv4 } from 'uuid';
import Pusher  from 'pusher-js';

export type messageStore = {
  msg: RtmMessage;
  uid: string;
  date: string | Date | null;
  messageId?: string;
};

export const RecordModal = observer(
  ({
    isRecording,
    onClick,
    setIsRecording,
    buttonName = "Send"
  }: {
    isRecording: boolean;
    onClick?: any;
    setIsRecording?: any;
    buttonName?: string;
  }) => {

    const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({ audio: true, 
      blobPropertyBag: {
        type: "audio/mpeg"
    }
    });
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [ showSendButton, setShowSendButton ] = useState(false);
    function reset() {
      setSeconds(0);
      setIsActive(false);
    };

    function convertMS(value : any) {
      const sec = parseInt(value, 10);
      let hours : any   = Math.floor(sec / 3600); // get hours
      let minutes : any = Math.floor((sec - (hours * 3600)) / 60); // get minutes
      let seconds : any = sec - (hours * 3600) - (minutes * 60); //  get seconds
      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      return minutes+':'+seconds;
  }

    useEffect(() => {
      let interval : any = null;
      if (isActive) {
        interval = setInterval(() => {
          setSeconds(seconds => seconds + 1);
        }, 1000);
      } else if (!isActive && seconds !== 0) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }, [isActive, seconds]);

    return (
      <Modal
        open={isRecording}
        onClose={() => {}}
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          css={{ backgroundColor: "#fff", width: 350, borderRadius: 30, border: 'solid 2px #3f51b5' }}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            mt={1}
          >
            <Box css={{ color: "#4B4C56" }}>
              <h3>Record</h3> 
            </Box>
            <Box
              onClick={() => {
                setIsRecording(false);
                clearBlobUrl();
              }}
            >
              <CloseIcon css={{ cursor: "pointer" }} />
            </Box>
          </Box>

          

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
            margin="dense"
            >
              <div style={{marginLeft: '5%'}}>
                
                <Button onClick={() => {
                  setIsActive(true);
                  startRecording();
                  setShowSendButton(false);
                }} style={{marginRight: '4%'}}>
                    <PlayCircleFilledWhiteIcon style={{color: '#0461c4', fontSize: '21px'}}/> Start
                </Button>
                <Button onClick={() =>{
                  setIsActive(false);
                  stopRecording();
                  setShowSendButton(true);
                }}>
                  <StopIcon style={{color: '#0461c4'}}/> Stop
                </Button>
                <br/>
                <p style={{fontSize: '13px', padding: '10px'}}>
                  { status === 'recording' ? 
                      <>
                          <SettingsVoiceIcon style={{color: '#0461c4'}}/> {status}
                      </>
                      : 
                      "" 
                  }</p>
                <audio id="audioFile" src={mediaBlobUrl ? mediaBlobUrl : ""}  controls />
              </div>
            </FormControl>
            
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt ={2}
          >
            {
              showSendButton ? 
              <Button
                onClick={async()=>{
                  const audioBlob = await fetch(mediaBlobUrl ? mediaBlobUrl : "").then(r => r.blob());
                  const audiofile = new File([audioBlob], `${uuidv4()}.mp3`, { type: "audio/mpeg" });
                  onClick(mediaBlobUrl, audiofile, convertMS(seconds));
                  clearBlobUrl();
                  reset();
                }}
                color="primary"
                size="medium"
                variant="contained"
              >
                {buttonName}
              </Button>
              :
              <></>
            }
            
          </Box>
          <br/>
        </Box>
      </Modal>
    );
  }
);

const btnStyle = { backgroundColor: '#007bff', borderWidth: 0, borderRadius: 10, color: '#fff', 
                    width: 100, fontWeight: 'bold', fontSize: 16 } as CSSProperties

export const ChatMessage  = observer(() => {
  const { index, name, surname, chatChannel, chatChannelType, appointmentId }: any = useParams();
  const [ userId, setUserId ] = useState("");
  
  const [texts, setTexts] = useState<messageStore[]>([])
  const [uid, setUid] = useState<string>('')
  const [textInput, setTextInput] = useState<string>('')
  const { result_getUserProfile,fire_userRead, loading_getUserProfile, ...rest } = GetUserProfile(userId);
  const { fire_sentMessage } = SentMessage();
  const { fire_sentVoiceMessage } =SentVoiceMessage();
  const { result_getMessage, messages } = GetMessage(userId, chatChannel);
  const { result_bookingData } = GetBookingInfoById(appointmentId);

//   const { result_getMessageByLimit, loading_getMessageByLimit, messagesByLimit } = GetMessageByLimit(chatChannel);
  const [ isRecording, setIsRecording ] = useState(false);

  const messagesEndRef : any = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  // const scrollMessage = document.querySelector("#scrollMessage");
  
  // scrollMessage?.addEventListener("scroll", event => {
  //     setScrollPosition(scrollMessage?.scrollTop)
  // }, { passive: true });

  const handleKeyPress = (event : any) => {
    if (event.keyCode === 13 || event.which === 13) {
      if(textInput !== ''){
          try {
            fire_sentMessage(chatChannel, textInput)
              .then(() => {
                console.log('success sent!');
              })
              .catch((ex) => {
                console.log('message cannot be sent! something went wrong!');
              });
          } catch (ex) {}
        }
      }
      
  }
  useEffect(() => {
    
    if(index){
      setUserId(index);
    }

    if(result_getMessage){
      setTexts(messages);
      setUid('You');
      scrollToBottom();
    }

    if(messagesEndRef){
        scrollToBottom();
    }

    const pusher = new Pusher("19ace3328dfc84d1f19a", {
      cluster: "ap1",
    });

		const channel = pusher.subscribe(chatChannel);
		const doctorId = localStorage.getItem("doctor_id");
		channel.bind('message_receive',function(data :any) {
		    console.log(data);
        let msg = {"text" : data?.message};
        let uid = data?.sender_id === doctorId ? "You" : "";
        let date = data?.created_at;
        setTexts((previous) => {
          return [...previous, 
            { msg, 
              uid, 
              date }
          ]
        });
        scrollToBottom();
        setTextInput('');
		})
		
		return (() => {
			pusher.unsubscribe(chatChannel);
		})
  }, [result_getMessage,result_bookingData, chatChannel, index]);

  return (
    <> 
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '83vh', margin: 8 }}>
        
            <div style={{ display: 'flex', flex: 10, flexDirection: 'column',marginTop: '-1%', marginBottom:'2%', backgroundColor: '#efefef', paddingRight: 10, paddingLeft: 10, overflowY: 'scroll'}}>
            {texts.map((text: any, i) =>
                {
                return (
                    <div key={text.messageId}>
                      <div style={{ backgroundColor: text.uid === uid ? '#007bff50' : '#ccc', margin: 10, width: '50%', marginLeft: text.uid === uid ? 'auto' : '', padding: 12, borderRadius: 10 }}>
                          <div style={{fontSize: 12, opacity: 0.5}}>{text.uid}</div>
                          <div>
                          {
                              
                              text.msg['text']?.toString().includes('blob')?
                              <>
                              <audio controls>
                                  <source src={text.msg['text']} type="audio/mpeg" />
                              </audio>
                              </>
                              :text.msg['text']?.toString().includes('VOICEMSG')?
                              <>
                              <audio controls>
                                  <source src={text.msg['text']} type="audio/mpeg"/>
                              </audio>
                      
                              </>
                              :
                              <>
                              {text.msg['text']}
                              </>

                          }
                          
                          </div>
                          
                      </div>
                      <p key={i} style={{color: 'gray', fontSize: 10,  margin: 10, width: '50%', marginLeft: text.uid === uid ? 'auto' : '', marginTop: '-4px'}}>{DateAndTimeString(text.date)}</p>
                    </div>
                    )

                }
                
            )}
            <div ref={messagesEndRef} />
            </div>
        
        
        <div style={{ display: 'flex', justifyContent: 'space-around', justifySelf: 'flex-end' }}>
        <div style={{display: 'flex', flexDirection: 'row', flex: '1', height: 40}}>
            <input style={{width: '100%', marginRight: 20}} type='text' value={textInput}
                onChange={e => setTextInput(e.target.value)} 
                onKeyPress={handleKeyPress}
                />
                <MicIcon  
                style={{marginTop: '5px', fontSize: '30px', color: '#0461c4', marginRight: '10px'}}
                onClick={()=>{
                setIsRecording(true);
                }}
                />
            
            <button style={{...btnStyle, opacity: textInput ? 1 : 0.5}} disabled={!textInput} 
            onClick={async () => {
                try {
                    fire_sentMessage(chatChannel, textInput)
                    .then(() => {
                        console.log('success message sent!');
                    })
                    .catch((ex) => {
                        console.log('message cannot be sent! something went wrong!');
                    });
                } catch (ex) {}
                
                }
            }
            >
                <SendIcon/>
            </button>
        </div>
        </div>
        </div>
        <RecordModal
        isRecording = { isRecording }
        onClick = {(audioUrl : any , audioFile: any, duration: any) =>{
            setIsRecording(false);
            try {
            fire_sentVoiceMessage(chatChannel, audioFile , duration )
                .then(() => {
                console.log('success voice message sent!');
                })
                .catch((ex) => {
                console.log('voice message cannot be sent! something went wrong!');
                });
            } catch (ex) {}
            
        }}
        setIsRecording = { setIsRecording }/>   
    </>
  );
});