import { UserByNationalityReport } from "../../../types/overviewReport";
import { useCallAPISpacetrax } from "../../useCallAPI";

export const useReadUserByNationality = (endDate?: Date, startDate?: Date) => {
  const [{ data: res, loading, error }, fire] = useCallAPISpacetrax<{
    data: {
      nationality: UserByNationalityReport;
    };
  }>({
    url: `/v2/dashboard/personals`,
    method: "GET",
    data: {
      startDate,
      endDate,
    },
  });
  return {
    result_user_by_nation: res?.data?.nationality,
    loading_user_by_nation: loading,
    error_user_by_nation: error,
    fire_user_by_nation: (endDate?: Date, startDate?: Date) => {
      return fire({
        data: {
          startDate,
          endDate,
        },
      });
    },
  };
};
