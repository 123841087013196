import React from "react";
import { observer } from "mobx-react-lite";
import { FormControl, Box, Button, Grid } from "@material-ui/core";
import { NON_REQUIRE_USER_FIELD } from "./UserCreateSI";
import LuxonUtils from "@date-io/luxon";
import { ToolbarComponentProps } from "@material-ui/pickers/Picker/Picker";
import { css } from "@emotion/react";
import { MuiPickersUtilsProvider, KeyboardDatePicker,KeyboardTimePicker } from "material-ui-thai-datepickers";

export const InputDate = observer(
    ({
      input,
      form,
      onChange,
      error,
      value,
    }: {
      input: any;
      form?: any;
      onChange: any;
      error: boolean;
      value?: any;
    }) => {
      const f: any = form;
      const v: any = form ? f[input.name] : value;
      return (
        <FormControl
          required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
          style={{ width: "100%" }}
          size="small"
          margin="dense"
        >
          <MuiPickersUtilsProvider utils={LuxonUtils} locale={'th'}>
            <KeyboardDatePicker
              required={NON_REQUIRE_USER_FIELD.indexOf(input.name) < 0}
              style={{ margin: 0, width: "100%" }}
              autoOk
              format="dd/MM/yyyy"
              margin="normal"
              id="from"
              size="small"
              ToolbarComponent={(props: ToolbarComponentProps) => {
                return (
                  <div
                    style={{
                      background: "#494592",
                    }}
                  >
                    <Box p={2}>
                      <Button
                        size="small"
                        css={css`
                          font-size: 0.8rem;
                          color: white;
                          line-height: 1rem;
                          font-weight: bold;
                          text-transform: none;
                        `}
                        onClick={() => {
                          props.setOpenView("date");
                        }}
                      >
                        {props.date?.weekdayLong + " " + props.date?.day}
                      </Button>
                      <Grid>
                        <Button
                          size="small"
                          css={css`
                            font-size: 1.5rem;
                            line-height: 1.65rem;
                            font-weight: bold;
                            color: white;
                            text-transform: none;
                          `}
                          onClick={() => {
                            props.setOpenView("month");
                          }}
                        >
                          {props.date?.monthLong}
                        </Button>
                        <Button
                          size="small"
                          css={css`
                            font-size: 1.5rem;
                            line-height: 1.5rem;
                            font-weight: bold;
                            color: white;
                            text-transform: none;
                          `}
                          onClick={() => {
                            props.setOpenView("year");
                          }}
                        >
                          {props.date?.year ? props?.date?.year + 543 : props?.date?.year}
                        </Button>
                      </Grid>
                    </Box>
                  </div>
                );
              }}
              inputVariant="outlined"
              label={input.label}
              value={v}
              onChange={onChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              error={error}
              yearOffset={543}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      );
    }
  );