import React, { useEffect, useState } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  withStyles
} from "@material-ui/core";
import '../../App.css';
import { StickyContainer, Sticky } from "react-sticky";
import { useParams } from "react-router-dom";
import {
  GetUserProfile,
} from "../../services/api/user/useReadUser";
import LuxonUtils from "@date-io/luxon";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { webStore } from "../../stores/webStore";
import { observer } from "mobx-react-lite";
import { UserInfoCardSI,UserProfileDetail } from "./UserInfoCardSI";
import { DeviceHistoryDatagrid } from "./DeviceHistoryDatagrid";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { BodyTempGraph } from "./BodyTempGraph";
import { HeartRateGraph } from "./HeartRateGraph";
import { BloodPressureGraph } from "./BloodPressureGrph";
import { OximeterGraph } from "./OximeterGraph";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import { dateStrAPI, timeStr } from "../../utils";
import { UserPreviewData } from "../../types/UserPreviewData";
import { SubBar } from "../Dashboard/SubBar";

const DeviceField = observer((props: any) => {
  return (
    <Box
      borderRight={props.border ? props.border : 0}
      css={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
    >
      <Box css={{ color: "#8F919C" }} fontSize={12} mb={0.5}>
        {props.label}
      </Box>
      <Box fontSize={18}>{props.children}</Box>
    </Box>
  );
});

export const UserDetail = observer(() => {
  const { userId }: any = useParams();

  const { result_getUserProfile,fire_userRead, loading_getUserProfile, ...rest } = GetUserProfile(userId,webStore.readUserFilter);
  useEffect(() => {
      webStore.resetFilter();
      webStore.setStartDate(startDate);
      webStore.setEndDate(endDate);
      webStore.set_filter_hour(filterHour);

  }, [ result_getUserProfile]);
  const parseData: UserPreviewData = {
    loading: loading_getUserProfile,
    ...rest,
  };

  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  // const { fire_createNote, loading_createNote } = CreateNote(userId);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  const Accordion = withStyles({
    root: {
      borderBottom: "1px solid rgba(0, 0, 0, .1)",
      boxShadow: "none",
      "&:last-child": {
        borderBottom: 0,
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);

  const [startDate, setStartDate] = useState<Date | null>(
    webStore.startDate || new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    webStore.endDate || new Date()
  );
  const [filterHour, setFilterHour] = useState<number | null>(
    webStore.filter_hour
  );
  
  
  const onSearch = () => {
    webStore.setStartDate(startDate);
    webStore.setEndDate(endDate);
    webStore.set_filter_hour(filterHour);
  };

  return (
    <>
      <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16" id={"savePNG"}>
      <SubBar title={"User Detail"} reload={false} back={true} />

      <Box>
        <Box
          css={{ borderRadius: 5, backgroundColor: "#2B5698", color: "#fff" }}
        >
          <Box display={{ xs: "block", md: "flex" }}>
            <Box
              p={4}
              width={{ xs: "100%", md: "40%" }}
              css={{ borderRight: "1px solid rgba(255,255,255,0.5)" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {result_getUserProfile && (
                <UserProfileDetail reload={fire_userRead} user={result_getUserProfile} />
              )}
            </Box>
            <Box py={4} px={6} width={{ xs: "100%", md: "60%" }}>
              {result_getUserProfile && (
                <UserInfoCardSI
                  reload={fire_userRead}
                  user={result_getUserProfile}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="flex justify-between items-center" mt={8} pb={2}>
        <Grid container>
            <Grid item md={4} xs={12}>
              <Box fontSize="h5.fontSize" fontWeight={500}>
                Health Report 
              </Box>
            </Grid>
            <Grid item md={8} xs={12}></Grid>
        </Grid>
        
      </Box>

      <Box>
        {
            result_getUserProfile?.health ?
            <Accordion
            square
            expanded={expanded === `panel1`}
            onChange={handleChange(`panel1`)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display={{ xs: "block", md: "flex" }}
                alignItems="center"
                px={{ xs: 0, md: 3 }}
                py={2}
              >
                <Box
                  fontSize={12}
                  css={{ color: "#4B4C56" }}
                  mt={{ xs: 2, md: 0 }}
                >
                  Latest update :
                  {
                    result_getUserProfile?.health?.updated_at ?
                    dateStrAPI(result_getUserProfile?.health?.updated_at) + ", " + timeStr(result_getUserProfile?.health?.updated_at)
                    :
                    ""
                  }
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box px={{ xs: 0, md: 3 }} width="100%">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Box
                      display="flex"
                      width="100%"
                      paddingRight={{ md: 5 }}
                      mb={{ xs: 4, md: 0 }}
                    >
                      <Grid item xs={4}>
                        <DeviceField label="Body temp">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.body_temp ? result_getUserProfile?.health?.body_temp : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField label="Heart rate">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.heart_rate ? result_getUserProfile?.health?.heart_rate : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Blood pressure"
                          border={{ xs: 0, md: 1 }}
                        >
                          <Box fontWeight={600}>
                            { result_getUserProfile?.health?.blood_systolic && result_getUserProfile?.health?.blood_diastolic ?
                            `${result_getUserProfile?.health?.blood_systolic}/${result_getUserProfile?.health?.blood_diastolic}`
                            :
                            "N/A"
                            }</Box>
                        </DeviceField>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" width="100%" mb={{ xs: 4, md: 0 }}>
                      <Grid item xs={4}>
                        <DeviceField label="Blood Sugar">
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.blood_sugar ? result_getUserProfile?.health?.blood_sugar : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Blood Oxygen"
                        >
                          <Box fontWeight={600}>
                            {result_getUserProfile?.health?.blood_oxygen ? result_getUserProfile?.health?.blood_oxygen : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                      <Grid item xs={4}>
                        <DeviceField
                          label="Respiratoin(per min)"
                        >
                          <Box fontWeight={600}>
                              {result_getUserProfile?.health?.breath ? result_getUserProfile?.health?.breath : "N/A"}
                          </Box>
                        </DeviceField>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
            </Accordion>
          :
          <></>
        }
      </Box>      
  
      <Box
        className="justify-between items-center"
        mt={8}
        pb={2}
        display={{ xs: "block", md: "flex" }}
      >
        <Box fontSize="h5.fontSize" fontWeight={500} mb={{ xs: 2, md: 0 }}>
          Health Log
        </Box>
        
        <Grid container md={4}>
          <Grid item xs={5} css={{ padding: "0 5px" }}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <KeyboardDatePicker
                style={{
                  margin: 0,
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="from"
                inputVariant="outlined"
                label="Date Start"
                autoOk={true}
                value={startDate}
                onChange={(date) => {
                  setStartDate(date ? new Date(date.toString()) : null);
                  // onSearch();
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={5} css={{ padding: "0 5px" }}>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <KeyboardDatePicker
                style={{ margin: 0, width: "100%", backgroundColor: "#fff" }}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="to"
                label="Date End"
                inputVariant="outlined"
                value={endDate}
                autoOk={true}
                onChange={(date) => {
                  setEndDate(date ? new Date(date.toString()) : null);
                  // onSearch();
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2} css={{ padding: "0 5px" }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton onClick={onSearch} color="primary">
                <SearchIcon fontSize="large" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item md={8} xs={12}>
          <Box
            paddingRight={{ xs: 0, md: 3 }}
            css={{ backgroundColor: "transparent" }}
          >
            <Grid
              item
              xs={12}
              style={{ height: 380, overflow: "hidden", marginBottom: 15 }}
            >
              <BodyTempGraph data={parseData} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ height: 380, overflow: "hidden", marginBottom: 15 }}
            >
              <HeartRateGraph data={parseData} />
            </Grid>
      
              <Grid item xs={12} style={{ height: 380, overflow: "hidden" , marginBottom: 15}}>
                <BloodPressureGraph data={parseData} />
              </Grid>
            
            <Grid item xs={12} style={{ height: 380, overflow: "hidden" , marginBottom: 15 }}>
              <OximeterGraph data={parseData} />
            </Grid>

          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <StickyContainer
            style={{
              width: "100%",
              flex: 1,
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Sticky topOffset={-80}>
              {({ style, isSticky }: any) => (
                <div style={style}>
                  <div
                    style={{
                      position: "relative",
                      height: "calc(100vh - 4rem)",
                      top: !isSticky ? 0 : "4rem",
                    }}
                  >
                    <DeviceHistoryDatagrid data={parseData} />
                  </div>
                </div>
              )}
            </Sticky>
          </StickyContainer>
        </Grid>
      </Grid>
      
    </Container>
    </>
  );
});
export const UserDetailMobile = UserDetail;
