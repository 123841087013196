import { CallTelemed } from "../../useCallAPI";
import { FinanceData } from "../../../types/FinanceData"; 
import { dateStrAPI,dateStrAPIFormat } from "../../../utils";
import { AppointmentDataById } from "../../../types/Appointment";

export const GetFinance = (startDate: any, endDate: any, status: any, page?: any, rowsPerPage?: any, patientname?: any, changeState?: any) => {
    var start_date;
    var end_date;
    if(startDate){
        start_date = dateStrAPIFormat(startDate);
    }else{
        start_date = dateStrAPIFormat(new Date());
    };

    if(endDate){
        end_date = dateStrAPIFormat(endDate);
    }else{
        end_date =dateStrAPIFormat(new Date());
    }

    if(!page || page === 0){
      page = 1;
    }

    if(!rowsPerPage){
      rowsPerPage = 5;
    }

    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: FinanceData;
        }>(
        {
            url:  `/v2/medical-billing/list`,
            method: "GET",
            params: {
                page: page,
                take: rowsPerPage,
                invoiceStartDate: start_date,
                invoiceEndDate: end_date,
                invoiceStatus: status,
                name: patientname,
                changeState: changeState
              },
        }
        );

        return {
        result_financeData: res?.data ? res?.data?.datas : null,
        result_meta: res?.data?.meta ? res?.data?.meta : null,
        loading_financeData: loading,
        error_financeData: error
        };
}

export const UpdateFinanceStatus = () => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
      data: any;
      code: 0 | 1;
      message: string;
    }>(
      {
        url: `/v2/medical-billing/status`,
        method: "PATCH",
      },
      { manual: true }
    );
    return {
      result_updateFinance: res?.data,
      loading_updateFinance: loading,
      error_updateFinance: error,
      fire_update_finance: (
        id: any, status: number
      ) => {
        let idArray: any = [];
        for(let i =0; i < id.length; i++){
          idArray.push(id[i].id.toString());
        }
        let requestData = {};
        requestData = {
            invoice_status: status,
            medical_billing_ids: idArray
        } 
        return new Promise((resolve, reject) => {
            fire({
              data: requestData,
            })
          .then(({ data: { data, code, message } }) => {
            if (code === 0) {
              reject(message);
            } else {
              resolve(data);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
      },
    };
  };


  export const GetPatientRecent = (keyword?: any, startDate?: any, endDate?: any) => {
    const [{ data: res, loading, error }, fire] = CallTelemed<{
        data: AppointmentDataById[];
        }>(
        {
            url:  `/v2/dashboard/patient/recent`,
            method: "GET",
            params:{
              keyword: keyword,
              startDate: dateStrAPI(startDate),
              endDate: dateStrAPI(endDate)
            }
        }
        );

        return {
        result_patientRecent: res?.data ? res?.data : null,
        loading_patientRecent: loading,
        error_patientRecent: error
        };
}

export type FormInput = {
  file: File;
};

export const CreateInvoiceService = (id : any) => {
  const [{ data: res, loading, error }, fire] = CallTelemed<{
    code: 0 | 1;
    type: string;
    description: any;
  }>(
    {
      url: `/v2/medical-billing/patients/${id}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    {
      manual: true,
    }
  );
  return {
    result_create_invoice: res?.code === 1 ? res?.description : null,
    loading_create_invoice: loading,
    error_create_invoice: res?.code === 0 ? res?.type : null,
    fire_create_invoice: (FormInput: FormInput, invoiceNumber: any, bookingId: string, invoiceDate: string , amount: string) => {
      var formData = new FormData();
      formData.append("invoice_file", FormInput.file, FormInput.file.name);
      formData.append("amount", amount);
      formData.append("invoice_date", invoiceDate);
      formData.append("booking_id", bookingId);
      formData.append("invoice_no", invoiceNumber);
      return new Promise((resolve, reject) => {
        fire({
          data: formData,
        })
          .then(({ data: { description, code, type } }) => {
            if (code === 0) {
              reject(description);
            } else {
              resolve(description);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
    },
  };
};

export const InoviceUploadService = (id : any) => {
  const [{ data: res, loading, error }, fire] = CallTelemed<{
    code: 0 | 1;
    type: string;
    description: any;
  }>(
    {
      url: `/v2/medical-billing/${id}/upload`,
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    {
      manual: true,
    }
  );
  return {
    result_form_upload: res?.code === 1 ? res?.description : null,
    loading_form_upload: loading,
    error_form_upload: res?.code === 0 ? res?.type : null,
    fire_form_upload: (FormInput: FormInput) => {
      var formData = new FormData();
      formData.append("file", FormInput.file, FormInput.file.name);
      formData.append("fileType", "invoice");
      return new Promise((resolve, reject) => {
        fire({
          data: formData,
        })
          .then(({ data: { description, code, type } }) => {
            if (code === 0) {
              reject(description);
            } else {
              resolve(description);
            }
          })
          .catch((ex) => {
            reject(ex.toString());
          });
      });
    },
  };
};