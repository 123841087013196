import React, { useEffect } from "react";
import { useLocation,useHistory } from "react-router-dom";
import { useMe,GetProject } from "../services/api/auth/useMe";
import { webStore } from "../stores/webStore";
import logo from "../components/assets/PMH.svg";
export const AfterLogin = () => {
  const { result_me, loading_me } = useMe();
  const { result_project, loading_project } = GetProject();
  const { pathname } = useLocation();
  const history = useHistory();
  
  useEffect(() => {
    if (!loading_me && result_me  && !loading_project && result_project ) {
      result_me.logo = logo;
      webStore.setProfile(result_me, result_project);
      if(localStorage.getItem("login") === "login" || localStorage.getItem("login") === 'toDashboard'){
        if(localStorage.getItem("userRole") === 'finance') {
          history.push("/telemedicine/finance");
          localStorage.setItem("login", "routeChange");
        }else if (localStorage.getItem("userRole") === 'pharmacy'){
          history.push("/user/listSI");
          localStorage.setItem("login", "routeChange");
        }
      }
      
      if(localStorage.getItem("login") === "login" || localStorage.getItem("login") !== 'toDashboard' && localStorage.getItem('login') !== 'routeChange'){
        history.push("/");
        localStorage.setItem("login", "toDashboard");
      }      
    }
  }, [localStorage.getItem("token"), loading_project, pathname || "/"]);
  return <></>;
};
