import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import VideocamIcon from '@material-ui/icons/Videocam';
import AgoraUIKit, {
 }from 'agora-react-uikit';
import '../VideoCallStyle.css';
import { SubBar } from "../../Dashboard/SubBar";
import '../Messenger.css';

import { GetAgoraToken, JoinVideoCall, EndCall } from "../../../services/api/telemedicine/Videocall"; 
import { PatientInfo } from "../Chat/PatientInfo";

const styles = {
  container: { width:'100%',height: '100%', display: 'inline-flex', flex: 1, backgroundColor: '#e6eff8'},
  heading: { textAlign: 'center' as const, marginBottom: 0 },
  videoContainer: { display: 'flex', flexDirection: 'column', flex: 1 } as React.CSSProperties,
  nav: { display: 'flex', justifyContent: 'space-around' },
  btn: { backgroundColor: '#3f51b5', cursor: 'pointer', borderRadius: 5, padding: 5, color: '#ffffff', fontSize: 20 },
}

export const VideoCall  = observer(() => {
  const { index, name, surname,videoChannel,videoChannelType, appointmentId }: any = useParams();
  const [channelName, setChannelName] = useState("");
  const [videocall, setVideocall] = useState(true);
  const [ agoraToken, setAgoraToken ] = useState('');
  const [ agoraUserId, setAgoraUserId ] = useState<number>();
  const { result_agorToken,loading_agoraToken,error_Token } = GetAgoraToken(videoChannel);
  const { result_joinCall, loading_joinCall, error_joinCall } = JoinVideoCall(videoChannel);
  const { loading_endCall, fire_endCall } = EndCall();

  useEffect(() => {
    if(result_agorToken){
      setChannelName(result_agorToken.channel_name);
      setAgoraToken(result_agorToken.token);
      setAgoraUserId(result_agorToken.user_id);
    }
  }, [result_agorToken]);
 
  
  return (
    <>
          <SubBar title={"Video Call"} back={true} reload={false}/>
            <Grid container style={{padding: '10px'}}>
                    <Grid item md={4} xs={12}>
                      <PatientInfo />
                    </Grid>
                    <Grid item  md={8} xs = {12}>
                          <div style={styles.container}>
                            <div style={styles.videoContainer}>
                              {
                                videocall ? 
                                <>
                                <AgoraUIKit
                                rtcProps={{
                                  appId: '497639af6d0c4da6b2219759a43fdc88', 
                                  channel: channelName,
                                  token: agoraToken,
                                  uid: agoraUserId
                                }}
                                styleProps =  {{
                                  theme: '#ffffffee', 
                                  minViewContainer: {
                                    position: 'absolute',
                                    top: '14%',
                                    right: '2%',
                                    width: '12%',
                                    minWidth: '20%',
                                    minHeight: '18%',
                                    backgroundColor: '#fff',
                                    borderColor: 'grey',
                                    borderWidth: 2,
                                    height: '26%',
                                  },
                                  minViewStyles: {
                                    height: '100%',
                                  },
                                  maxViewContainer: {
                                    minWidth: '100%',
                                    minHeight: '100%'
                                  },
                                  maxViewStyles: {
                                    height: '100%',
                                    width: '100%'
                                  },
                                }}
                                callbacks={{
                                  EndCall: () => {
                                    setVideocall(false);
                                    try {
                                      fire_endCall(videoChannel)
                                        .then(() => {
                                          console.log('video end call!');
                                        })
                                        .catch((ex) => {
                                          console.log('video end call error!');
                                        });
                                    } catch (ex) {}
                                  },
                                }} />
                                </>:
                                <>
                                    <div style={styles.nav}>
                                        <Box
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          flex: 1,
                                          minHeight: "100%",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: 5,
                                          marginTop: '200px'
                                        }}
                                      >
                                            <Button startIcon={<VideocamIcon /> }  variant="contained" color="primary" onClick={() => setVideocall(true)}> Join Video Call  </Button>
                                        </Box>
                                    </div>
                                </>
                              }
                                  
                                </div>
                            </div>
                            
                      </Grid>
            </Grid>
          
    </>
  );
});