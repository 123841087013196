import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  Select,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";

import { withStyles, Theme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Filter, SmartInfirmaryUserList, SIuserListRaw } from "../../types/user";
import { UserItem } from "./UserItem";

export const ColorButton = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    
    '&:hover': {
      backgroundColor: '#314993',
      color: '#fff'
    },
  },
}))(Button);

export const CardUserListSI = observer(
  ({
    result_userList_raw,
    result_userList,
    loading_userList,
    total = 0,
    filter,
    mapRef,
    setPage,
  }: {
    result_userList_raw?: SIuserListRaw;
    result_userList: SmartInfirmaryUserList[];
    loading_userList: boolean;
    total?: number;
    mapRef?: any;
    filter?: Filter;
    setPage: (pn: number) => void;
  }) => {
    const [userLiser, setUserLiser] = useState<SmartInfirmaryUserList[]>([]);
  
    const [mapRefLat, setMapRefLat] = useState<String>();
    const [filterUser, setFilterUser] = useState<string>("oldest_first");
    useEffect(() => {
      setUserLiser(
        result_userList
      );
      if(filterUser === "oldest_first"){
        setUserLiser(result_userList);
      }
      else{
        setUserLiser([...result_userList].reverse());
      }
    }, [result_userList]);
    const pageCount = Math.ceil(total / (filter?.perPage || 10));
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    };

    const onChange = (e: any) => {
      setFilterUser(e.target.value as string);
      if (e.target.value === "oldest_first"){
        setPage(1);
        setUserLiser(result_userList);
      }else {
        setPage(pageCount);
        setUserLiser([...result_userList].reverse());
      }
    };

    return (
      <Box
        className="relative"
        css={{
          backgroundColor: "#fff",
          borderRadius: 5,
          cursor: "pointer",
          "@media (max-width: 992px)": { backgroundColor: "transparent" },
          height: "100%",
        }}
      >
        {loading_userList && (
          <LinearProgress
            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
          />
        )}
        <Box py={2} px={{ xs: 0, md: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            
            <Box fontSize={14} fontWeight={600}>
              Total {total} User
            </Box>

            <Box display="flex" alignItems="center" fontSize={14}>
              <Box mr={1}>Sort by</Box>
                <Select
                  labelId="label"
                  id="select"
                  value={filterUser}
                  onChange = {onChange}
                  disableUnderline
                  css={{ fontSize: 14, fontFamily: "inherit", color: "#4684E2" }}
                >
                  <MenuItem value="oldest_first">Oldest first</MenuItem>
                  <MenuItem value="newest_first">Newest first</MenuItem>
                </Select>
            </Box>
              
            
          </Box>
          <Box>
            {(userLiser || []).map((user, i) => {
              var index =
                ((filter?.page || 1) - 1) * (filter?.perPage || 10) + i + 1;
              
              return (
                <div
                  onMouseLeave={() => {
                    mapRef &&
                      mapRef.current &&
                      mapRef.current.reset &&
                      mapRef.current.reset();
                  }}
                  key={Math.random()}
                >
                  {
                    user ? 
                      <UserItem user={user} index={index} borderTop={i !== 0} />
                    :
                    <>
                      <div
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          position: "absolute",
                          opacity: "0.5",
                        }}
                      >
                          There is no user info.
                      </div> 
                    </>
                  }
                </div>
              );
            })}
          </Box>
          {pageCount > 1 && (
            <Box mt={2}>
              <Pagination
                count={pageCount}
                page={filter?.page || 1}
                onChange={handleChange}
                size="small"
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);
