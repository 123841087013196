import { observer } from "mobx-react-lite";
import React from "react";
import {useDropzone} from 'react-dropzone';

export const BasicDropZone = observer(( {setFile} : { setFile: any} ) => {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
    const files = acceptedFiles.map(file => (
        <li key={file.name}>
          {file.name}
        </li>        
    ));
  
    return (
      <section>
        <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag and drop files, or click to select files</p>
        </div>
        <aside>
            {
                files? 
                <>
                    {
                      setFile(acceptedFiles)
                    }
                    <ul style={{paddingLeft: '25px', color: '#111a79'}}>{files}</ul>
                </>
                :
                <></>
            }
          
        </aside>
      </section>
    );
}
);