import { Box, Button, Modal, TextField } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useClipboard } from 'use-clipboard-copy';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CloseIcon from "@material-ui/icons/Close";
import { InputDate } from './InputDate';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export const Field = observer(
    ({
      label,
      value,
      copy,
      edit,
      ...rest
    }: {
      label?: string;
      value: any;
      copy?: boolean;
      edit?: any;
    }) => {
      const { enqueueSnackbar } = useSnackbar();
      const clipboard = useClipboard();
      return (
        <Box my={1}>
          <Box fontSize={14} css={{ color: "rgba(255, 255, 255, 0.5)" }}>
            {label}
          </Box>
  
          <Box fontSize={18} display="flex" alignItems="center" style={{whiteSpace: 'pre-wrap'}}>
            {copy ? (
              <Box
                css={{ cursor: "pointer"}}
                onClick={() => {
                  clipboard.copy(value);
                  enqueueSnackbar(`copy ${value}`, {
                    variant: "success",
                    autoHideDuration: 3000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  });
                }}
              >
                {value}
                <FileCopyIcon
                  css={{ fontSize: 14, marginLeft: 5, cursor: "pointer" }}
                />
              </Box>
            ) : (
              value
            )}
            {edit && (
              <BorderColorIcon
                css={{ fontSize: 14, marginLeft: 5, cursor: "pointer" }}
                onClick={edit}
              />
            )}
          </Box>
        </Box>
      );
    }
  );
  
  export const EditField = observer(
    ({
      isEdit,
      label,
      value,
      field,
      onClick,
      setIsEdit,
      type = "text",
      setValue,
      buttonName = "SAVE",
    }: {
      isEdit: boolean;
      setIsEdit: any;
      label: string;
      field: string;
      value: any;
      onClick?: any;
      type?: string;
      setValue: any;
      buttonName?: string;
    }) => {
      return (
        <Modal
          open={isEdit}
          onClose={() => {}}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            css={{ backgroundColor: "#fff", width: 300, borderRadius: 5 }}
            p={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Box css={{ color: "#4B4C56" }} fontSize={14}>
                {label}
              </Box>
              <Box
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                <CloseIcon css={{ cursor: "pointer" }} />
              </Box>
            </Box>
            <Box>
              {type === "text" && (
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={value}
                  defaultValue={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue({
                      label: label,
                      type: type,
                      field: field,
                      value: event.target.value,
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={onClick}
                        color="primary"
                        size="small"
                        variant="text"
                      >
                        {buttonName}
                      </Button>
                    ),
                  }}
                />
              )}
              {type === "date" && (
                <Box display="flex">
                  <InputDate
                    input={{ label: "", name: "start_date" }}
                    value={value}
                    onChange={(date: MaterialUiPickersDate) => {
                      setValue({
                        label: label,
                        type: type,
                        field: field,
                        value: date ? new Date(date.toString()) : null,
                      });
                    }}
                    error={false}
                  />
                  <Button
                    onClick={onClick}
                    color="primary"
                    size="small"
                    variant="text"
                  >
                    {buttonName}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
      );
    }
  );