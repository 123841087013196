import React from 'react';
import {
  Box,
  Button,
  Container,
  Grid
} from "@material-ui/core";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import TextField from '@material-ui/core/TextField';
import { SubBar } from "../Dashboard/SubBar";
import Logo from '../../components/assets/Logo_Navamin_1.png';

export const MedicalCertificateTelemed = () =>{
    const onCapturePNG = () =>{
        htmlToImage.toPng(document.getElementById("saveAnotherPNG") as HTMLElement)
        .then(function (dataUrl) {
            const pdf = new jsPDF();
            var link = document.createElement('a');
            link.download = 'my-image-name.jpeg';
            const imgProps= pdf.getImageProperties(dataUrl);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            console.log("pdfHeight:", pdfHeight, imgProps.height);
            pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight, undefined,'FAST');
            pdf.autoPrint({variant: 'non-conform'});
            pdf.save("Medical Certificate.pdf"); 
        });
        
      }
    
      return (
        <>
            <Container maxWidth="xl" style={{ maxWidth: 1500 }} className="py-4 pb-16" >
                <SubBar title={"Medical Certificate"} back={true} reload={false}/>
                <br/>
                <Grid container id="saveAnotherPNG">
                  <Grid container style={{marginTop: '5%', marginLeft: '30px', fontSize: '18px'}}>
                      <img src={Logo} />
                  </Grid>
                    <Grid container>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'center', fontSize: '30px', color: '#4d56a7'}}> ใบรับรองแพทย์ </p>
                         </Grid>
                    </Grid>
                    <Grid container style={{marginTop: '5%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'right', fontSize: '25px', color: '#4d56a7'}}> 
                            วันที่ 
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '23px', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: 150, marginRight: '20px'}}
                            />  
                          </p>
                         </Grid>
                    </Grid>   
                    <Grid container style={{marginTop: '5%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'left', fontSize: '1.5vw', color: '#4d56a7'}}> 
                              ข้าพเจ้า   
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '20vw', marginRight: '10px'}}
                            />  
                            ใบอนุญาตประกอบโรคศิลปะแผนปัจจุบันชั้นหนึ่ง สาขาเวชกรรมเลขที่
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '23vw'}}
                            />
                          </p>
                         </Grid>
                    </Grid> 

                    <Grid container style={{marginTop: '1%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'left', fontSize: '1.5vw', color: '#4d56a7'}}> 
                            ขอรับรองว่า   
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '83vw', marginRight: '10px'}}
                            />  
                            
                          </p>
                         </Grid>
                    </Grid> 
                    <Grid container style={{marginTop: '1%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'left', fontSize: '1.5vw', color: '#4d56a7'}}> 
                          ได้มารับการตรวจรักษา เมื่อ   
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '20vw', marginRight: '10px'}}
                            />  
                            มีอาการ
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '48vw'}}
                            />
                          </p>
                         </Grid>
                    </Grid> 

                    <Grid container style={{marginTop: '1%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'left', fontSize: '1.5vw', color: '#4d56a7'}}> 
                            วินิจฉัยว่าเป็นโรค   
                            <TextField
                              id="margin-none"
                              InputProps={{ style: { fontSize: '1.5vw', color: '#4d56a7' } }}
                              style={{marginLeft: '5px', width: '80vw', marginRight: '10px'}}
                            />  
                            
                          </p>
                         </Grid>
                    </Grid> 

                    <Grid container style={{marginTop: '30%', marginLeft: '30px'}}>
                        <Grid item xs={12}> 
                          <p style={{textAlign:'right', fontSize: '1.5vw', color: '#4d56a7', marginRight: '10vw'}}> 
                            ( พญ. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)  
                          </p>
                          <p style={{textAlign:'right', fontSize: '1.5vw', color: '#4d56a7', marginRight: '10vw'}}> 
                          แพทย์ผู้รับรองการตรวจ
                          </p>
                         </Grid>
                  </Grid> 

                </Grid>
                <Box mt={8} pb={2} style={{textAlign: 'center'}}>
                <Grid container>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={4} xs={12}> 
                        <Button  variant="contained" color="primary" onClick={onCapturePNG} style={{marginRight: '10px'}}> 
                              Submit
                        </Button>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                </Grid>
                </Box>
            </Container>
              
        </>
      );
}
