import React, { useEffect } from "react";
import { Global, css } from "@emotion/react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import "swiper/swiper-bundle.min.css";
import "./App.css";
import { PrivateRoute } from "./components/PrivateRoute";
import { Login } from "./features/Login";
import { SnackbarProvider } from "notistack";
import { webStore } from "./stores/webStore";
import { observer } from "mobx-react-lite";

import { UserCreateSI, UserCreateSIMobile } from './features/UserManage/UserCreateSI';
import { UserDetail, UserDetailMobile } from "./features/UserManage/UserDetail";
import {
  ViewNoteList
} from "./features/UserManage/ViewNoteList";

import { createMuiTheme, MuiThemeProvider, Avatar } from "@material-ui/core";
import { LoggingDashboard } from "./features/LoggingDashboard/LoggingDashboard";
import { Alert } from "./features/Alert/Alert";

import 'react-toastify/dist/ReactToastify.css';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken,onMessage } from "firebase/messaging";
import { API_PATH_GO } from "./utils/const";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { Notification } from "./features/Notification/Notification";
import { DashboardMobileSI, DashboardSI } from "./features/Dashboard/DashboardSI";
import { UserListMobileSmartInfirmary, UserListSmartInfirmary } from "./features/UserList/UserListSI";

import { VideoCall } from "./features/Telemedicine/VideoCall/VideoCall";
import { Appointment } from "./features/Telemedicine/Appointment/Appointment";
import { ViewCase } from "./features/Telemedicine/ViewCase/ViewCase";
import { Booking } from "./features/Telemedicine/Booking/Booking";
import { MedicalCertificate } from "./features/UserManage/MedicalCertificate";
import { AcceptNewAppointment } from "./features/Telemedicine/Appointment/AcceptNewAppointment";
import { Chat } from "./features/Telemedicine/Chat/Chat";
import { Finance } from "./features/Telemedicine/Finance/Finance";
import { Pharmacy } from "./features/Telemedicine/Pharmacy/Pharmacy";
import { ReportTelemedicine } from "./features/Telemedicine/ReportTelemedicine";
import { MedicalCertificateTelemed } from "./features/Telemedicine/MedicalCertificateTelemed";
import { UserListTelemed } from "./features/Telemedicine/PatientList/UserListTelemed";
import { ImportUserList } from "./features/Telemedicine/PatientList/ImportUserList";
const firebaseApp = initializeApp({
  apiKey: "AIzaSyDvkf7Az7jrp0mSUZIMFdT3QYPcRGeY53g",
  authDomain: "home-isolation-9bc2b.firebaseapp.com",
  projectId: "home-isolation-9bc2b",
  storageBucket: "home-isolation-9bc2b.appspot.com",
  messagingSenderId: "485087116672",
  appId: "1:485087116672:web:44bcb8e38a49bbf9079565",
  measurementId: "G-JZXM4QJQYE"
});

const messaging = getMessaging(firebaseApp);

export const getTokenApp = () => {
  return getToken(messaging, {vapidKey: 'BOo-HvZFv9ySBYrSc9nFeOww6a2hQl4ChPyeCLghiS7vUJOe9Dp43bnSIQnuLC8KdBEjCoyRLHNI_Pn15OCkT-s'}).then((currentToken) => {
    if (currentToken) {
      localStorage.setItem("firebaseToken", currentToken);
      axios.post(API_PATH_GO+'/v2/notification-token/create', 
        { 
          provider: "firebase",
          token: localStorage.getItem("firebaseToken"),
          user_id: localStorage.getItem("userId")
        }, 
        {
          headers: {
            "Content-Type": "application/json",
            "Project-Key": localStorage.getItem("projectKey"),
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Platform" : "web"
          }
        }
      ).then((res) => {
      }).catch((error) => {
      });
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = observer(() => {
  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    webStore.setDevice(mobile ? "mobile" : "desktop");
  }, []);
  
  getTokenApp();
  if(typeof messaging !== 'undefined'){
    onMessage(messaging, (payload) => {
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          css={{backgroundColor: "#4684e2"}}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <Avatar css={{ width: 32, height: 32 }}>
                    {payload.notification?.body?.substring(0, 1)}
                </Avatar>
                {/* <img
                  className="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                  alt=""
                /> */}
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900" css={{color: "white !important"}}>
                  {payload.notification?.title} {payload.notification?.body}
                </p>
                <p className="mt-1 text-sm text-gray-500" css={{color: "white !important"}} >
                    {payload ? payload?.data?.event_desc : ""}
                </p>
                <p className="mt-1 text-sm text-gray-500" css={{color: "white !important"}}>
                  Just now!!!
                </p>

                {/*  incomoing change
                <img
                  className="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                  alt=""
                />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900" css={{color: "white"}}>
                  {payload.notification.title}
                </p>
                <p className="mt-1 text-sm text-gray-500" css={{color: "white"}}>
                  {payload.notification.body} */}
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              css={{color: "white !important"}}
            >
              Close
            </button>
          </div>
        </div>
      ))
    });
  }
  if (webStore.device === "unknown") {
    return <></>;
  } else if (webStore.device === "desktop") {

  }

  axios.interceptors.response.use(function (response) {
    const expired_date = localStorage.getItem("token_expired_date");
    const date_now = new Date(Date.now());
    if(expired_date?.toLocaleString()) {
      if(date_now?.toLocaleString() > expired_date?.toLocaleString()) {
        // localStorage.clear();
      }
    }

    return response;
  }, function (error) {
    if (error?.request?.status === 401) {
      localStorage.clear();
    }
    return Promise.reject(error);
  });
  
  return (
    <MuiThemeProvider theme={formLabelsTheme}>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ duration: 10000000 }}
      />
      <SnackbarProvider>
        <Global
          styles={css`
            .MuiPickersBasePicker-container
              .MuiPickersToolbarButton-toolbarBtn
              .MuiTypography-subtitle1 {
              color: rgba(255, 255, 255, 1) !important;
              text-decoration: underline;
              font-weight: bold;
            }
          `}
        />
        <Router>
          <ScrollToTop />
          <div key={webStore?.profile?.id}>
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute
                path="/"
                title="Dashboard"
                exact
                component={(props: any) => {
                  if (
                    webStore.selectedProject?.is_quarantine === 0 ||
                    window.location.href.indexOf("?log") >= 0
                  ) {
                    return <LoggingDashboard {...props} />;
                  }
                  return webStore.device === "desktop" ? (
                    <DashboardSI {...props} />
                  ) : (
                    <DashboardMobileSI {...props} />
                  );
                }}
              />
            
              <PrivateRoute
                path="/user/listSI"
                exact
                component={
                  webStore.device === "desktop" ? UserListSmartInfirmary : UserListMobileSmartInfirmary
                }
              />
          
              <PrivateRoute
                path="/user/createUserSI"
                exact
                component={
                  webStore.device === "desktop" ? UserCreateSI : UserCreateSIMobile
                }
              />
              <PrivateRoute
                path="/user/:userId/detail"
                exact
                component={
                  webStore.device === "desktop" 
                  ? UserDetail
                  : UserDetailMobile
                }
              />

              <PrivateRoute path="/user/:quarantineId/noteList" exact component={ ViewNoteList } />
              <PrivateRoute path="/user/:userId/medicalCertificate" exact component = { MedicalCertificate } />
              
              <PrivateRoute path="/alert" exact component={Alert} />
           
              <PrivateRoute path='/telemedicine/videocall/:index/:name/:surname/:videoChannel/:videoChannelType/:appointmentId' exact component={ VideoCall }/>
              <PrivateRoute path='/telemedicine/appiontment' exact component ={ Appointment } />
              <PrivateRoute path='/telemedicine/viewCase/:index/:appointmentId' exact component = {ViewCase} />
              <PrivateRoute path='/telemedicine/booking' exact component = { Booking } />
              <PrivateRoute path="/telemedicine/acceptNewAppointment/:index/:patientId" component = { AcceptNewAppointment }/>
              <PrivateRoute path="/telemedicine/chat/:index/:name/:surname/:chatChannel/:chatChannelType/:appointmentId" component = { Chat } />
              <PrivateRoute path="/telemedicine/finance" component = { Finance } />
              <PrivateRoute path= "/telemedicine/pharmacy" component = { Pharmacy }/>
              <PrivateRoute path= "/telemedicine/report" component = { ReportTelemedicine }/>
              <PrivateRoute path= "/telemedicine/medicalCertificate" component = { MedicalCertificateTelemed }/>
              <PrivateRoute path="/telemedicine/patientList" component = { UserListTelemed } />
              <PrivateRoute path="/telemedicine/importUser" component = { ImportUserList } />

              <PrivateRoute path="/notification" component = { Notification }/>
            </Switch>
          </div>
        </Router>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
});

export default App;
